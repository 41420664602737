import React from 'react'
import styled, { keyframes } from 'styled-components';

const Loader = ({
  size = '100px',
  message
}) => {
  return (
    <Loading size={size}>
      <span>{message ? message : 'Iniciando...'}</span>
    </Loading>

  )
}


const load = keyframes`
    to {
        transform: rotate(360deg);
        }
`;
const a2 = keyframes`
    to {
        transform: rotate(-360deg);
    }
`;
const Loading = styled.div`
    position: relative;
    display:flex;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 5px solid var(--primaryColor);
    animation: ${load} 2s linear infinite;
    align-items:center;
    justify-content: center;

    span{
        position: absolute;
        display: flex;
        font-size: 1em;
        font-weight: bold;
        width: ${({ size }) => size};
        height: ${({ size }) => size};
        color: var(--primaryColor);
        align-items:center;
        justify-content: center;
        animation: ${a2} 2s linear infinite;
    }

    &::before,
    &::after{
        content: '';
        width: ${({ size }) => size};
        height:${({ size }) => size};
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        border-radius: 50%;
    }
    &::before{
        border-top: 5px solid #e67e22;
        transform: rotate(120deg);
    }
    &::after{
        border-top: 5px solid #3498db;
        transform: rotate(240deg);
    }


`;



/*
.loader {
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 5px solid #e74c3;
    position: relative;
    animation: load 2s linear infinite;
  }
  
  .loader::before,.loader::after {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 50%;
  }
  
  .loader::before {
    border-top: 5px solid #FFFFFF;
    transform: rotate(120deg);
  }
  
  .loader::after {
    border-top: 5px solid #3498db;
    transform: rotate(240deg);
  }
  
  .loader span {
    position: absolute;
    font-size: small;
    width: 100px;
    height: 100px;
    color: #fff;
    text-align: center;
    line-height: 100px;
    animation: a2 2s linear infinite;
  }
  
  @keyframes load {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes a2 {
    to {
      transform: rotate(-360deg);
    }
  }
*/
export default Loader
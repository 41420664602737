import React from 'react'
import Searcher from '../../components/clients/searcher'
import ContainerPages from '../../components/containerPages'
import { useClients } from '../../hooks/useClients'
import { Title } from '../../styles/clients'
import Table from './components/table'

const Clients = () => {

        const { totalClient, searching, getAllClients, navigation, deleteClient, permissions, download } = useClients()

        return (
                <ContainerPages>
                        <Title>
                                <h3>{totalClient ?? 0} CLIENTE(S)</h3>
                                <button onClick={() => permissions('/clients/add', 'submenu')}>
                                        <p>AGREGAR</p>
                                </button>
                        </Title>
                        <Searcher
                                searching={searching}
                                download={download}
                        />
                        <Table getAllClients={getAllClients}
                                deleteClient={deleteClient}
                                navigation={navigation}
                                permissions={permissions} />
                </ContainerPages>
        )
}

export default Clients
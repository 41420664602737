import React from 'react'
import ContainerPages from '../components/containerPages'
import ListPagesInternal from '../components/maintenance/listPagesInternal'
import styles from '../styles/parameters.module.css'
import { useParamsMaintenance } from '../hooks/useParamsMaintenance'
import { listAction } from '../services/globals'
import Delete from '../img/svg/delete'
import SelectUser from '../components/maintenance/selectUser'
import Edit from '../img/svg/edit'

const Maintenance = () => {

        const { navigation, initialMaintenance, filterMaintenance, allMaintenance, deleteMaintenance, downloadMaintenance } = useParamsMaintenance()

        return (
                <ContainerPages>
                        <ListPagesInternal
                                navigation={navigation}
                        />
                        <div className={styles.container_filter}>
                                <form onSubmit={filterMaintenance}>
                                        <span>
                                                <label htmlFor=''>desde</label>
                                                <input type='date' name='start' defaultValue={initialMaintenance.start} />
                                        </span>
                                        <span>
                                                <label htmlFor=''>Hasta</label>
                                                <input type='date' name='end' defaultValue={initialMaintenance.end} />
                                        </span>
                                        <span>
                                                <label htmlFor='associated_user'>Usuario</label>
                                                <SelectUser />
                                        </span>
                                        <span>
                                                <label htmlFor=''>Accion</label>
                                                <select name='action'>
                                                        <option value='0'>Todos</option>
                                                        <option value='1'>Limpieza</option>
                                                        <option value='2'>Pintura</option>
                                                </select>
                                        </span>
                                        <span>
                                                <input type='search' name='search' placeholder='Buscar' />
                                        </span>
                                        <span>
                                                <button type='submit'>Buscar</button>
                                        </span>
                                        <span>
                                                <button type='button' onClick={() => downloadMaintenance()}>Exportar</button>
                                        </span>
                                </form>
                        </div>
                        <div className={styles.container_table_maintenance}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>ID</th>
                                                        <th>Nombre</th>
                                                        <th>Fecha programada</th>
                                                        <th>Accion</th>
                                                        <th>Usuario asociado</th>
                                                        <th>Observaciones</th>
                                                        <th>Acciones</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {allMaintenance === 0 ? (
                                                        <tr>
                                                                <td colSpan='7'>No hay mantenciones</td>
                                                        </tr>
                                                ) : (
                                                        allMaintenance === null ? (
                                                                <tr>
                                                                        <td colSpan='7'>Cargando...</td>
                                                                </tr>
                                                        ) : (
                                                                allMaintenance.map((maintenance) => (
                                                                        <tr key={maintenance.id}>
                                                                                <td>{maintenance.id}</td>
                                                                                <td>{maintenance.name}</td>
                                                                                <td>{maintenance.programmed_date}</td>
                                                                                <td>{listAction[maintenance.action].name}</td>
                                                                                <td>{maintenance.associated_user_name}</td>
                                                                                <td>{maintenance.observations}</td>
                                                                                <td>
                                                                                        <button type='button' onClick={() => navigation(`/maintenance/update-maintenance/${maintenance.id}`, { replace: true })}>
                                                                                                <Edit />
                                                                                        </button>
                                                                                        <button type='button' onClick={() => deleteMaintenance(maintenance.id)}>
                                                                                                <Delete />
                                                                                        </button>
                                                                                </td>
                                                                        </tr>
                                                                ))
                                                        ))}
                                        </tbody>
                                </table>
                        </div>

                </ContainerPages>
        )
}

export default Maintenance
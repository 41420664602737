import React from 'react'
import { API_FRONT } from '../../services/globals'
import styles from '../../styles/modalVoucher.module.css'
import {motion} from  'framer-motion'

const ModalVoucher = ({voucher,showModalVoucher}) => {

        return (
                <motion.div
                className={styles.container_modal_voucher}
                initial={{y:-20, opacity: 0 }}
                animate={{y:0, opacity: 1 }}
                exit={{y:-20, opacity: 0 }}
                >
                        <div>
                                <button type='button' onClick={()=> showModalVoucher()}>Cerrar</button>
                                <img src={`${API_FRONT}/uploads/voucher/${voucher}`}/>
                        </div>
                </motion.div>
        )
}

export default ModalVoucher
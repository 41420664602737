
const Sales = (props) => (
  <svg
    viewBox="0 0 511.45 509.96"
    width="1em"
    height="1em"
  >
    <g data-name="Capa 2">
      <g data-name="Capa 1">
        <path d="M393.82 0c4.21 1 8.52 1.72 12.62 3.06 24.09 7.92 40.64 29.86 40.87 55.21.33 37.45.12 74.9.13 112.34 0 11.38.08 22.76-.05 34.13-.08 6.94-3.51 12-9.87 14.63-6 2.49-11.75 1.42-16.68-2.92-3.87-3.41-5.37-7.88-5.37-13V60.97c0-17.32-11.8-29.06-29.16-29.06H127.5c-22.35 0-44.7-.06-67 0-13.81 0-24.62 8.55-27.65 21.67a33.93 33.93 0 0 0-.78 7.6Q32 197.33 32 333.41c0 17.65 11.76 29.33 29.53 29.34H233.4a27 27 0 0 1 6.48.56 15.83 15.83 0 0 1 11.82 17.42c-.89 7.93-7.59 13.89-16.07 14-13.54.11-27.08.05-40.62.05H60.92c-26.57 0-49.41-15.82-57.43-40.24A63.77 63.77 0 0 1 .17 335Q-.15 197.52 0 60C.06 29.59 22 4.67 52.11.51A16.71 16.71 0 0 0 54 0Z" />
        <path d="M511.45 487.11c-.48 1.5-.94 3-1.45 4.51-3.95 11.44-13.78 18.28-26.64 18.31q-41.88.07-83.78 0c-28.41 0-56.81.05-85.21-.06a26.53 26.53 0 0 1-25.9-20.71 31.34 31.34 0 0 1-.82-7q-.06-99.81 0-199.61c0-15.71 11.82-27.54 27.53-27.55q84.36-.08 168.71 0c13.49 0 23.73 8.18 27 21.24a12.88 12.88 0 0 0 .59 1.52Zm-191.77-200v75.68c0 4.2 0 4.2 4.33 4.2h152.21c2.55 0 3.35-.57 3.34-3.26-.11-24.54-.06-49.09-.07-73.63 0-1-.09-2-.14-3Zm.14 190.71h159.56v-78.7c-1.27 0-2.38-.15-3.49-.15H323.1c-2.73 0-3.49.74-3.48 3.47.1 24.08.06 48.15.07 72.22 0 .94.08 1.96.13 3.13ZM327.41 93.46h-31.78c-13.54 0-27.08.06-40.63 0a15.95 15.95 0 0 1-4.41-31.32 18.94 18.94 0 0 1 5-.58h124.73c.76 0 1.51.06 2.52.11.06.87.17 1.69.17 2.51v125.15c0 8.8-5.88 15.56-14.14 16.49a15.87 15.87 0 0 1-17.41-12.57 24.67 24.67 0 0 1-.35-5.09V116c-1.28 1.21-2 1.88-2.7 2.57L238.79 227.65c-7.48 7.45-17 7.41-24.34-.11q-27.21-27.87-54.36-55.79a17.93 17.93 0 0 1-1.69-2.49c-1.31 1.25-2.07 1.93-2.79 2.65q-41.8 41.82-83.58 83.67c-5.24 5.26-11.21 7.49-18.28 4.71C42.9 256 40.12 242.58 48.4 234.1c10.2-10.44 20.61-20.67 30.92-31q33.48-33.47 67-66.93c7.32-7.3 16.55-7.24 23.89 0l.8.81q26.78 27.5 53.53 55a23.51 23.51 0 0 1 2 2.83L327.7 94.13Z" />
      </g>
    </g>
  </svg>
)

export default Sales
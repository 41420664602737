import ContainerPages from '../../components/containerPages'
import useSurrenders from '../../hooks/useSurrenders'
import ListItem from '../../components/surrenders/listItem'
import styles from '../../styles/surrenders.module.css'
import { Outlet, useLocation } from 'react-router-dom'

const Surrenders = () => {

        const { navigation } = useSurrenders()

        const { pathname } = useLocation()

        return (
                <ContainerPages>
                        <div className={styles.container_title}>
                                <h3>Control de Caja</h3>
                                <span>
                                        <button type='button' onClick={() => navigation('/surrenders/add-cash-control', { replace: true })}>Agregar Apertura</button>
                                        <button type='button' onClick={() => navigation('/surrenders/add', { replace: true })}>Agrega nuevo control</button>
                                </span>
                        </div>
                        <div className={styles.box_cash_control}>
                                <button
                                        type='button'
                                        onClick={() => navigation('/surrenders/list-quadrature', { replace: true })}
                                        className={pathname === '/surrenders/list-quadrature' ? styles.active : ''}
                                >Listado Apertura/cierre</button>
                                <button
                                        type='button'
                                        onClick={() => navigation('/surrenders/list-cash-control', { replace: true })}
                                        className={pathname === '/surrenders/list-cash-control' ? styles.active : ''}
                                >Listado Control de caja</button>
                        </div>
                        <Outlet />
                </ContainerPages>
        )
}

export default Surrenders
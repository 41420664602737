import React from 'react'
import styles from '../../styles/orders.module.css'
import { Download } from '../../img/svg/download'
import { methodPayment } from '../../services/globals'

const Filters = ({ initialFilters, getFilterOrders, download }) => {

        const { start_date, end_date, filter_payments, filter_status } = initialFilters

        return (
                <div className={styles.container_filters_buy_orders}>
                        <form onSubmit={getFilterOrders}>
                                <div className={styles.box_filter_date}>
                                        <span>
                                                <label htmlFor='start_date'>Fecha inicio</label>
                                                <input type='datetime-local' name='start_date' id='start_date' defaultValue={start_date} />
                                        </span>
                                        <span>
                                                <label htmlFor='end_date'>Fecha termino</label>
                                                <input type='datetime-local' name='end_date' id='end_date' defaultValue={end_date} />
                                        </span>
                                </div>
                                <div className={styles.box_payment_status}>
                                        <label htmlFor='filter_payments'>Estado de pago</label>
                                        <select defaultValue={filter_payments} id='filter_payments' name='filter_payments' >
                                                <option value='all'>Todos</option>
                                                {methodPayment.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                        </select>
                                </div>
                                <div className={styles.box_action_buy_order}>
                                        <label htmlFor='filter_status'>estado de envio</label>
                                        <select defaultValue={filter_status} id='filter_status' name='filter_status'>
                                                <option value="all">Todos</option>
                                                <option value="delivered">Entregados</option>
                                                <option value="in_transit">En transito</option>
                                                <option value="quantity_to_sent">Pendientes</option>
                                        </select>
                                </div>
                                <div>
                                        <button type='submit'>Filtrar</button>
                                </div>
                                <div>
                                        <button type='button' onClick={() => download()}>
                                                <Download />
                                        </button>
                                </div>
                        </form>
                </div>
        )
}

export default Filters
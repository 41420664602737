import React, { useEffect } from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/cashControl.module.css'
import { useWorkers } from '../../hooks/useWorkers'
import useSurrenders from '../../hooks/useSurrenders'
import { useParams } from 'react-router-dom'

const UpdateCashControl = () => {

        const { id } = useParams()

        const { getACashControlById, getACashControl, onSubmitUpdateCashControl } = useSurrenders()
        const { getAllWorkers } = useWorkers()

        useEffect(() => {
                getACashControlById(id)
        }, [id])

        return (
                <ContainerPages>
                        <div className={styles.container_cash_control}>
                                <form onSubmit={onSubmitUpdateCashControl}>
                                        <h3>Cierre de caja</h3>
                                        <input type='hidden' name='id' defaultValue={getACashControl.id} />
                                        <span>
                                                <label>Vendedor</label>
                                                <select name='id_responsible_worker' defaultValue={getACashControl.id_responsible_worker} required disabled>
                                                        {getAllWorkers === null ? (
                                                                <option value='0'>Cargando...</option>
                                                        ) : (
                                                                getAllWorkers === 0 ? (
                                                                        <option value='0'>No hay vendedores</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione</option>
                                                                                {getAllWorkers.map((worker, index) => (
                                                                                        getACashControl.id_responsible_worker === worker.id_user ? (
                                                                                                <option key={worker.id_user} value={worker.id_user} selected>{worker.name_user}</option>
                                                                                        ) : (
                                                                                                <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                        )
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label>Fecha apertura</label>
                                                <input type='datetime-local' name='opening_date' required disabled defaultValue={getACashControl.opening_date} />
                                        </span>
                                        <span>
                                                <label>Monto apertura</label>
                                                <input type='number' name='box_opening' required disabled defaultValue={getACashControl.box_opening} />
                                        </span>
                                        <span>
                                                <label>Observaciones</label>
                                                <textarea name='comments' disabled defaultValue={getACashControl.comments} />
                                        </span>
                                        <span>
                                                <label>Fecha cierre</label>
                                                <input type='datetime-local' name='closing_date' defaultValue={getACashControl.closing_date} required />
                                        </span>
                                        <span>
                                                <label>Monto cierre</label>
                                                <input type='number' name='box_closing' defaultValue={getACashControl.box_closing} required />
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type='button'>Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default UpdateCashControl
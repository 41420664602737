import { useProducts } from '../../../hooks/useProducts'
import { chileanCurrency } from '../../../services/globals'

const LastProductAdded = () => {

    const {getAllProducts} = useProducts()

  return (
                    <div>
                <table>
                    <caption>Ultimos Productos agregados</caption>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Distribuidor</th>
                            <th>Stock</th>
                            <th>Precio Unitario</th>
                        </tr>
                    </thead>
                    {getAllProducts === null ? (
                        <tbody>
                            <tr>
                                <th>Cargando...</th>
                            </tr>
                        </tbody>
                    ) : (getAllProducts === 0 ? (
                        <tbody>
                        <tr>
                            <th>Sin registros</th>
                        </tr>
                        </tbody>  
                    ):(
                        getAllProducts.slice(0, 3).map((item, index)=> (
                            <tbody key={index}>
                            <tr>
                                <th>{item.name_product}</th>
                                <th>Incompleto</th>
                                <th>{item.stock_product}</th>
                                <th>${chileanCurrency(item.price_product)}</th>  
                            </tr>
                            </tbody>  
                        ))
                    ))}
                </table>
                </div>
  )
}

export default LastProductAdded
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContext from '../contexts/login'
import { DELETE_LOC } from '../services/delete'
import { GET_LOC } from '../services/get'
import { POST_LOC } from '../services/post'
import { API } from '../services/globals'

const filterInitial = {
        order_by: 'ASC',
        name_order: 'id_client',
        search: ''
}

export const useClients = () => {

        const navigation = useNavigate()
        const { setToast, activeToast, permissions } = useContext(LoginContext)
        const [getAllClients, setGetAllClients] = useState(null)
        const [totalClient, setTotalClient] = useState(0)
        const [client, setClient] = useState(null)
        const [search, setSearch] = useState('')
        const [formData, setFormData] = useState(null)
        const [loading, setLoading] = useState(false)

        const searchAllClient = (orderMode, nameOrder, search) => {
                GET_LOC({
                        table: 'clients',
                        params: `?list=true&order_by=${orderMode}&name_order=${nameOrder}&search=${search}`
                }).then((response) => {
                        if (response.status === 200) {
                                setTotalClient(response.total)
                                setGetAllClients(response.results)
                        } else {
                                setTotalClient(0)
                                setGetAllClients(0)
                        }
                })
        }


        const findAnClient = (id) => {
                GET_LOC({
                        table: 'clients',
                        params: `?key=id_client&value=${id}`
                }).then((response) => {
                        if (response.status === 200) {
                                setClient(response.results)
                        } else {
                                setClient(0)
                        }
                })
        }

        const download = () => {
                if (getAllClients === 0) {
                        setToast({
                                message: 'No hay registros para descargar',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }

                let initial = `order_by=${filterInitial.order_by}&name_order=${filterInitial.name_order}&search=${filterInitial.search}`
                if (formData !== null) {
                        initial = `order_by=${formData.order_by}&name_order=${formData.name_order}&search=${formData.search}`
                }

                fetch(`${API}/clients?list=true&${initial}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'clientes.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const searching = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                let orderMode = data.get('order_by') === 'on' ? 'DESC' : 'ASC'
                
                data.set('order_by', orderMode)
                setFormData(Object.fromEntries(data))
                searchAllClient(orderMode, data.get('name_order'), data.get('search'))
        }

        const addClient = (e) => {

                e.preventDefault()
                let data = new FormData(e.target)

                POST_LOC({
                        table: 'clients',
                        data: data
                }).then((response) => {
                        if (response.status === 200) {
                                setToast({
                                        message: "Cliente agregado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return navigation('/clients', { replace: true })
                        } else {
                                setToast({
                                        message: response.results,
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return
                        }

                })

        }

        const deleteClient = (id, email) => {

                let isDelete = window.confirm(
                        `¿Estás seguro que quires eliminar el cliente con el correo ${email}?`
                );

                if (isDelete) {
                        DELETE_LOC({
                                table: 'clients',
                                params: `?key=id_client&value=${id}`
                        }).then((response) => {
                                if (response.status === 200) {
                                        setGetAllClients(getAllClients.filter((item) => item.id_client !== id))
                                        setToast({
                                                message: "Cliente eliminado correctamente",
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                } else {
                                        setToast({
                                                message: "Error al eliminar el cliente, intente nuevamente",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return
                                }
                        })

                }
        }

        const updateClient = (e) => {
                e.preventDefault()
                setLoading(true)
                let data = new FormData(e.target)

                POST_LOC({
                        table: 'clients?update=true',
                        data: data
                }).then((response) => {

                        if (response.status == 200) {
                                setToast({
                                        message: "Cliente actualizado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return navigation('/clients', { replace: true })
                        } else {
                                setToast({
                                        message: "Error al actualizar el cliente, intente nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                        }
                }).finally(() => {
                        setLoading(false)
                })
        }

        useEffect(() => {
                searchAllClient(filterInitial.order_by, filterInitial.name_order, filterInitial.search)
        }, [])


        return {
                addClient,
                totalClient,
                getAllClients,
                deleteClient,
                updateClient,
                navigation,
                findAnClient,
                client,
                setSearch,
                search,
                download,
                permissions,
                searching,
                loading
        }

}

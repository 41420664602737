import React from 'react'
import ContainerPages from '../components/containerPages'
import styles from '../styles/caps.module.css'
import useCaps from '../hooks/useCaps'
import { useEffect } from 'react'
import ListCaps from '../components/caps/listCaps'
import Filters from '../components/caps/filters'

const Caps = () => {

        const { caps, deleteCap, permissions, inititalFilters, filterCaps } = useCaps();

        return (
                <ContainerPages>
                        <div className={styles.container_caps}>
                                <div className={styles.title}>
                                        <h3>Gestion de tapas</h3>
                                        <button type='button' onClick={() => permissions('/caps/add', 'submenu')}>Agregar</button>
                                </div>
                                <Filters
                                        filterCaps={filterCaps}
                                        inititalFilters={inititalFilters}
                                />
                                <ListCaps
                                        caps={caps}
                                        deleteCap={deleteCap}
                                        permissions={permissions}
                                />
                        </div>
                </ContainerPages>
        )
}

export default Caps
import React, { useContext } from 'react'
import styled from 'styled-components'
import LoginContext from '../contexts/login'
import Check from '../img/svg/check'
import Close from '../img/svg/close'
import {motion,AnimatePresence } from 'framer-motion'

const Toast = () => {
    const {toast,state, closeToast} = useContext(LoginContext)

    return (
    <AnimatePresence>
    {state === true && (
    <Box 
        toast={toast}
        initial={{x:0, opacity: 0 }}
        animate={{x:-20, opacity: 1 }}
        exit={{x:0, opacity: 0 }}
        >
        <span>
            <span>
                {toast.error ? (
                    <Close/>
                ):(
                <Check/>
                )}
            </span>
        </span>
        <span>
            <h3>{toast.error ? 'Error' : 'Exito'}</h3>
            <p>{toast.message}</p>
        </span>
        <button type="button" onClick={()=> closeToast()}>
            <Close/>
        </button>
    </Box>
    )}
    </AnimatePresence>
  )
}

const Box = styled(motion.div)`
    position: fixed;
    display:flex;
    width:90%;
    max-width: 360px;
    height:70px;
    z-index: 9999999;
    top: 50px;
    right:0;
    background: var(--secundaryBackground);
    border-radius: 5px;
    overflow: hidden;
    box-shadow:1px 1px 5px 1px rgba(0, 0, 0,0.1);
    border-left:10px solid ${({toast})=> toast.error ? 'red' : 'green'};

    span:nth-child(1){
        display: flex;
        width:20%;
        height:100%;
        align-items: center;
        justify-content: center;
        
        span{
            display: flex;
            width:2em;
            height:2em;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color:${({toast})=> toast.error ? 'red' : 'green'};

            svg{
                width:1.2em;
                height:1.2em;
                fill:var(--secundaryBackground);
            }
        }
    }
    
    span:nth-child(2){
        display: flex;
        width:80%;
        height:100%;
        justify-content: center;
        flex-direction: column;

        h3{
            font-weight: bold;
        }
        p{
            font-size: .9em;
            padding-right: 20px;
            overflow-wrap: break-word;
        }
    }
    button{
        position: absolute;
        display: grid;
        width: 2em;
        height: 2em;
        place-content: center;
        right:0;
        top:0;
        border:none;
        background-color:transparent;
        cursor:pointer;
        svg{
            width: 1.2em;
            height: 1.2em;
        }
    }

    @media screen and (min-width:768px){
        width: 350px;
    }

`;

export default Toast

const Orders = () => (
  <svg
    viewBox="0 0 490.59 512"
    width="1em"
    height="1em"
  >
    <g data-name="Capa 2">
      <path d="M63.89 383.45V27.33C63.89 7.37 71.34 0 91.51 0h372.28c19.21 0 26.78 7.65 26.78 26.92v376c0 51.71-32.76 94-82 105.84a107.85 107.85 0 0 1-24.69 3.15q-138 .27-276.08 0c-53.1-.1-95.9-35.72-106-87.91A104.66 104.66 0 0 1 0 409.08c-.75-15.95 8-25.14 23.9-25.27 11-.09 22.07 0 33.1 0 2.07-.04 4.1-.22 6.89-.36Zm42.76.34H295.36c15.87 0 23.72 7.64 24.46 23.48 1.76 37.72 33.69 65.27 71.1 61.37 33.22-3.47 56.93-30.87 56.95-66.23q.11-176.44 0-352.9c0-2.19-.24-4.38-.39-6.9H115c-8.37 0-8.38 0-8.38 8.43v332.75Z" />
      <path d="M256.2 149.06v-41.95h127.09v42ZM383.43 192.54v41.68h-127v-41.68ZM256.16 319.45v-41.86h127.13v41.86ZM171.13 107.16H213V149h-41.87ZM171.18 192.38H213v41.76h-41.82ZM212.81 319.55H171.1v-42h41.71Z" />
    </g>
  </svg>
)

export default Orders

import styled from "styled-components";


export const Section = styled.section`
    width: 100%;
    position: relative;

    form{


    width: 100%;
        position: relative;
        display:flex;
        padding:20px;
        flex-wrap: wrap;
        row-gap: 5px;

        div{
            display: flex;
            width: 100%;
            border-radius: 5px;
            padding: 10px;
            flex-direction: column;
            box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.2);
    
            p{
               width: 100%;
               text-align: center;
               font-weight: bold;
            }
        }
    
        div:nth-child(1){
            row-gap: 5px;
    
            span{
                display:flex;
                width: 100%;
                padding: 0 10px;
    
                label{
                    width: 50%;
                }
                input{
                    width: 50%;
                }
            }
    
        }
        div:nth-child(2){
    
            span:nth-child(2){
                display:flex;
                width: 100%;
                justify-content:center;
                column-gap:10px;
            }

            span:nth-child(3){
                width: 100%;
                height: 30px;
                border:none;
                margin-top: 10px;

                select{
                    width: 100%;
                    height: 30px;
                    border: 1px solid rgba(0,0,0, 0.2);
                    border-radius: 5px;
                }
    
            }
    
        }
        div:nth-child(3){
            button{
                width:100%;
                height:30px;
                border-radius:5px;
                border:1px solid rgba(0,0,0,0.2);
                font-weight: bold;
                font-size: 1em;
                background-color:var(--primaryColor);
                color:#FFFFFF;
                cursor:pointer;
            }
        }
    }

    @media screen and (min-width:768px){

        flex-wrap: nowrap;
        column-gap:5px;

        div:nth-child(1),
        div:nth-child(2){
            width:40%;
        }
        div:nth-child(3){
            width:20%;
            justify-content: center;
        }


    }

`;

export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: hidden;
    padding:20px;

`;

export const DataTable = styled.div`

    width: 100%;
    height: auto;
    overflow-y: scroll;

    table{
        position: relative;
        width: 100%;
        height: fit-content;
        min-width: 2600px;

        thead {
        display:flex;
        position: relative;
        background-color: var(--primaryColor);
        color: var(--secundaryBackground);
            
            tr {
            display: grid;
            place-content: center;
            width: 100%;
            height: 40px;
            grid-template-columns: 2% 5% 11% 6% 4% 4% 4% 10% 11% 13% 10% 10% 10%;
            }

        }
        tbody{
            height: auto;
        
        &:nth-child(odd){
            background-color: var(--secundaryBackground);
        }
            tr{
                display: grid;
                width: 100%;
                min-height: 40px;
                height: auto;
                grid-template-columns: 2% 5% 11% 6% 4% 4% 4% 10% 11% 13% 10% 10% 10%;
        
                th{
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    font-weight: normal;
                    gap: 10px;
                    align-items: center;
                    text-align: left;
                    
        
                    button{
                        display: grid;
                        width: 35px;
                        height: 35px;
                        border: 1px solid rgba(0,0,0, 0.2);
                        border-radius: 5px;
                        cursor: pointer;
                        place-content: center;
        
                        svg{
                            width: 1.2em;
                            height: 1.2em;
                        }
                    }
                    ol{
                        display: flex;
                        list-style-type: disc;
                        flex-direction: column;
                        text-align:left;
                        li{
                            display:flex;

                            b{
                                width:85px;
                                
                            }
                        }
                    }
                    button:nth-child(1){

                        svg{
                            fill:var(--secundaryColor);
                        }

                    }
                    button:nth-child(2){
                        svg{
                            fill:red;
                        }
                    }
                }
            }
        
        }
    
}

`;

export const GeneratorPDF = styled.div`
    position:relative;
    display:flex;
    width: 100%;
    align-items: center;
    justify-content:space-between;
    padding: 0 20px;

    button{
        
        padding: 5px 20px;
        border: none;
        background-color: var(--primaryColor);
        color:#FFFFFF;
        font-size: 1em;
        border-radius: 5px;
        transition:box-shadow .3s ease;
        font-weight: bold;
        cursor:pointer;

        &:hover{
            box-shadow: 1px 1px 5px 1px rgba(0,0,0, 0.3);
        }
    }
`;
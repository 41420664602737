import React from 'react'
import { useContext } from 'react'
import LoginContext from '../../../contexts/login'
import Review from '../../../img/svg/review'
import { ContainerTable } from '../../../styles/deliveries'
import styles from '../../../styles/listDeliveries.module.css'

const ListDeliveries = ({ orders }) => {

        const { permissions } = useContext(LoginContext)
        
        return (

                <ContainerTable>
                        <div className={styles.container_table_deliveries}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>N°</th>
                                                        <th>Cliente</th>
                                                        <th>Email</th>
                                                        <th>Telefono</th>
                                                        <th>Direccion</th>
                                                        <th>Comentarios</th>
                                                        <th>Total productos</th>
                                                        <th>Estado de envio</th>
                                                        <th>Acciones</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {orders === null ? (
                                                        <tr>
                                                                <td>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        orders === 0 ? (
                                                                <tr>
                                                                        <td>No hay pedidos</td>
                                                                </tr>
                                                        ) : (
                                                                orders.map((item, index) => (
                                                                        <tr key={index}>
                                                                                <td>{item.id}</td>
                                                                                <td>{item.name_client}</td>
                                                                                <td>{item.email_client}</td>
                                                                                <td>{item.phone_client}</td>
                                                                                <td>{item.direction_client}</td>
                                                                                <td>{item.additional_comments}</td>
                                                                                <td>{item.quantity_total}</td>
                                                                                <td>
                                                                                        {item.quantity_total != item.delivered ? (
                                                                                                <>
                                                                                                        {item.in_transit > 0 && (
                                                                                                                <p style={{ background: 'orangered' }}>{item.in_transit} en transito</p>
                                                                                                        )}
                                                                                                        {item.delivered > 0 && (
                                                                                                                <p style={{ background: 'green' }}>{item.delivered} entregados</p>
                                                                                                        )}
                                                                                                </>
                                                                                        ) : (
                                                                                                <p style={{ background: 'green' }}>Todos entregados</p>
                                                                                        )}
                                                                                </td>
                                                                                <td>
                                                                                        <button title='Ver detalles' onClick={() => permissions(`/deliveries/update/${item.id}`, 'submenu')}>
                                                                                                <Review />
                                                                                        </button>
                                                                                </td>
                                                                        </tr>
                                                                ))))}
                                        </tbody>
                                </table>
                        </div>
                </ContainerTable>
        )
}

export default ListDeliveries
import React from 'react'
import ContainerPages from '../components/containerPages'
import styles from '../styles/parameters.module.css'
import { useParamsMaintenance } from '../hooks/useParamsMaintenance'
import ListPagesInternal from '../components/maintenance/listPagesInternal'
import Delete from '../img/svg/delete'
import SelectUser from '../components/maintenance/selectUser'
import Edit from '../img/svg/edit'

const ParamsAndMaintenance = () => {

        const { navigation, filterParameters, allParameters, deleteParam, initialParams, downloadParameters } = useParamsMaintenance()

        return (
                <ContainerPages>
                        <ListPagesInternal
                                navigation={navigation}
                        />
                        <div className={styles.container_filter}>
                                <form onSubmit={filterParameters}>
                                        <span>
                                                <label htmlFor=''>desde</label>
                                                <input type='date' name='start' defaultValue={initialParams.start} />
                                        </span>
                                        <span>
                                                <label htmlFor=''>Hasta</label>
                                                <input type='date' name='end' defaultValue={initialParams.end} />
                                        </span>
                                        <span>
                                                <label htmlFor=''>Usuario</label>
                                                <SelectUser />
                                        </span>
                                        <span>
                                                <button type='submit'>Filtrar</button>
                                        </span>
                                        <span>
                                                <button type='button' onClick={() => downloadParameters()}>Exportar</button>
                                        </span>
                                </form>

                        </div>
                        <div className={styles.container_table_params}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th rowSpan='2'>ID</th>
                                                        <th rowSpan='2'>Inicio registro</th>
                                                        <th rowSpan='2'>Usuario</th>
                                                        <th colSpan='5'>Registro Parametros</th>
                                                        <th colSpan='7'>Nivel estanques</th>
                                                        <th rowSpan='2'>Acciones</th>
                                                </tr>
                                                <tr>
                                                        <th>PH</th>
                                                        <th>TEMP PH</th>
                                                        <th>PPM</th>
                                                        <th>TEMP PPM</th>
                                                        <th>Dureza</th>
                                                        <th>1</th>
                                                        <th>2</th>
                                                        <th>3</th>
                                                        <th>4</th>
                                                        <th>5</th>
                                                        <th>6</th>
                                                        <th>7</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {allParameters === 0 ? (
                                                        <tr>
                                                                <td colSpan='14'>No hay parametros</td>
                                                        </tr>
                                                ) : (
                                                        allParameters === null ? (
                                                                <tr>
                                                                        <td colSpan='14'>Cargando...</td>
                                                                </tr>
                                                        ) : (
                                                                allParameters.map((param) => (
                                                                        <tr key={param.id}>
                                                                                <td>{param.id}</td>
                                                                                <td>{param.start_record}</td>
                                                                                <td>{param.associated_user_name}</td>
                                                                                <td>{param.ph}</td>
                                                                                <td>{param.temp_ph}</td>
                                                                                <td>{param.ppm}</td>
                                                                                <td>{param.temp_ppm}</td>
                                                                                <td>{param.hardness}</td>
                                                                                <td>{param.pond_n_1}</td>
                                                                                <td>{param.pond_n_2}</td>
                                                                                <td>{param.pond_n_3}</td>
                                                                                <td>{param.pond_n_4}</td>
                                                                                <td>{param.pond_n_5}</td>
                                                                                <td>{param.pond_n_6}</td>
                                                                                <td>{param.pond_n_7}</td>
                                                                                <td>
                                                                                        <button onClick={() => navigation(`/maintenance/update-param/${param.id}`, { replace: true })}>
                                                                                                <Edit />
                                                                                        </button>
                                                                                        <button type='button' onClick={() => deleteParam(param.id)}>
                                                                                                <Delete />
                                                                                        </button>

                                                                                </td>
                                                                        </tr>
                                                                ))
                                                        )
                                                )}
                                        </tbody>
                                </table>
                        </div>
                </ContainerPages>
        )
}

export default ParamsAndMaintenance
import React from 'react'
import ContainerPages from '../containerPages'
import useProduction from '../../hooks/useProduction'
import Multiselect from "react-widgets/Multiselect";
import styles from '../../styles/production.module.css'
import { useWorkers } from '../../hooks/useWorkers'

const AddProduction = () => {

        const { getAllWorkers } = useWorkers()
        const { addProduction, userSelect, setUserSelect, errors } = useProduction()

        return (
                <ContainerPages>
                        <div className={styles.container_add_turn}>
                                <form onSubmit={addProduction}>
                                        <h3>NUEVO TURNO</h3>
                                        <span>
                                                <label>Bidones Malos</label>
                                                <input type='number' name='filling_20lt' placeholder='20 Lts' />
                                                <input type='number' name='filling_10lt' placeholder='10 Lts' />
                                                <input type='number' name='filling_5lt' placeholder='5 Lts' />
                                        </span>
                                        <span>
                                                <label>Bidones Producidos</label>
                                                <input type='number' name='created_20lt' placeholder='20 Lts' />
                                                <input type='number' name='created_10lt' placeholder='10 Lts' />
                                                <input type='number' name='created_5lt' placeholder='5 Lts' />
                                        </span>
                                        <span>
                                                <label htmlFor='ice'>Hielos</label>
                                                <input type='number' name='ice' id='ice' />
                                        </span>
                                        <span>
                                                <label>Inicio del turno</label>
                                                <input type='time' name='start_of_shift' />
                                        </span>
                                        <span>
                                                <label>Usuarios asociados</label>
                                                {getAllWorkers !== null && (
                                                        <Multiselect style={{ width: '100%' }}
                                                                dataKey="id_user"
                                                                textField="name_user"
                                                                placeholder='Seleccione los usuarios del turno'
                                                                value={userSelect}
                                                                data={getAllWorkers}
                                                                onChange={(data) => setUserSelect(data)} />
                                                )}
                                        </span>
                                        <span>
                                                <button type='submit'>Agregar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddProduction
import ContainerPages from '../containerPages'
import styles from '../../styles/workers.module.css'
import React, { useContext } from 'react'
import Multiselect from "react-widgets/Multiselect";
import LoginContext from '../../contexts/login'
import { useWorkers } from '../../hooks/useWorkers';

const AddWorker = () => {

        const { menuSelected,addWorker, setMenuSelected, subMenuSelected, setSubMenuSelected } = useWorkers()

        const { menu, submenu } = useContext(LoginContext)

        if (menu === null) return;
        if (submenu === null) return;

        return (
                <ContainerPages>
                        <div className={styles.container_update_worker}>
                                <span>
                                        <h3>NUEVO USUARIO</h3>
                                </span>
                                <form onSubmit={addWorker}>
                                        <label>Nombre</label>
                                        <input name="name_user" required />
                                        <label>Código</label>
                                        <input name="code_user" required />
                                        <label>Email</label>
                                        <input name="email_user" required />
                                        <label>Email Personal</label>
                                        <input type="email" name="email_personal_user" />
                                        <label>Cuenta/banco</label>
                                        <input type="text" name="bank_account_user" />
                                        <label>Dirección</label>
                                        <input type="text" name="direction_user" />
                                        <label>Rut </label>
                                        <input name="rut_user" />
                                        <label>Telefono</label>
                                        <input name="phone_user" />
                                        <label>Cargo</label>
                                        <input name="rol_user"/>
                                        <label>Permisos Principales</label>
                                        <Multiselect style={{ width: '100%' }}
                                                dataKey="id"
                                                textField="title"
                                                value={menuSelected}
                                                data={menu}
                                                onChange={(data) => setMenuSelected(data)} />
                                        <label style={{ paddingTop: '15px' }}>Permisos Adicionales</label>
                                        <Multiselect style={{ width: '100%' }}
                                                dataKey="id"
                                                textField="description"
                                                value={subMenuSelected}
                                                data={submenu}
                                                onChange={(data) => setSubMenuSelected(data)} />
                                        <label>Contraseña</label>
                                        <input name="password_user" required />
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type='button'>Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddWorker
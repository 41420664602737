import React from 'react'
import { chileanCurrency } from '../../services/globals'
import styles from '../../styles/addOrder.module.css'

const ProductsAssociated = ({ productSelected, setProductSelected, clientSelected,addNewOrder }) => {

        const lessQuantity = (id) => {
                const index = productSelected.findIndex(item => item.id_product == id);
                if (productSelected[index].quantity > 1) {
                        productSelected[index].quantity -= 1
                        setProductSelected([...productSelected])
                }
        }

        const addQuantity = (id) => {
                const index = productSelected.findIndex(item => item.id_product == id);
                productSelected[index].quantity += 1
                setProductSelected([...productSelected])

        }


        const deleteProduct = (id) => {
                const index = productSelected.findIndex(item => item.id_product == id);
                productSelected.splice(index, 1)
                setProductSelected([...productSelected])
        }


        const priceTotalProduct = () => {
                let total = 0
                productSelected.forEach(item => {
                        total += item.price_product * item.quantity
                });
                return total
        }

        return (
                <div className={styles.container_product_associated}>
                        <h3 style={{ position: 'relative', width: '100%' }}>PRODUCTOS AGREGADOS</h3>
                        <table>
                                <thead>
                                        <tr>
                                                <th>N°</th>
                                                <th>Nombre producto</th>
                                                <th>Cantidad</th>
                                                <th>Precio Unitario</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {productSelected?.map((item, index) => (
                                                <tr key={index}>
                                                        <td>{item.id_product}</td>
                                                        <td>{item.name_product}</td>
                                                        <td style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                <button style={{ width: '25px', height: '25px' }} onClick={() => lessQuantity(item.id_product)}>-</button>
                                                                <p>{item.quantity}</p>
                                                                <button style={{ width: '25px', height: '25px' }} onClick={() => addQuantity(item.id_product)}>+</button>
                                                        </td>
                                                        <td>$ {chileanCurrency(item.price_product)}</td>
                                                        <td>
                                                                <button type='button' onClick={() => deleteProduct(item.id_product)}>Eliminar</button>
                                                        </td>
                                                </tr>
                                        ))}
                                </tbody>
                                <tfoot>
                                        <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Total compra</td>
                                                <td>$ {chileanCurrency(parseInt(priceTotalProduct()))}</td>
                                        </tr>
                                </tfoot>
                        </table>
                        {productSelected?.length > 0 && clientSelected !== null && (
                                <span style={{ display: 'grid', width: '100%', placeContent: 'center' }}>
                                        <button type='button' style={{ padding: '3px 20px' }} onClick={addNewOrder}>AGREGAR PEDIDO</button>
                                </span>
                        )}
                </div>
        )
}

export default ProductsAssociated
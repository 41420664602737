import React, { useContext } from 'react'
import ContainerPages from '../../components/containerPages'
import { Box } from '../../styles/login'
import Logo from '../../img/agua.bmp'
import LoginContext from '../../contexts/login'
import Loader from '../../components/loader'
import styled from 'styled-components'
const Login = () => {

  const {handleAccess, loader} = useContext(LoginContext)

  return (
    <ContainerPages>
      <Box>
        <form onSubmit={handleAccess}>
          <span>
            <img src={Logo}/>
          </span>
          <span>
            <h3>INICIAR SESIÓN</h3>
            <label htmlFor="email_user" >Correo</label>
            <input type="email" id="email_user" name="email_user" required/>
            <label htmlFor="password_user" >Contraseña</label>
            <input type="password"  id="password_user" name="password_user" required/>
            <button type="submit">INGRESAR</button>
          </span>
          {loader && 
          <BoxLoad>
            <Loader/>
          </BoxLoad>
            }
        </form>
      </Box>
    </ContainerPages>
  )
}


const BoxLoad = styled.div`
  position: absolute;
  display: flex;
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,.8);
  align-items: center;
  justify-content: center;
`;

export default Login
import styled from "styled-components";



export const Title = styled.div`
    position: relative;
    display:flex;
    width:100%;
    height:auto;
    column-gap:20px;
    align-items:center;
    flex-wrap:wrap;

    h3{
        position:relative;
        width:auto;
        text-align: left;
        padding: 20px;
        font-size: 1.4em;

    }
    span{
        display:flex;
        width:auto;
        height: 30px;
        padding: 10px;
        background-color:var(--primaryColor);
        align-items:center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        p{
            color: var(--secundaryBackground);
            font-size:1em;
            font-weight: bold;

        }
    }

`;

export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: hidden;
    padding:0 20px;
`;
export const DataTable = styled.div`

    width: 100%;
    height: auto;
    overflow-y: scroll;

table{
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 2400px;

    thead {
    display:flex;
    position: relative;
    background-color: var(--primaryColor);
    color: var(--secundaryBackground);
    
        tr {
        display: grid;
        place-content: center;
        width: 100%;
        height: 40px;
        grid-template-columns: 2% 7% 11% 7% 4% 7% 14% 14% 7% 10% 5% 5% 7% ;
        }
    
    }
    tbody{
        height: auto;
    
    &:nth-child(odd){
        background-color: var(--secundaryBackground);
    }
        tr{
            display: grid;
            width: 100%;
            min-height: 40px;
            height: auto;
            grid-template-columns: 2% 7% 11% 7% 4% 7% 14% 14% 7% 10% 5% 5% 7%  ;
    
            th{
                display: flex;
                height: 100%;
                justify-content: center;
                font-weight: normal;
                gap: 10px;
                align-items: center;
                text-align: left;

                ol{
                    display: flex;
                    list-style-type: disc;
                    flex-direction: column;
                    text-align:left;
                    li{
                        display:flex;

                        b{
                            width:85px;
                            
                        }
                    }
                }
                
    
                button{
                    display: grid;
                    width: 35px;
                    height: 35px;
                    border: 1px solid rgba(0,0,0, 0.2);
                    border-radius: 5px;
                    cursor: pointer;
                    place-content: center;
    
                    svg{
                        width: 1.2em;
                        height: 1.2em;
                    }
                }
                ol{
                    display: flex;
                    list-style-type: disc;
                    flex-direction: column;
                    text-align:left;
                }
                button{
                    display: grid;
                    width:140px;
                    align-items: center;
                    justify-content: center;
                    border:none;

                    p{
                        font-weight: bold;
                        color:#FFFFFF;
                    }
                }
            }
        }
    
    }
    
}

`;
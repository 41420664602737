import React from 'react'
import { useControl } from '../../hooks/useControl'
import ContainerPages from '../containerPages'
import styles from '../../styles/control.module.css'
import { useWorkers } from '../../hooks/useWorkers'
const AddExitControl = () => {

        const { addExitControl, loading } = useControl()
        const { getAllWorkers } = useWorkers()

        return (
                <ContainerPages>
                        <div className={styles.container_exit_control}>
                                <form onSubmit={addExitControl}>
                                        <h3>NUEVO CONTROL DE SALIDA</h3>
                                        <span>
                                                <label>Trabajador</label>
                                                <select defaultValue='0' name='id_deliveryman_drumcontrol'>
                                                        {getAllWorkers === 0 ? (
                                                                <option>No hay datos</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione</option>
                                                                                {getAllWorkers.map((item, index) => (
                                                                                        <option key={item.id_user} value={item.id_user}>{item.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label>20Lt Retornable</label>
                                                <input type="number" name="exit_20L_drumcontrol" />
                                        </span>
                                        <span>
                                                <label>10Lt Retornable</label>
                                                <input type="number" name="exit_10L_returnable_drumcontrol" />
                                        </span>
                                        <span>
                                                <label>10Lt Desechable</label>
                                                <input type="number" name="exit_10L_disposable_drumcontrol" />
                                        </span>
                                        <span>
                                                <label>Cubos</label>
                                                <input type="number" name="exit_cube_drumcontrol" />
                                        </span>
                                        <span>
                                                <label>Frape</label>
                                                <input type="number" name="exit_frape_drumcontrol" />
                                        </span>
                                        <span>
                                                <label htmlFor='firm_register_drumcontrol'>Firma</label>
                                                <select name='firm_register_drumcontrol' id='firm_register_drumcontrol' defaultValue='0'>
                                                        <option value='0'>No</option>
                                                        <option value='1'>Si</option>
                                                </select>
                                        </span>
                                        <span>
                                                <button type='submit' disabled={loading}>{loading ? 'Guardando...' : 'Guardar'}</button>
                                        </span>

                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddExitControl
import { BoxCard } from '../../../styles/dashboard'

const Card = (
  {children,
    title= "",
    count='',
    color="#000000",
    background='#FFFFFF',
    onClick=null
  }) => {


  return (
    <BoxCard color={color} background={background} onClick={onClick}>
      <span>
        {children}
      </span>
      <span>
        {count !== null ?(
        <h3>{count}</h3>
        ):(
          <p>Cargando...</p>
        )}
        <h4>{title}</h4>
      </span>
    </BoxCard>
  )
}

export default Card
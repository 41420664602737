import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/cashControl.module.css'
import { useWorkers } from '../../hooks/useWorkers'
import useSurrenders from '../../hooks/useSurrenders'

const CashControl = () => {


        const { onSubmitCashControl } = useSurrenders()
        const { getAllWorkers } = useWorkers()

        return (
                <ContainerPages>
                        <div className={styles.container_cash_control}>
                                <form onSubmit={onSubmitCashControl}>
                                        <h3>Apertura de caja</h3>
                                        <span>
                                                <label>Vendedor</label>
                                                <select name='id_responsible_worker' required>
                                                        {getAllWorkers === null ? (
                                                                <option value='0'>Cargando...</option>
                                                        ) : (
                                                                getAllWorkers === 0 ? (
                                                                        <option value='0'>No hay vendedores</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione</option>
                                                                                {getAllWorkers.map((worker, index) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label>Fecha</label>
                                                <input type='datetime-local' name='opening_date' required />
                                        </span>
                                        <span>
                                                <label>Monto apertura</label>
                                                <input type='number' name='box_opening' required />
                                        </span>
                                        <span>
                                                <label>Observaciones</label>
                                                <textarea name='comments' />
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type='button'>Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default CashControl
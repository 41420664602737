import { useState, useEffect, useContext } from 'react'
import { GET_LOC } from '../services/get'
import LoginContext from '../contexts/login'
import { API, dateTime } from '../services/globals'
import { useRef } from 'react'
import { POST_LOC } from '../services/post'
import { DELETE_LOC } from '../services/delete'

export const useBuyOrder = () => {

        const { permissions, setToast, activeToast, navigation } = useContext(LoginContext)
        const [getOrders, setGetOrders] = useState(null)
        const [totalOrders, setTotalOrders] = useState(null)
        const { currentTime, currentDay } = dateTime()
        const [saveDataFilter, setSaveDataFilter] = useState(null)
        const [dataClient, setDataClient] = useState(null)
        const [clientSelected, setClientSelected] = useState(null)
        const [activeTable, setActiveTable] = useState(false)
        const [productSelected, setProductSelected] = useState([])
        const [products, setProducts] = useState(null)
        const formClient = useRef(null)
        const initialFilters = {
                start_date: currentDay,
                end_date: currentTime,
                filter_payments: 'all',
                filter_status: 'all'
        }

        const orders = (filter_payments, filter_status, start_date, end_date) => {
                setGetOrders(null)
                GET_LOC({
                        table: 'buy_order',
                        params: `?all=true&status=${filter_payments}&shipping=${filter_status}&start_date=${start_date}&end_date=${end_date}`
                }).then(response => {
                        if (response.status === 200) {
                                setTotalOrders(response.total)
                                setGetOrders(response.results)
                        }
                        else {
                                setTotalOrders(response.total)
                                setGetOrders(0)
                        }
                })
        }

        const getProducts = (idType) => {
                GET_LOC({
                        table: 'products',
                        params: `?list=true&type=${idType}`
                }).then(response => {
                        if (response.status === 200) {

                                setProducts(response.results)
                        }
                })
        }

        const getFilterOrders = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                setSaveDataFilter(Object.fromEntries(data))
                orders(data.get('filter_payments'), data.get('filter_status'), data.get('start_date'), data.get('end_date'))
        }

        const download = () => {
                if (getOrders === 0) {
                        setToast({
                                message: "No hay datos para descargar",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let params = `status=${initialFilters.filter_payments}&shipping=${initialFilters.filter_status}&start_date=${initialFilters.start_date}&end_date=${initialFilters.end_date}`

                if (saveDataFilter !== null) {
                        params = `status=${saveDataFilter.filter_payments}&shipping=${saveDataFilter.filter_status}&start_date=${saveDataFilter.start_date}&end_date=${saveDataFilter.end_date}`
                }
                fetch(`${API}/buy_order?all=true&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Pedidos.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const deleteOrder = (id, payment, status) => {

                console.log(status)
                if (![1, 2, 5, 9].includes(parseInt(payment)) || status != 0) {
                        setToast({
                                message: "Este pedido ya esta pagado o ya esta en transito",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let confirm = window.confirm('¿Estas seguro de eliminar este pedido?')

                if (!confirm) return;

                DELETE_LOC({
                        table: 'buy_order',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status == 200) {

                                setToast({
                                        message: "El pedido se ha eliminado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                //eliminamos el pedido del front-end
                                let newOrder = getOrders.filter(item => item.id_order != id);
                                setTotalOrders(newOrder.length)
                                setGetOrders(newOrder)
                                return;
                        }
                        console.log(response);
                }).catch(e => {
                        console.log(e)
                }).finally(() => {

                })
        }

        const searchClients = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                if (data.get('search_client') === '') {
                        return;
                }

                GET_LOC({
                        table: 'clients',
                        params: `?search_client=${data.get('search_client').trim()}`,
                }).then(response => {
                        if (response.status === 200) {
                                setActiveTable(true)
                                setDataClient(response.results)
                        } else {
                                setActiveTable(false)
                                setToast({
                                        message: 'No se encontraron resultados',
                                        error: true,
                                        time: 3000,
                                })
                                activeToast()
                                return;
                        }
                })
        }


        const dataClientSelected = (client) => {
                getProducts(client.client_type_client)
                setClientSelected(client)
                setActiveTable(false)
        }

        const addProductToCart = (product) => {

                let duplicatedProduct = productSelected.find(item => item.id_product === product.id_product)
                if (duplicatedProduct) {
                        setToast({
                                message: 'El producto ya se encuentra en el carrito',
                                error: true,
                                time: 2000,
                        })
                        activeToast()
                        return;
                }
                product.quantity = 1
                setProductSelected([...productSelected, product])
        }

        const priceTotalProduct = () => {
                let total = 0
                productSelected.forEach(item => {
                        total += item.price_product * item.quantity
                });
                return total
        }

        const addNewOrder = () => {
                if (productSelected.length === 0 || clientSelected === null) {
                        setToast({
                                message: 'No se ha seleccionado ningun cliente o producto',
                                error: true,
                                time: 2000,
                        })
                        activeToast()
                        return;
                }

                let quantityTotal = productSelected.reduce((total, p) => {
                        return total + parseInt(p.quantity);
                }, 0);

                let data = new FormData(formClient.current)
                data.append('total_to_pay', priceTotalProduct())
                data.append('quantity_total', quantityTotal)
                data.append('subtotal_sum', priceTotalProduct())

                if ([1, 2].includes(parseInt(data.get('payment_accepted'))) && data.get('manage_shipping') == 'Automatico') {
                        setToast({
                                message: 'El pedido no puede ser enviado automaticamente si no esta pagado',
                                error: true,
                                time: 3000,
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'buy_order',
                        data: data
                }).then(response => {

                        if (response.lastId) {

                                let dataProducts = new FormData()

                                productSelected.map((prod, index) => {

                                        dataProducts.append('id_buy_order', response.lastId)
                                        dataProducts.append('id_product', prod.id_product)
                                        dataProducts.append('name_product', prod.name_product)
                                        dataProducts.append('description_product', prod.description_product)
                                        dataProducts.append('unit_price', prod.price_product)
                                        dataProducts.append('quantity', prod.quantity)
                                        if ([3, 4, 5].includes(parseInt(data.get('payment_accepted')))) {

                                                if (data.get('manage_shipping') == 'Automatico') {
                                                        dataProducts.append('quantity_to_sent', prod.quantity)
                                                }
                                        }

                                        dataProducts.append('total', prod.price_product * prod.quantity)

                                        POST_LOC({
                                                table: 'cart_detail',
                                                data: dataProducts
                                        }).then(response => response)
                                })

                                setToast({
                                        message: 'Pedido creado correctamente, buscando pedido...',
                                        error: false,
                                        time: 2000,
                                })
                                activeToast()
                                setTimeout(() => {
                                        navigation(`/orders/update/${response.lastId}`, { replace: true })
                                }, 1000)
                                return;

                        } else {
                                setToast({
                                        message: 'No se ha podido crear el pedido',
                                        error: true,
                                        time: 2000,
                                })
                                activeToast()
                                return;
                        }

                })

        }

        const activeSendMail = (idorder) => {

                GET_LOC({
                        table: 'buy_order',
                        params: `?active-mail=true&id=${idorder}`,
                }).then(response => {
                        if (response.status === 200) {

                                setToast({
                                        message: 'Correo enviado correctamente',
                                        error: false,
                                        time: 2000,
                                })
                                activeToast()
                                return;
                        } else {
                                setToast({
                                        message: 'No se ha podido enviar el correo',
                                        error: true,
                                        time: 2000,
                                })
                                activeToast()
                        }
                }).catch(error => {
                        console.log(error)
                })
        }

        useEffect(() => {
                orders(initialFilters.filter_payments, initialFilters.filter_status, initialFilters.start_date, initialFilters.end_date)
        }, [])

        useEffect(() => {
                getProducts(1)
        }, [])


        return {
                totalOrders,
                getOrders,
                permissions,
                initialFilters,
                getFilterOrders,
                download,
                searchClients,
                activeTable,
                clientSelected,
                formClient,
                productSelected,
                addNewOrder,
                products,
                addProductToCart,
                navigation,
                dataClient,
                dataClientSelected,
                setProductSelected,
                activeSendMail,
                deleteOrder
        }
}

import React from 'react'
import ContainerPages from '../../components/containerPages'
import styles from '../../styles/dailyManagement.module.css'
import { useDailyManager } from '../../hooks/useDailyManager'
import { chileanCurrency } from '../../services/globals'
import AddFormAccount from '../../components/dailyManager/addFormAccount'
import AddFormSubAccount from '../../components/dailyManager/addFormSubAccount'
import Filters from '../../components/dailyManager/filters'

const DailyManagement = () => {

	const { navigation, getAccExp, filterAccountsExpenses,downloadAccountsExpenses, filterInitial, getAcc, getSubAcc, deleteAccount, deleteSubAccount, activeFormAccount, activeFormSubAccount, setActiveFormSubAccount, addSubAccount, addAccount, deleteAccountExpences, setActiveFormAccount } = useDailyManager()

	return (
		<ContainerPages>
			<div className={styles.box_title_daily}>
				<h3>Cuentas gastos</h3>
				<button type='button' onClick={() => navigation('/daily-management/add', { replace: true })}>Crear nuevo</button>
				<button type='button' onClick={() => setActiveFormAccount(true)}>Cuentas</button>
				<button type='button' onClick={() => setActiveFormSubAccount(true)}>SubCuentas</button>
			</div>
			<Filters
				getSubAcc={getSubAcc}
				getAcc={getAcc}
				filterInitial={filterInitial}
				filterAccountsExpenses={filterAccountsExpenses}
					downloadAccountsExpenses={downloadAccountsExpenses}
			/>
			<div className={styles.container_table_daily}>
				<table>
					<thead>
						<tr>
							<th>Dia/Mes</th>
							<th>Cuenta</th>
							<th>SubCuenta</th>
							<th>Monto</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{getAccExp === 0 ? (
							<tr>
								<td colSpan='5'>No hay datos</td>
							</tr>
						) : (
							getAccExp === null ? (
								<tr>
									<td colSpan='5'>Cargando...</td>
								</tr>
							) : (
								getAccExp.results.map((item, index) => (
									<>
										<tr key={index}>
											<td rowSpan={parseInt(item.count_date) + 1}>{item.date}</td>
										</tr>
										{item.accounts.map((item, index) => (
											<tr key={item.id}>
												<td>{item.name_account}</td>
												<td>{item.name_sub_account}</td>
												<td>{chileanCurrency(item.amount)}</td>
												<td>
													<button type='button' onClick={() => navigation(`/daily-management/update/${item.id}`, { replace: true })}>Editar</button>
													<button type='button' onClick={() => deleteAccountExpences(item.id)}>Eliminar</button>
												</td>
											</tr>
										))}
									</>
								))
							)
						)}
					</tbody>
				</table>
			</div>
			<div className={styles.container_total_daily}>
				<table>
					<thead>
						{getAccExp === 0 ? (
							<tr>
								<th colSpan='8'>No hay datos</th>
							</tr>
						) : (
							getAccExp === null ? (
								<tr>
									<th colSpan='8'>Cargando...</th>
								</tr>
							) : (
								<tr>
									<th>Dia/Mes</th>
									{getAccExp.totals.name_keys.map((item, index) => (
										<th key={index}>{item}</th>
									))}
								</tr>
							))}
					</thead>
					<tbody>
						{getAccExp === 0 ? (
							<tr>
								<td colSpan='8'>No hay datos</td>
							</tr>
						) : (
							getAccExp === null ? (
								<tr>
									<td colSpan='8'>Cargando...</td>
								</tr>
							) : (
								<tr>
									<th>Totales</th>
									{getAccExp.totals.amounts.map((item, index) => (
										<td key={index}>$ {chileanCurrency(item)}</td>
									))}
								</tr>
							)
						)}
					</tbody>
				</table>
			</div>
			<AddFormAccount
				activeFormAccount={activeFormAccount}
				addAccount={addAccount}
				getAcc={getAcc}
				deleteAccount={deleteAccount}
				setActiveFormAccount={setActiveFormAccount} />
			<AddFormSubAccount
				activeFormSubAccount={activeFormSubAccount}
				getSubAcc={getSubAcc}
				deleteSubAccount={deleteSubAccount}
				addSubAccount={addSubAccount}
				setActiveFormSubAccount={setActiveFormSubAccount}
			/>
		</ContainerPages>
	)
}

export default DailyManagement
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import LoginContext from '../contexts/login'
import { GET_LOC } from '../services/get'
import { POST_LOC } from '../services/post'
import { DELETE_LOC } from '../services/delete'
import { PUT } from '../services/put'
import { API } from '../services/globals'


const filterInitial = {
        order_by: 'ASC',
        name_order: 'id_user',
        search: ''
}

export const useWorkers = () => {

        const { setToast, activeToast, navigation, permissions } = useContext(LoginContext)
        const [worker, setWorker] = useState(null)
        const [menuSelected, setMenuSelected] = useState([])
        const [subMenuSelected, setSubMenuSelected] = useState([])
        const [totalWorkers, setTotalWorkers] = useState(0)
        const [getAllWorkers, setGetAllWorkers] = useState(null)
        const [formData, setFormData] = useState(null)

        const searchAllWorkers = (orderMode, nameOrder, search) => {
                
                GET_LOC({
                        table: 'users',
                        params: `?list=true&order_by=${orderMode}&name_order=${nameOrder}&search=${search}`
                }).then((response) => {
                        if (response.status === 200) {
                                setTotalWorkers(response.total)
                                setGetAllWorkers(response.results)
                        } else {
                                setTotalWorkers(0)
                                setGetAllWorkers(0)
                        }
                })
        }

        const getWorker = (id) => {
                GET_LOC({
                        table: 'users',
                        params: `?id_user=${id}`
                }).then(response => {

                        if (response.status === 200) {
                                setWorker(response.results.user[0])
                                setMenuSelected(response.results.menu)
                                setSubMenuSelected(response.results.submenu)
                        }

                })
        }

        const downloadData = () => {
                if (getAllWorkers === 0) {
                        setToast({
                                message: 'No hay registros para descargar',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }
                let initial = `order_by=${filterInitial.order_by}&name_order=${filterInitial.name_order}&search=${filterInitial.search}`
                if (formData !== null) {
                        initial = `order_by=${formData.order_by}&name_order=${formData.name_order}&search=${formData.search}`
                }

                fetch(`${API}/users?list=true&${initial}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Trabajadores.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const searching = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                let orderMode = data.get('order_by') === 'on' ? 'DESC' : 'ASC'

                data.set('order_by', orderMode)
                setFormData(Object.fromEntries(data))
                searchAllWorkers(orderMode, data.get('name_order'), data.get('search'))
        }


        const addWorker = (e) => {

                e.preventDefault()
                let data = new FormData(e.target)

                let newMenu = menuSelected.map((item) => {
                        return parseInt(item.id)
                })

                let newSubMenu = subMenuSelected.map((item) => {
                        return parseInt(item.id)
                })

                data.append('json_action_user', JSON.stringify(newMenu))
                data.append('id_subaction_user', JSON.stringify(newSubMenu))

                POST_LOC({
                        table: 'users?add=true',
                        data: data
                })
                        .then(response => {

                                if (response.status === 400) {

                                        setToast({
                                                message: response.results,
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return;
                                }


                                setToast({
                                        message: "datos guardados correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return navigation('/workers', { replace: true })
                        })
                        .catch(error => {
                                console.log(error)
                        })
        }

        const stateAdministrator = (item) => {
                if (parseInt(item.id_user) === 25) {
                        setToast({
                                message: "el usuario ROOT no se puede desactivar",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                let active = parseInt(item.state_user) === 0 ? "activar" : "desactivar"

                let isUpdate = window.confirm(
                        `¿Estás seguro de ${active} el usuario con el correo ${item.email_user}?`
                );

                if (isUpdate) {
                        item.state_user = item.state_user == 0 ? 1 : 0;
                        let updateData = new URLSearchParams();
                        updateData.append("state_user", item.state_user)
                        PUT({
                                table: 'users',
                                id: item.id_user,
                                nameId: 'id_user',
                                data: updateData
                        }).then(response => {
                                if (response.status === 200) {

                                        //importante , cuambiar el estado del usuario de manera visible
                                        setToast({
                                                message: "registro actualizado correctamente",
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                } else {
                                        return;
                                }
                        })
                }
        }

        const updateWorker = (e) => {
                e.preventDefault()
                let update = new FormData(e.target)

                let newMenu = menuSelected.map((item) => {
                        return parseInt(item.id)
                })

                let newSubMenu = subMenuSelected.map((item) => {
                        return parseInt(item.id)
                })
                update.append('json_action_user', JSON.stringify(newMenu))
                update.append('id_subaction_user', JSON.stringify(newSubMenu))

                POST_LOC({
                        table: 'users?update=true',
                        data: update
                })
                        .then(response => {
                                setToast({
                                        message: "datos actualizados correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return navigation('/workers', { replace: true })
                        })
                        .catch(error => {
                                console.log(error)
                        })

        }

        const deleteWorker = (id, email) => {

                let isDelete = window.confirm(`¿Estas seguro de eliminar este usuario con correo ${email}?`);

                if (isDelete) {

                        DELETE_LOC({
                                table: 'users',
                                params: `?id=${id}`
                        }).then(response => {
                                let filter = getAllWorkers.filter((item) => item.id_user !== id)
                                setGetAllWorkers(filter)
                        })
                }
        }
        useEffect(() => {
                searchAllWorkers(filterInitial.order_by, filterInitial.name_order, filterInitial.search)
        }, [])


        return {
                updateWorker,
                getWorker,
                worker,
                menuSelected,
                setMenuSelected,
                getAllWorkers,
                totalWorkers,
                subMenuSelected,
                setSubMenuSelected,
                addWorker,
                deleteWorker,
                permissions,
                stateAdministrator,
                searchAllWorkers,
                searching,
                downloadData
        }
}

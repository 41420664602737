import styled from "styled-components";


export const Box = styled.div`
    display:grid;
    width: 100%;
    height: fit-content;
    grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    gap:20px;
    padding: 20px;
    justify-items: center;
    align-items: flex-start;
`;

export const BoxCard = styled.div`
    display: flex;
    width:100%;
    height:80px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #FFFFFF;
    transition: box-shadow 0.2s ease;
    cursor:pointer;

    &:hover{
        box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.1);
    }

    span:nth-child(1){
        display: flex;
        width:80px;
        height:80px;
        align-items: center;
        justify-content: center;
        background-color:${({background})=> background ? background : '#000000'};
        svg{
            width:2em;
            height:2em;
            fill:${({color})=> color ? color : '#FFFFFF'};
        }
    }
    span:nth-child(2){
        display:flex;
        width:calc(100% - 80px);
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3{
            font-size: 2em;
            font-weight: bold;
        }

        h4{
            font-weight: normal;
            padding: 0 20px;
            text-align: center;
            text-transform: uppercase;
            font-size: .8em;

        }
    }


`;

export const BoxTable = styled.div`

    position: relative;
    display: flex;
    width: 100%;
    gap:20px;
    padding: 20px;
    flex-direction: column;


    div{
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        overflow-y: scroll;

        table{
            display: flex;
            width: 100%;
            min-width: 700px;
            height: auto;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 5px;
    
            caption{
                display: flex;
                width: 100%;
                height: 40px;
                text-align: left;
                padding-left: 20px;
                align-items:center;
                text-transform: uppercase;
                font-weight: bold;
    
            }
            thead{
                display: flex;
                width: 100%;
    
                tr{
                    display: grid;
                    width: 100%;
                    height: 40px;
                    grid-template-columns:25% 25% 25% 25% ;
                    background-color:var(--primaryColor);
                    color:var(--secundaryBackground);
    
                    th{
                        display: flex;
                        align-items:center;
                        justify-content: center;
                    }
                }
            }

            tbody{
                    width: 100%;
                    height: auto;
    
            &:nth-child(odd){
                background-color: var(--secundaryBackground);
            }
                tr{
                    display: grid;
                    width: 100%;
                    min-height: 40px;
                    height: auto;
                    grid-template-columns:25% 25% 25% 25% ;
            
                    th{
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        font-weight: normal;
                        gap: 10px;
                        align-items: center;
                        text-align: left;
                        
                    }
                }
            
            }
        }
    }

`;
import React from 'react'
import styles from '../../styles/surrenders.module.css'
import useSurrenders from '../../hooks/useSurrenders'
import { chileanCurrency } from '../../services/globals'
import Edit from '../../img/svg/edit'
import Delete from '../../img/svg/delete'

const ListQuadrature = () => {

        const { getAllQuadrature, navigation, deleteBoxCuadrature } = useSurrenders()

        return (
                <div className={styles.container_quadrature}>
                        <table>
                                <thead>
                                        <tr>
                                                <th>N°</th>
                                                <th>Responsable</th>
                                                <th>Vendedor</th>
                                                <th>Fecha Apertura</th>
                                                <th>Monto Apertura</th>
                                                <th>Fecha cierre</th>
                                                <th>Monto cierre</th>
                                                <th>Diferencia</th>
                                                <th>Comentarios</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {getAllQuadrature === 0 ? (
                                                <tr><td>No hay datos</td></tr>
                                        ) : (
                                                getAllQuadrature === null ? (
                                                        <tr><td>Cargando...</td></tr>
                                                ) : (
                                                        getAllQuadrature.results.map((quadrature, index) => (
                                                                <tr key={quadrature.id}>
                                                                        <td>{quadrature.id}</td>
                                                                        <td>{quadrature.registered_by}</td>
                                                                        <td>{quadrature.name_user}</td>
                                                                        <td>{quadrature.opening_date}</td>
                                                                        <td>{chileanCurrency(quadrature.box_opening)}</td>
                                                                        <td>{quadrature.closing_date}</td>
                                                                        <td>{chileanCurrency(quadrature.box_closing)}</td>
                                                                        <td>{chileanCurrency(quadrature.difference_box)}</td>
                                                                        <td>{quadrature.comments}</td>
                                                                        <td>
                                                                                <button type='button' onClick={() => navigation(`/surrenders/update-cash-control/${quadrature.id}`, { replace: true })}>
                                                                                        <Edit />
                                                                                </button>
                                                                                <button type='button' onClick={() => deleteBoxCuadrature(quadrature.id)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </td>
                                                                </tr>
                                                        ))
                                                )
                                        )}
                                </tbody>
                                <tfoot>
                                        {getAllQuadrature === 0 ? (
                                                <tr><td colSpan='10'>No hay datos</td></tr>
                                        ) : (
                                                getAllQuadrature === null ? (
                                                        <tr><td>Cargando...</td></tr>
                                                ) : (
                                                        <tr>
                                                                <td colSpan='4'>Total</td>
                                                                <td>{chileanCurrency(getAllQuadrature.totals.total_box_opening)}</td>
                                                                <td></td>
                                                                <td>{chileanCurrency(getAllQuadrature.totals.total_box_closing)}</td>
                                                                <td>{chileanCurrency(getAllQuadrature.totals.total_difference)}</td>

                                                        </tr>
                                                )
                                        )}

                                </tfoot>
                        </table>
                </div>
        )
}

export default ListQuadrature
import React, { useEffect } from 'react'
import { chileanCurrency } from '../../../services/globals'
import { ContainerTable, DataTable } from '../../../styles/reports'
import { useReports } from '../../../hooks/useReports'
import styles from '../../../styles/reports.module.css'

const ListOrders = () => {

        const { getOrders, filterByDate, findAllOrders } = useReports()


        console.log(getOrders)
        return (
                <ContainerTable>
                        <div className={styles.container_reports}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>N°</th>
                                                        <th>Cliente</th>
                                                        <th>Dirección</th>
                                                        <th>Cantidad</th>
                                                        <th>Estado pago</th>
                                                        <th>Descuento</th>
                                                        <th>Subtotal</th>
                                                        <th>Precio Total</th>
                                                        <th>Fecha de creación</th>
                                                        <th>Ultima actualización</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {getOrders === 0 ? (
                                                        <tr>
                                                                <td colSpan="10">No hay datos</td>
                                                        </tr>
                                                ) : (
                                                        getOrders === null ? (
                                                                <tr>
                                                                        <td colSpan="10">Cargando...</td>
                                                                </tr>
                                                        ) : (
                                                                getOrders.map((order, index) => (
                                                                        <tr key={order.id_order}>
                                                                                <td>{order.id_order}</td>
                                                                                <td>{order.name_client}</td>
                                                                                <td>{order.direction_client}</td>
                                                                                <td>{order.quantity_total}</td>
                                                                                <td>{order.name_payment}</td>
                                                                                <td>$ 0</td>
                                                                                <td>{chileanCurrency(order.subtotal_sum)}</td>
                                                                                <td>{chileanCurrency(order.total_to_pay)}</td>
                                                                                <td>{order.creation_date_order}</td>
                                                                                <td>{order.update_date_order}</td>
                                                                        </tr>
                                                                )))
                                                )}
                                        </tbody>
                                </table>
                        </div>

                </ContainerTable>
        )
}

export default ListOrders
import React from 'react'
import ContainerPages from '../containerPages'
import { useProducts } from '../../hooks/useProducts'
import { useWorkers } from '../../hooks/useWorkers'
import usePackageStore from '../../hooks/usePackageStore'
import styles from '../../styles/packageStore.module.css'

const AddPackageStore = () => {

        //, ,, arrival_quantity, difference_quantity, dispatcher_id, dispatcher_name, assigned_salesperson_id, assigned_salesperson_name, sign_seller, sign_dispatcher

        const { getAllProducts } = useProducts()
        const { getAllWorkers } = useWorkers()
        const { addPackageStore } = usePackageStore()

        return (
                <ContainerPages>
                        <div className={styles.container_add_package_store}>
                                <form onSubmit={addPackageStore}>
                                        <h3>AGREGAR NUEVO CONTROL</h3>
                                        <span>
                                                <label htmlFor='id_product'>Producto</label>
                                                <select name='id_product' id='id_product' defaultValue='0'>
                                                        {getAllProducts === 0 ? (
                                                                <option value=''>No hay productos</option>
                                                        ) : (
                                                                getAllProducts === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione</option>
                                                                                {getAllProducts.map((product, index) => (
                                                                                        <option key={product.id_product} value={product.id_product}>{product.name_product}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label htmlFor='exit_quantity'>Cantidad</label>
                                                <input type='number' name='exit_quantity' id='exit_quantity' min={1} defaultValue='1'/>
                                        </span>
                                        <span>
                                                <label htmlFor='assigned_salesperson_id'>Vendedor asignado</label>
                                                <select name='assigned_salesperson_id' id='assigned_salesperson_id' defaultValue='0'>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay vendedores</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione</option>
                                                                                {getAllWorkers.map((worker, index) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label htmlFor='sign_dispatcher'>firmar</label>
                                                <input name='sign_dispatcher' id='sign_dispatcher' type='checkbox' />
                                        </span>
                                        <span>
                                                <button type='submit'>Agregar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddPackageStore
import React from 'react'
import { methodPayment } from '../../services/globals'
import styles from '../../styles/addOrder.module.css'

const ClientAssociated = ({ clientSelected, formClient }) => {

        return (
                <div className={styles.box_details_order}>
                        <form ref={formClient}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>Nombre cliente</th>
                                                        <th>Rut</th>
                                                        <th>Telefono</th>
                                                        <th>Email</th>
                                                        <th>Direccion</th>
                                                        <th>Estado del pago</th>
                                                        <th>N°Factura/Guía <b style={{ fontSize: '.8em', color: 'red' }}>(Solo si corresponde)</b></th>
                                                        <th>Metodo de envio</th>
                                                        <th>Comentarios de envio</th>
                                                        <th>Comprobante de compra</th>
                                                        <th>Repartidor asignado</th>
                                                        <th>¿Enviar email de compra?</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <tr>
                                                        <td>
                                                                <input type='hidden' name='id_client' defaultValue={clientSelected?.id_client} />
                                                                <input type='text' name='name_client' placeholder='nombre del cliente' defaultValue={clientSelected?.name_client} />
                                                        </td>
                                                        <td>
                                                                <input type='text' name='rut_client' placeholder='Rut cliente' defaultValue={clientSelected?.rut_client} />
                                                        </td>
                                                        <td>
                                                                <input type='text' name='phone_client' placeholder='Telefono cliente' defaultValue={clientSelected?.phone_client} />
                                                        </td>
                                                        <td>
                                                                <input type='text' name='email_client' placeholder='Email cliente' defaultValue={clientSelected?.email_client} />
                                                        </td>
                                                        <td>
                                                                <input type='text' name='direction_client' placeholder='Direccion cliente' defaultValue={clientSelected?.direction_client} />
                                                        </td>
                                                        <td>
                                                                <select name='payment_accepted'>
                                                                        {methodPayment.map((payment, index) => (
                                                                                <option key={payment.id} value={payment.id}>{payment.name}</option>
                                                                        ))}
                                                                </select>
                                                        </td>
                                                        <td>
                                                                <input type='text' name='number_guide_envoice' placeholder='N°Factura/Guía' />
                                                        </td>
                                                        <td>
                                                                <select name='manage_shipping'>
                                                                        <option value='Automatico'>Automatico</option>
                                                                        <option value='Manual'>Manual</option>
                                                                </select>
                                                        </td>
                                                        <td>
                                                                <input type='text' name='additional_comments' placeholder='Comentarios de envio' defaultValue={clientSelected?.reference_client} />
                                                        </td>
                                                        <td>
                                                                <input type='file' name='voucher_image' />
                                                        </td>
                                                        <td>
                                                                <p style={{ color: 'red' }}>Despues de crear el pedido podras asignar un repartidor</p>
                                                        </td>
                                                        <td>
                                                                <p style={{ color: 'red' }}>Despues de crear el pedido podras enviar correo de compra</p>
                                                        </td>
                                                </tr>
                                        </tbody>
                                </table>
                        </form>
                </div>
        )
}

export default ClientAssociated
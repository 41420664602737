import React, { useContext } from 'react'
import { Horizontal, Vertical } from '../styles/menu'
import Link from '../components/navLink'
import IconMenu from './iconMenu'
import LoginContext from '../contexts/login'
import { AnimatePresence } from "framer-motion"
import User from '../img/svg/user'
import Orders from '../img/svg/orders'


const Menu = () => {

        const { activeMenu, authenticated, logout, navigation } = useContext(LoginContext)

        if (authenticated === null) return
        const { name_user, code_user, email_user, id_user } = authenticated.user[0];

        return (
                <>
                        <Horizontal activeMenu={activeMenu}>
                                <IconMenu />
                                <div className="box_user">
                                        {authenticated !== null ? (
                                                <>
                                                        <span>
                                                                <p>{name_user.charAt(0).toUpperCase()}</p>
                                                        </span>
                                                        <p>{name_user}</p>
                                                        <div className="box_hover_arrow"></div>
                                                        <span>
                                                                <div className="box_hover_data">
                                                                        <span>
                                                                                <p>{code_user}</p>
                                                                        </span>
                                                                        <h3>{name_user}</h3>
                                                                        <p>{email_user}</p>
                                                                </div>
                                                                <div className="box_hover_button">
                                                                        <button onClick={() => navigation(`/workers/update/${id_user}`, { replace: true })}>MIS DATOS</button>
                                                                        <button onClick={logout}>SALIR</button>
                                                                </div>
                                                        </span>
                                                </>
                                        ) : (
                                                <>
                                                        <span>
                                                                <User />
                                                        </span>
                                                        <p>Bienvenido</p>
                                                </>
                                        )}
                                </div>
                        </Horizontal>
                        <AnimatePresence>
                                {!activeMenu && (
                                        <Vertical
                                                key="box_menu"
                                                initial={{ x: '-150px', opacity: 1 }}
                                                animate={{ x: 0, opacity: 1 }}
                                                exit={{ x: '-150px', opacity: 0 }}
                                                transition={{ duration: 0.2 }}
                                        >
                                                <span>
                                                        <h3>Aguadelzar</h3>
                                                </span>
                                                <header>
                                                        <nav>
                                                                <ul>
                                                                        {authenticated.menu.map((item, index) => (
                                                                                <Link key={item.id} to={item.slug}>
                                                                                        <h4 title={item.title}>{item.title}</h4>
                                                                                </Link>
                                                                        ))}
                                                                        {parseInt(id_user) === 66 || parseInt(id_user) === 63 ? (
                                                                                <Link to="/menu">
                                                                                        <h4>Gestionar menu</h4>
                                                                                </Link>
                                                                        ) : null}
                                                                </ul>
                                                        </nav>
                                                </header>
                                        </Vertical>
                                )}
                        </AnimatePresence>
                </>
        )
}

export default React.memo(Menu)
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ContainerPages from '../containerPages'
import styles from '../../styles/updateClient.module.css'
import { useClients } from '../../hooks/useClients'
import { useEffect } from 'react'
import { autoFormat, typeClient } from '../../services/globals'
import { API_FRONT } from '../../services/globals'
import Loading from '../loaderSubmit'

const UpdateClient = () => {

        const { id } = useParams()
        const { updateClient, navigation, findAnClient, client, loading } = useClients()
        const [stateClient, setStateClient] = useState({})

        useEffect(() => {
                findAnClient(id)

        }, [id])

        const onChageState = (e) => {
                let value = parseInt(e.target.value)
                setStateClient({
                        id:value,
                        message:value === 1 ? 'activación de cuenta' : 'rechazo de cuenta'
                })
        }

        if (client === null) return;


        return (
                <ContainerPages>
                        <div className={styles.container_add_client}>
                                <h3>ACTUALIZAR DATOS</h3>
                                <form onSubmit={updateClient}>
                                        <input type="hidden" name="id_client" defaultValue={client[0].id_client ?? ""} />
                                        <label>Nombre y Apellido</label>
                                        <input type="text" name="name_client" defaultValue={client[0].name_client ?? ""} required />
                                        <label>Rut</label>
                                        <input type="text" name="rut_client" defaultValue={client[0].rut_client ?? ""} onChange={autoFormat} />
                                        <label>Email</label>
                                        <input type="email" readOnly defaultValue={client[0].email_client ?? ""} />
                                        <label>Telefono</label>
                                        <input type="number" name="phone_client" defaultValue={client[0].phone_client ?? ""} required />
                                        <label>Tipo cliente</label>
                                        <select name='client_type_client' defaultValue={client[0].client_type_client} required>
                                                {typeClient.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.name}</option>)
                                                )}
                                        </select>
                                        <label>Dirección</label>
                                        <input type="text" name="direction_client" defaultValue={client[0].direction_client ?? ""} required />
                                        <label>Referencia</label>
                                        <input type="text" name="reference_client" defaultValue={client[0].reference_client ?? ""} />
                                        <label>Estado cliente</label>
                                        <select name='state_client' defaultValue={client[0].state_client} required onChange={onChageState}>
                                                <option value='0'>Pendiente</option>
                                                <option value='1'>Activo</option>
                                                <option value='2'>Rechazado</option>
                                        </select>
                                        <label>Documento</label>
                                        {client[0].document_client !== null ? (
                                                client[0].document_client.split('.').pop() === 'pdf' ? (
                                                        <a href={`${API_FRONT}/uploads/document_client/${client[0].document_client}`} target="_blank" rel="noreferrer">Ver documento</a>
                                                ) : (
                                                        <a href={`${API_FRONT}/uploads/document_client/${client[0].document_client}`} alt="documento" target='_blank' rel="noreferrer">Ver imagen</a>
                                                )
                                        ) : (
                                                <p style={{ color: 'red' }}>Sin documento</p>
                                        )}
                                        {[1, 2].includes(stateClient.id) && (
                                                <span className={styles.box_state_client}>
                                                        <label htmlFor='send_mail_confirmation'>¿Enviar correo de {stateClient.message}?</label>
                                                        <input type='checkbox' name='send_mail_confirmation' id='send_mail_confirmation'/>
                                                </span>
                                        )}
                                        <span>
                                                <button type='submit' disabled={loading}>
                                                        {loading ? <Loading /> : 'Guardar'}
                                                </button>
                                                <button type="button" onClick={() => navigation('/clients', { replace: true })}>Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default React.memo(UpdateClient)
import React from 'react'
import ContainerPages from '../containerPages'
import { useDailyManager } from '../../hooks/useDailyManager'
import AddFormSubAccount from './addFormSubAccount'
import AddFormAccount from './addFormAccount'
import styles from '../../styles/addDailyManager.module.css'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const UpdateAccExpences = () => {

        const { id } = useParams()
        const { activeFormAccount, deleteAccount, deleteSubAccount, updateAccExp, updateAccountExpences, getAtAccountExpenses, setActiveFormAccount, activeFormSubAccount, addAccount, addSubAccount, setActiveFormSubAccount, getAcc, getSubAcc } = useDailyManager()

        useEffect(() => {
                getAtAccountExpenses(id)
        }, [id])

        if (updateAccExp === null) return null

        return (
                <ContainerPages>
                        <div className={styles.container_add_manager}>
                                <form onSubmit={updateAccountExpences}>
                                        <span>
                                                <h3>Actualizar cuenta gastos</h3>
                                        </span>
                                        <span>
                                                <input type='hidden' name='id' defaultValue={updateAccExp.id} />
                                                <span>
                                                        <label htmlFor='id_account'>Cuenta</label>
                                                        <button type='button' onClick={() => setActiveFormAccount(true)}>Agregar nuevo</button>
                                                </span>
                                                <select name='id_account' defaultValue={updateAccExp.id_account}>
                                                        {getAcc === 0 ? (
                                                                <option value=''>sin cuentas</option>
                                                        ) : (
                                                                getAcc === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        getAcc.map((item, index) => (
                                                                                <option key={item.id_account} value={item.id_account}>{item.name_account}</option>
                                                                        ))
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <span>
                                                        <label htmlFor='id_subaccount'>Sub cuenta</label>
                                                        <button type='button' onClick={() => setActiveFormSubAccount(true)}>Agregar nuevo</button>
                                                </span>
                                                <select name='id_subaccount' defaultValue={updateAccExp.id_subaccount}>
                                                        {getSubAcc === 0 ? (
                                                                <option value=''>Sin subcuentas</option>
                                                        ) : (
                                                                getSubAcc === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        getSubAcc.map((item, index) => (
                                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label htmlFor='data_date'>Fecha</label>
                                                <input type='datetime-local' name='data_date' defaultValue={updateAccExp.data_date} disabled />
                                        </span>
                                        <span>
                                                <label htmlFor='amount'>Monto</label>
                                                <input type='number' name='amount' defaultValue={updateAccExp.amount} />
                                        </span>
                                        <span>
                                                <button type='submit'>Agregar</button>
                                        </span>
                                </form>
                                <AddFormAccount
                                        setActiveFormAccount={setActiveFormAccount}
                                        activeFormAccount={activeFormAccount}
                                        addAccount={addAccount}
                                        getAcc={getAcc}
                                        deleteAccount={deleteAccount}
                                />
                                <AddFormSubAccount
                                        getSubAcc={getSubAcc}
                                        deleteSubAccount={deleteSubAccount}
                                        activeFormSubAccount={activeFormSubAccount}
                                        setActiveFormSubAccount={setActiveFormSubAccount}
                                        addSubAccount={addSubAccount}
                                />
                        </div>
                </ContainerPages>
        )
}

export default UpdateAccExpences
import styled from "styled-components";

export const Box = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content: center;

    form{
        position: relative;
        display: flex;
        width: 90%;
        height: auto;
        min-height: 400px;
        max-width: 500px;
        flex-wrap: wrap;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0,0.2);
        border-radius:5px;
        align-items:center;
        justify-content: center;

        span{
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            align-items:center;
            justify-content: center;
        }

        span:nth-child(1){
            background-color: #FFFFFF;

            img{
                width: 100%;

            }
        }
        span:nth-child(2){
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            row-gap: 10px;

                h3{
                    padding: 20px 0;
                    color: var(--primaryColor);
                }

                label{
                    width:60%;
                    font-weight: bold;
                    text-align: left;
                    color: var(--primaryColor);
                }
                input{
                    width:60%;
                    height: 30px;
                    border: 1px solid rgba(0, 0, 0,0.2);
                    border-radius: 5px;
                    padding-left: 5px;
                }
                button{
                    margin-top: 20px;
                    padding: 5px 20px;
                    background-color: var(--primaryColor);
                    border: none;
                    color:var(--secundaryBackground);
                    border-radius: 5px;
                    font-weight: bold;
                }
        }

    }
    
    @media screen and (min-width:768px){

        form{
            span:nth-child(1){
                width: 40%;
                height:400px;
            }
            span:nth-child(2){
                width: 60%;
                height: 100%;

            }
        }

    }
`;
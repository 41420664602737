import { Download } from "../../../img/svg/download"
import Search from "../../../img/svg/search"
import styles from '../../../styles/workers.module.css'

const Filters = ({ searching, downloadData }) => {

        return (
                <div className={styles.container_filters}>
                        <form onSubmit={searching}>
                                <span>
                                        <label className={styles.switch}>
                                                <p>ASC</p>
                                                <input type="checkbox" name="order_by" />
                                                <span className={styles.slider}></span>
                                                <p>DESC</p>
                                        </label>
                                </span>
                                <select name="name_order">
                                        <option value="id_user">N°</option>
                                        <option value="name_user">Nombre</option>
                                        <option value="email_user">Email</option>
                                </select>
                                <input type="search" name="search" placeholder="busca por nombre, correo, telefono o direccion" />
                                <button type="submit">
                                        <Search />
                                </button>
                        </form>
                        <button type="button" onClick={() => downloadData()}>
                                <Download />
                        </button>
                </div>
        )
}

export default Filters
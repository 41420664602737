import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/addCash.module.css'
import useSurrenders from '../../hooks/useSurrenders'
import { useWorkers } from '../../hooks/useWorkers'
import DetailsCashControlSeller from '../detailsCashControlSeller'

const AddCash = () => {

        const { onChangeDate, getDataTotalsSeller, addCashControl, totalsSeller, activeDetails, setActiveDetails } = useSurrenders()

        const { getAllWorkers } = useWorkers()

        return (
                <ContainerPages>
                        <div className={styles.container_add_cash}>
                                <form onSubmit={addCashControl}>
                                        <span>
                                                <h3>Nuevo control de caja</h3>
                                        </span>
                                        <span>
                                                <label>Desde</label>
                                                <input type='datetime-local' name='start_date' onChange={onChangeDate} required />
                                        </span>
                                        <span>
                                                <label>Hasta</label>
                                                <input type='datetime-local' name='end_date' onChange={onChangeDate} required />
                                        </span>
                                        <span className={styles.box_seller}>
                                                <div>
                                                        <label>Vendedor</label>
                                                        <select name='id_seller' onChange={onChangeDate} required>
                                                                {getAllWorkers === null ? (
                                                                        <option value='0'>Cargando...</option>
                                                                ) : (
                                                                        getAllWorkers === 0 ? (
                                                                                <option value='0'>No hay vendedores</option>
                                                                        ) : (
                                                                                <>
                                                                                        <option value='0'>Seleccione un vendedor</option>
                                                                                        {getAllWorkers.map((worker, index) => (
                                                                                                <option key={index} value={worker.id_user}>{worker.name_user}</option>
                                                                                        ))}
                                                                                </>
                                                                        )
                                                                )}
                                                        </select>
                                                </div>
                                                <div>
                                                        <button type='button' onClick={() => getDataTotalsSeller()}>Obtener datos</button>
                                                </div>
                                        </span>
                                        <span className={styles.box_table_add_cash}>
                                                <table>
                                                        <caption>Resumen de totales</caption>
                                                        <thead>
                                                                <tr>
                                                                        <th>Efectivo</th>
                                                                        <th>Guía</th>
                                                                        <th>Factura</th>
                                                                        <th>Acciones</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                {totalsSeller?.totals ? (
                                                                        <tr>
                                                                                <td>$ {totalsSeller.totals.cash_payment}</td>
                                                                                <td>$ {totalsSeller.totals.guide_payment}</td>
                                                                                <td>$ {totalsSeller.totals.invoice_payment}</td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                        <button type='button' onClick={() => setActiveDetails(true)}>Detalles</button>
                                                                                </td>
                                                                        </tr>
                                                                ) : (
                                                                        <tr>
                                                                                <td colSpan='4'>No hay datos</td>
                                                                        </tr>
                                                                )}
                                                        </tbody>
                                                </table>
                                        </span>
                                        <span>
                                                <label htmlFor='sales_with_cash'>Efectivo recibido</label>
                                                <input type='number' name='sales_with_cash' required />
                                        </span>
                                        <span>
                                                <label htmlFor='sales_with_guide'>Monto guía</label>
                                                <input type='number' name='sales_with_guide' required />
                                        </span>
                                        <span>
                                                <label htmlFor='sales_with_invoice'>Monto Facturas</label>
                                                <input type='number' name='sales_with_invoice' required />
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                        </span>
                                </form>
                        </div>
                        <DetailsCashControlSeller
                                totalsSeller={totalsSeller}
                                setActiveDetails={setActiveDetails}
                                activeDetails={activeDetails}
                        />
                </ContainerPages >
        )
}

export default AddCash
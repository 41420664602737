import React, { useEffect } from 'react'
import ContainerPages from '../containerPages'
import { useParams } from 'react-router-dom'
import { useControl } from '../../hooks/useControl'
import styles from '../../styles/control.module.css'
const AddArrivalControl = () => {

        const { id } = useParams()
        const { getOneControl, getAtControl, updateArrivalControl, navigation } = useControl()

        useEffect(() => {
                getOneControl(id)
        }, [])

        if (getAtControl === null) return (<ContainerPages><h3>Cargando...</h3></ContainerPages>)

        return (
                <ContainerPages>
                        <div className={styles.container_arrival_control}>
                                <form onSubmit={updateArrivalControl}>
                                        <h3>NUEVO CONTROL DE LLEGADA</h3>
                                        <span>
                                                <label>Trabajador</label>
                                                <input type="text" readOnly disabled defaultValue={getAtControl.name_deliveryman_drumcontrol} />
                                                <input type="hidden" name='id_drumcontrol' defaultValue={getAtControl.id_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>20Lt Lleno</label>
                                                <input type="number" name='arrival_20L_full_drumcontrol' defaultValue={getAtControl.arrival_20L_full_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>20Lt Vacio</label>
                                                <input type="number" name='arrival_20L_empty_drumcontrol' defaultValue={getAtControl.arrival_20L_empty_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>20Lt Prestado</label>
                                                <input type="number" name='arrival_20L_taken_drumcontrol' defaultValue={getAtControl.arrival_20L_taken_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>20Lt Recuperado</label>
                                                <input type="number" name='arrival_20L_recovered_drumcontrol' defaultValue={getAtControl.arrival_20L_recovered_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Lleno</label>
                                                <input type="number" name='arrival_10L_full_drumcontrol' defaultValue={getAtControl.arrival_10L_full_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Vacio</label>
                                                <input type="number" name='arrival_10L_empty_drumcontrol' defaultValue={getAtControl.arrival_10L_empty_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Prestado</label>
                                                <input type="number" name='arrival_10L_taken_drumcontrol' defaultValue={getAtControl.arrival_10L_taken_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Recuperado</label>
                                                <input type="number" name='arrival_10L_recovered_drumcontrol' defaultValue={getAtControl.arrival_10L_recovered_drumcontrol} />
                                        </span>
                                        <span>
                                                <label htmlFor='firm_arrival_drumcontrol'>Firmar</label>
                                                <select name='firm_arrival_drumcontrol' id='firm_arrival_drumcontrol' defaultValue='0'>
                                                        <option value="0">No</option>
                                                        <option value="1">Si</option>
                                                </select>
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type='button' onClick={() => navigation('/control', { replace: true })}>Volver</button>
                                        </span>
                                </form>
                        </div>

                </ContainerPages>
        )
}

export default AddArrivalControl
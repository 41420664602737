export const BASE_API = "https://apiaguadelzar.aguadelzar.cl/"
export const API = "https://api.aguadelzar.cl/administrador";
//export const API = "http://localhost/api/administrador";
//export const API_FRONT = "http://localhost/api/cliente";
export const API_FRONT = "https://api.aguadelzar.cl/cliente";
//export const BASE_API = "http://localhost/api_aguadelzar/"
export const chileanCurrency = (number) => { return Intl.NumberFormat("es-CL").format(number) }
export const specialPermission = [63, 66] //prox... extraer desde base de datos
export const typeClient = [
        { id: 1, name: 'Particular' },
        { id: 2, name: 'Empresa' },
        { id: 3, name: 'Distribuidor' },
]

export const listAction = [
        { id: 0, name: 'Seleccione una accion' },
        { id: 1, name: 'Limpieza' },
        { id: 2, name: 'Pintura' },
]
export const methodPayment = [
        { id: 1, name: 'Pendiente de pago' },
        { id: 2, name: 'Procesando' },
        { id: 3, name: 'Pagado con transferencia' },
        { id: 4, name: 'Pagado con getnet' },
        { id: 5, name: 'Anulado/Cancelado' },
        { id: 6, name: 'Pagado con efectivo' },
        { id: 7, name: 'Pagado con Guía' },
        { id: 8, name: 'Pagado con Factura' },
        { id: 9, name: 'Por Pagar' },
]

export const typeAssignments = [
        { id: 1, name: 'Entrada' },
        { id: 2, name: 'Salida' }
]
export const validatorRut = (value) => {

        let rutV = value.substring(0, value.length - 1).replace(/\D/g, "");
        let digitoV = value.substring(value.length - 1);
        let arrayRut = rutV.split("").reverse();
        let acum = 0;
        let mult = 2;
        for (let num of arrayRut) {
                acum += parseInt(num) * mult;
                mult++;

                if (mult == 8) {
                        mult = 2;
                }
        }
        let dv = 11 - (acum % 11);

        if (dv == 11) {
                dv = "0";
        }
        if (dv == 10) {
                dv = "k";
        }

        let rutFormat = (Intl.NumberFormat("es-CL").format(rutV) + '-' + digitoV) === '0-' ? '' : Intl.NumberFormat("es-CL").format(rutV) + '-' + digitoV
        let rut = {
                format: rutFormat,
                isValid: digitoV.toLowerCase() == dv
        }
        return rut;

}

export const autoFormat = (event) => {
        let value = event.target.value;

        let newtext = value.replace(/[.-]/g, "");
        let lastDigit = newtext.substring(newtext.length - 1);
        let number = newtext.substring(0, newtext.length - 1);
        if (number.length > 0) {
                // Formatear el número como miles en el formato chileno
                const formattedValue = parseInt(number).toLocaleString("es-CL");

                // Verificar si el guión al final del valor del campo de entrada existe
                let finalValue = formattedValue + lastDigit;
                if (finalValue.slice(-2, -1) !== "-") {
                        finalValue = finalValue.slice(0, -1) + "-" + finalValue.slice(-1);
                }

                // Actualizar el valor del campo de entrada
                event.target.value = finalValue;
        } else {
                // Si el valor actual no tiene números, actualizar el valor del campo de entrada con el último carácter ingresado
                event.target.value = lastDigit;
        }
}

export const currentTime = () => {
        const timeNow = new Date()
        let day = timeNow.getDate()
        let month = timeNow.getMonth() + 1
        let year = timeNow.getFullYear()
        day = ('0' + day).slice(-2)
        month = ('0' + month).slice(-2)
        let monthInitial = timeNow.getMonth()
        monthInitial = ('0' + monthInitial).slice(-2)
        let today = `${year}-${month}-${day}`
        let aMonthAgo = `${year}-${monthInitial}-${day}`

        return today;
}


export const dateTime = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        const currentTime = `${year}-${month}-${day}T23:59`;
        const currentDate = `${year}-${month}-${day}`;
        const currentDay = `${year}-${month}-${day}T00:00`;
        const initialMonth = `${year}-${month}-01T00:00`;
        const initialMonthDate = `${year}-${month}-01`;

        return { currentTime, currentDay, currentDate, initialMonth,initialMonthDate };
}
export const randomNumer = () => {
        let min = 1000;
        let max = 100000;

        let x = Math.floor(Math.random() * (max - min + 1) + min);
        return x;
}

export const monthOfYear = (month) => {
        if (Number(month) > 12) {
                return false;
        }
        let months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

        return months[Number(month) - 1]
}

import { motion } from "framer-motion";
import styled from "styled-components";


export const BoxDrum = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    padding:10px ;

    button{
        padding: 5px 20px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border-radius : 5px;
        border:none;
        cursor:pointer;

    }

`;

export const Container = styled.div`

    width: 100%;
    height: auto;
    overflow-y: scroll;
    padding:10px;

table{
    display: flex;
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 2400px;
    flex-direction: column;

    .header-title{
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
    
        tr{
        display: flex;
        place-content: center;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: center;
            th{
                display: grid;
                border: 1px solid rgba(0,0,0,0.2);
                height: 100%;
                place-content: center;
            }

            th:nth-child(1){
                width:5% ;
            }
            th:nth-child(2){
                width:20% ;
            }
            th:nth-child(3){
                width:30% ;
            }
            th:nth-child(4){
                width:20% ;
            }
            th:nth-child(5){
                width:20% ;
            }
            th:nth-child(6){
                width:5%;
            }
        }    
    } 

    .header-subtitle{
        display:flex;

        tr{
            th,td{
                display: grid;
                height: 100%;
                font-size: .8em;
                place-content: center;
                border: 1px solid rgba(0,0,0,0.2);
            }
        }

        tr:nth-child(1){
            width: 5%;
            display: grid;
            grid-template-columns:1;
        }
        tr:nth-child(2){
            width: 20%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
        }
        tr:nth-child(3){
            width: 30%;
            display: grid;
            grid-template-columns: repeat(10, 1fr);
        }
        tr:nth-child(4){
            width: 20%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
        }
        tr:nth-child(5){
            width: 20%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        tr:nth-child(6){
            width: 5%;
            display: grid;
            grid-template-columns:1;
        }
    }
    tbody tr:nth-child(6) td button{
        display: grid;
        width: 20px;
        height: 20px;
        place-content: center;
        font-size: 1em;
        font-weight: bold;
        border:none;
        background-color:red;
        color:#FFFFFF;
        border-radius:5px;
    }

    tbody tr td {
        padding: 5px 0;
    }
}

`;

export const ContainerAddExit = styled(motion.div)`

    position: fixed;
    display:flex;
    width: 100%;
    height: 100vh;
    padding:20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    transition: width 0.2s ease;
    right: 0;
    z-index: 1;

    form{
        position: relative;
        display:flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        max-height: 80vh;
        align-items: center;
        justify-content: flex-start;
        background-color:#FFFFFF;
        flex-wrap: wrap;
        border-radius: 5px;
        gap: 20px;
        padding: 20px;
        overflow-y: scroll;


        span:not(span:nth-child(1), span:nth-child(2)){
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;

            input{
                width: 100%;
                height: 40px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.2);
                font-size: 1em;
                padding-left: 15px;
                color:#999999;
            }

        }
        span:nth-child(1){
            display: grid;
            width:100%;
            place-content: center;
        }

        span:nth-child(2){
            width: calc(100% + 10px);

        }

        span:nth-child(8){
            flex-direction: initial !important;
            padding: 10px;
            align-items: center;
            justify-content:center;
            column-gap: 20px;

            button{
                width:100px;
                padding: 5px 0;
                border: none;
                border-radius: 5px;
                color: var(--secundaryBackground);
                cursor: pointer;

            }
            button:nth-child(1){
                background-color:var(--primaryColor);

                &:disabled{
                    background-color:rgba(0,0,0,0.2);

                }
            }
            button:nth-child(2){
                background-color:red;
            }
        }
    }

    @media screen and (min-width:768px){
       
        form{
            
            justify-content: flex-start;
            span:not(span:nth-child(1),span:nth-child(2),span:nth-child(8)){
            width:calc(50% - 10px) !important;
            }
            span:nth-child(1),
            span:nth-child(2), 
            span:nth-child(8){
                width:100% !important;
            }

        }
    }

`;


export const ContainerAddArrival = styled(motion.div)`



    position: fixed;
    display:flex;
    width: 100%;
    height: 100vh;
    padding:20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    transition: width 0.2s ease;
    right: 0;
    z-index: 1;

    form{
        position: relative;
        display:flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        max-height: 80vh;
        align-items: center;
        justify-content: flex-start;
        background-color:#FFFFFF;
        flex-wrap: wrap;
        border-radius: 5px;
        gap: 20px;
        padding: 20px;
        overflow-y: scroll;


        span:not(span:nth-child(1), span:nth-child(2)){
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;

            input{
                width: 100%;
                height: 40px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.2);
                font-size: 1em;
                padding-left: 15px;
                color:#999999;
            }

        }
        span:nth-child(1){
            display: grid;
            width:100%;
            place-content: center;
        }

        span:nth-child(2){
            width: calc(100% + 10px);

        }

        span:nth-child(11){
            flex-direction: initial !important;
            padding: 10px;
            align-items: center;
            justify-content:center;
            column-gap: 20px;

            button{
                width:100px;
                padding: 5px 0;
                border: none;
                border-radius: 5px;
                color: var(--secundaryBackground);
                cursor: pointer;

            }
            button:nth-child(1){
                background-color:var(--primaryColor);

                &:disabled{
                    background-color:rgba(0,0,0,0.2);

                }
            }
            button:nth-child(2){
                background-color:red;
            }
        }
    }

    @media screen and (min-width:768px){
       
        form{
            
            justify-content: flex-start;
            span:not(span:nth-child(1),span:nth-child(2),span:nth-child(11)){
            width:calc(50% - 10px) !important;
            }
            span:nth-child(1),
            span:nth-child(2), 
            span:nth-child(11){
                width:100% !important;
            }

        }
    }

`;

export const ContainerAddSales = styled(motion.div)`

    position: fixed;
    display:flex;
    width: 100%;
    height: 100vh;
    padding:20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    transition: width 0.2s ease;
    right: 0;
    z-index: 1;

    form{
        position: relative;
        display:flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        max-height: 80vh;
        align-items: center;
        justify-content: flex-start;
        background-color:#FFFFFF;
        flex-wrap: wrap;
        border-radius: 5px;
        gap: 20px;
        padding: 20px;
        overflow-y: scroll;


        span:not(span:nth-child(1), span:nth-child(2)){
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;

            input{
                width: 100%;
                height: 40px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.2);
                font-size: 1em;
                padding-left: 15px;
                color:#999999;
            }

        }
        span:nth-child(1){
            display: grid;
            width:100%;
            place-content: center;
        }

        span:nth-child(6){
            width: calc(100% + 10px);

        }

        span:nth-child(6){
            flex-direction: initial !important;
            padding: 10px;
            align-items: center;
            justify-content:center;
            column-gap: 20px;

            button{
                width:100px;
                padding: 5px 0;
                border: none;
                border-radius: 5px;
                color: var(--secundaryBackground);
                cursor: pointer;

            }
            button:nth-child(1){
                background-color:var(--primaryColor);

                &:disabled{
                    background-color:rgba(0,0,0,0.2);
                }
            }
            button:nth-child(2){
                background-color:red;
            }
        }
    }

    @media screen and (min-width:768px){
       
        form{
            
            justify-content: flex-start;
            span:not(span:nth-child(1),span:nth-child(2),span:nth-child(6)){
            width:calc(50% - 10px) !important;
            }
            span:nth-child(1),
            span:nth-child(2), 
            span:nth-child(6){
                width:100% !important;
            }

        }
    }

`;
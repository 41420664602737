import React, { useEffect } from 'react'
import ContainerPages from '../containerPages'
import { useControl } from '../../hooks/useControl'
import { useParams } from 'react-router-dom'
import styles from '../../styles/control.module.css'

const SalesControl = () => {

        const { submitSalesControl, getAtControl, getOneControl, navigation,loading} = useControl()

        const { id } = useParams()

        useEffect(() => {
                getOneControl(id)
        }, [])

        if (getAtControl === null) return (<ContainerPages><h3>Cargando...</h3></ContainerPages>)

        return (
                <ContainerPages>
                        <div className={styles.container_sales_control}>
                                <form onSubmit={submitSalesControl}>
                                        <h3>NUEVO CONTROL DE VENTA</h3>
                                        <span>
                                                <label>Trabajador</label>
                                                <input type="hidden" name='id_drumcontrol' defaultValue={getAtControl.id_drumcontrol} />
                                                <input type="text" readOnly disabled defaultValue={getAtControl.name_deliveryman_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>20Lt</label>
                                                <input type="number" name='sale_20L_drumcontrol' defaultValue={getAtControl.sale_20L_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Retornable</label>
                                                <input type="number" name='sale_10L_returnable_drumcontrol' defaultValue={getAtControl.sale_10L_returnable_drumcontrol} />
                                        </span>
                                        <span>
                                                <label>10Lt Desechable</label>
                                                <input type="number" name='sale_10L_disposable_drumcontrol' defaultValue={getAtControl.sale_10L_disposable_drumcontrol} />
                                        </span>
                                        <span>
                                                <button type='submit' disabled={loading}>{loading ? 'Guardando...' : 'Guardar'}</button>
                                                <button type='button' onClick={() => navigation('/control', { replace: true })} >Volver</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default SalesControl
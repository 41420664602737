import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/workers.module.css'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useWorkers } from '../../hooks/useWorkers'
import { useContext } from 'react'
import LoginContext from '../../contexts/login'
import Multiselect from "react-widgets/Multiselect";
import { specialPermission } from '../../services/globals'

const UpdateWorker = () => {

        const { id } = useParams()
        const { getWorker, worker, menuSelected, setMenuSelected, updateWorker, subMenuSelected, setSubMenuSelected } = useWorkers()

        const { menu, submenu, navigation, authenticated } = useContext(LoginContext)

        useEffect(() => {
                getWorker(id)
        }, [id])

        if (worker === null) return;
        if (menu === null) return;
        if (submenu === null) return;

        return (
                <ContainerPages>
                        <div className={styles.container_update_worker}>
                                <span>
                                        <h3>ACTUALIZAR USUARIO</h3>
                                </span>
                                <form onSubmit={updateWorker}>
                                        <input type="hidden" name="id_user" defaultValue={worker.id_user} />
                                        <label>Nombre</label>
                                        <input name="name_user" defaultValue={worker.name_user ?? ""} required />
                                        <label>Código</label>
                                        <input name="code_user" defaultValue={worker.code_user ?? ""} required />
                                        <label>Email</label>
                                        <input name="email_user" defaultValue={worker.email_user ?? ""} readOnly />
                                        <label>Email Personal</label>
                                        <input type="email" name="email_personal_user" defaultValue={worker.email_personal_user ?? ""} />
                                        <label>Cuenta/banco</label>
                                        <input type="text" name="bank_account_user" defaultValue={worker.bank_account_user ?? ""} />
                                        <label>Dirección</label>
                                        <input type="text" name="direction_user" defaultValue={worker.direction_user ?? ""} />
                                        <label>Rut </label>
                                        <input name="rut_user" defaultValue={worker.rut_user ?? ""} />
                                        <label>Cargo </label>
                                        <input name="rol_user" defaultValue={worker.rol_user ?? ""} />
                                        <label>Telefono</label>
                                        <input name="phone_user" defaultValue={worker.phone_user ?? ""} />
                                        {specialPermission.includes(parseInt(authenticated?.user[0].id_user)) && (
                                                <>
                                                        <label>Permisos Principales</label>
                                                        <Multiselect style={{ width: '100%' }}
                                                                dataKey="id"
                                                                textField="title"
                                                                value={menuSelected}
                                                                data={menu}
                                                                onChange={(data) => setMenuSelected(data)} />
                                                        <label style={{ paddingTop: '15px' }}>Permisos Adicionales</label>
                                                        <Multiselect style={{ width: '100%' }}
                                                                dataKey="id"
                                                                textField="description"
                                                                value={subMenuSelected}
                                                                data={submenu}
                                                                onChange={(data) => setSubMenuSelected(data)} />
                                                </>
                                        )}

                                        <label>Contraseña</label>
                                        <input name="password_user" />
                                        <span>
                                                <b>NOTA:</b>
                                                <ul>
                                                        <li>Si no desea cambiar la <b>contraseña</b>, deje el campo vacio</li>
                                                        <li>Si actualizas los <b>permisos</b> de este usuario ,el usuario tendra que  cerrar y volver a iniciar session</li>
                                                </ul>
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type='button' onClick={() => navigation('/workers', { replace: true })}>Volver sin guardar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default UpdateWorker
import React from 'react'
import ContainerPages from '../components/containerPages'
import Filters from '../components/assignments/filters'
import ListAssignments from '../components/assignments/listAssignments'
import { useAssignment } from '../hooks/useAssignment'
import styles from '../styles/assignments.module.css'
const Assignments = () => {

        const { permissions, allAssignments, inititalFilters, filterAssignments, downloadAssignments } = useAssignment()

        return (
                <ContainerPages>
                        <div className={styles.title_assigment}>
                                <h3>ASIGNACIONES DIARIAS {allAssignments?.total ?? null}</h3>
                                <button type="button" onClick={() => permissions('/assignments/add', 'submenu')}>
                                        <p>AGREGAR</p>
                                </button>
                        </div>
                        <Filters
                                filterAssignments={filterAssignments}
                                inititalFilters={inititalFilters}
                                downloadAssignments={downloadAssignments}
                        />

                        <ListAssignments allAssignments={allAssignments} />
                </ContainerPages>
        )
}

export default Assignments
import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/caps.module.css'
import { useWorkers } from '../../hooks/useWorkers';
import useCaps from '../../hooks/useCaps';

const AddCaps = () => {

        const { getAllWorkers } = useWorkers();
        const { createCaps } = useCaps();

        return (
                <ContainerPages>
                        <div className={styles.container_add_caps}>
                                <form onSubmit={createCaps}>
                                        <h3>Nuevo control de tapas</h3>
                                        <span>
                                                <select name='id_user'>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay datos</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        getAllWorkers.map((item, index) => (
                                                                                <option key={item.id_user} value={item.id_user}>{item.name_user}</option>
                                                                        ))
                                                                )
                                                        )}
                                                        <option value=''>Seleccionar</option>
                                                </select>
                                        </span>
                                        <span>
                                                <label>Tapas 20LTS retornable</label>
                                                <input type='number' name='caps_20lt_returnable' placeholder='tapas 20Lts retor.' />
                                        </span>
                                        <span>
                                                <label>Tapas 10LTS retornable</label>
                                                <input type='number' name='caps_10lt_returnable' placeholder='tapas 10Lts retor.' />
                                        </span>
                                        <span>
                                                <label>Tapas 10LTS desechable</label>
                                                <input type='number' name='caps_10lt_disposable' placeholder='tapas 10Lts desc.' />
                                        </span>
                                        <span>
                                                <label>Tapas devueltas 20LTS retornable</label>
                                                <input type='number' name='returned_20lt_returnable' placeholder='tapas devueltas 20Lts retor.' />
                                        </span>
                                        <span>
                                                <label>Tapas devueltas 10LTS retornable</label>
                                                <input type='number' name='returned_10lt_returnable' placeholder='tapas devueltas 20Lts retor.' />
                                        </span>
                                        <span>
                                                <label>Tapas devueltas 10LTS desechable</label>
                                                <input type='number' name='returned_10lts_disposable' placeholder='tapas devueltas 20Lts retor.' />
                                        </span>
                                        <span>
                                                <button type='submit'>Agregar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddCaps
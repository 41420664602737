import React, { useContext } from 'react'
import ContainerPages from '../../components/containerPages'
import LoginContext from '../../contexts/login'
import { useFetch } from '../../hooks/useFetch'
import { useProducts } from '../../hooks/useProducts'
import { useClients } from '../../hooks/useClients'
import Administrator from '../../img/svg/administrator'
import Orders from '../../img/svg/orders'
import Sales from '../../img/svg/sales'
import Store from '../../img/svg/store'
import User from '../../img/svg/user'
import { Box, BoxTable } from '../../styles/dashboard'
import Card from './components/card'
import LastClientAdded from './components/lastClientAdded'
import LastProductAdded from './components/lastProductAdded'
import LastProductSold from './components/lastProductSold'
import { useWorkers } from '../../hooks/useWorkers'

const Dashboard = () => {

    const {orderCompleted, totalOrderNow } = useFetch()
    const { permissions } = useContext(LoginContext);
    const { totalProducts } = useProducts()
    const { totalClient } = useClients()
    const {totalWorkers} = useWorkers()

    return (
        <ContainerPages>
            <Box>
                <Card title='Clientes' count={totalClient ?? 0} color="#FFFFFF" background="#5564eb" onClick={() => permissions('/clients','menu')}>
                    <User />
                </Card>
                <Card title='Productos' count={totalProducts ?? 0} color="#FFFFFF" background="var(--secundaryColor)" onClick={() => permissions('/products','menu')}>
                    <Store />
                </Card>
                <Card title='Trabajadores' count={totalWorkers ?? 0} color="#FFFFFF" background="#009975" onClick={() => permissions('/workers','menu')}>
                    <Administrator />
                </Card>
                <Card title='Ventas' count={orderCompleted} color="#FFFFFF" background="#ffa420">
                    <Sales />
                </Card>
                <Card title='Pedidos hoy' count={totalOrderNow} color="#FFFFFF" background="#ffa420" onClick={() => permissions('/orders','menu')}>
                    <Orders />
                </Card>
                <Card title='Rendición de caja diaria' color="#FFFFFF" background="#ffa420" onClick={() => permissions('/surrenders','menu')}>
                    <Sales />
                </Card> 
                <Card title='Informes' color="#FFFFFF" background="#ffa420" onClick={() => permissions('/reports','menu')}>
                    <Sales />
                </Card>
            </Box>
            <BoxTable>
                <LastClientAdded />
                <LastProductAdded />
            </BoxTable>
        </ContainerPages>
    )
}

export default React.memo(Dashboard)
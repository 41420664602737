import { API, BASE_API } from "./globals";

export const GET = ({
    table = null,
    select = 'select=*',
    linkTo = null,
    search = null,
    startAt = null,
    endAt = null,
    orderBy = null,
    orderMode = null
}) =>
    fetch(`${BASE_API}${table}?${select}&${linkTo}&${search}&${startAt}&${endAt}&${orderBy}&${orderMode}`, {
        method: 'GET',
    })
        .then(response => response.json())
        .catch(err => console.log(err))

export const SEARCH = ({
    table = null,
    search = true,
    columns = "",
    value = ""
}) =>
    fetch(`${BASE_API}${table}?search=${search}&columns=${columns}&value=${value}`)
        .then(response => response.json())
        .catch(err => console.log(err))


export const RANGE = ({
    table = null,
    select = null,
    linkTo = null,
    rangeInitial = null,
    rangeFinal = null
}) =>
    fetch(`${BASE_API}${table}?${select}&${linkTo}&between1=${rangeInitial}&between2=${rangeFinal}`)
        .then(response => response.json())
        .catch(err => console.log(err))

export const GET_LOC = ({
    table = null,
    params = null
}) =>
    fetch(`${API}/${table}${params}`,{
        method:'GET'
    })
        .then(response => response.json())
        .catch(err => console.log(err))
import React from 'react'
import ContainerPages from '../components/containerPages'
import styles from '../styles/shipping.module.css'
import { useShipping } from '../hooks/useShipping'
import DeliveryStatus from '../components/shipping/deliveryStatus'
import Filters from '../components/shipping/filters'

const Shipping = () => {

        const { getAllShipping, filterShipping, downloadShipping, inititalFilters } = useShipping()

        return (
                <ContainerPages>
                        <div className={styles.container_shipping}>
                                <div className={styles.box_title}>
                                        <h3>CONTROL DE ENVIOS </h3>
                                        <strong>20</strong>
                                </div>
                                <Filters
                                        inititalFilters={inititalFilters}
                                        filterShipping={filterShipping}
                                        downloadShipping={downloadShipping}
                                />
                                <div className={styles.box_table_shipping}>
                                        <table>
                                                <thead>
                                                        <tr>
                                                                <th>N°</th>
                                                                <th>Producto asociado</th>
                                                                <th>Vendedor asignado</th>
                                                                <th>Cantidad a entregar</th>
                                                                <th>Estado envio</th>
                                                                <th>Comentarios de entrega</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {getAllShipping === 0 && <tr><td colSpan="6">No hay envios</td></tr>}
                                                        {getAllShipping !== 0 && getAllShipping?.map((item, index) => (
                                                                <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.name_product}</td>
                                                                        <td>{item.name_user}</td>
                                                                        <td>{item.quantity}</td>
                                                                        <td>
                                                                                <DeliveryStatus status={item} />
                                                                        </td>
                                                                        <td>{item.comments}</td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                                <tfoot>
                                                        <tr>
                                                                <td colSpan="3">Totales</td>
                                                                {/*<td colSpan='3'>{getAllShipping?.reduce((a, b) => a + parseInt(b.quantity), 0) ?? 0}</td> */}

                                                        </tr>
                                                </tfoot>
                                        </table>
                                </div>
                        </div>
                </ContainerPages>
        )
}

export default Shipping
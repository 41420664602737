import { motion } from "framer-motion";
import styled from "styled-components";

export const Title = styled.div`
    position: relative;
    display:flex;
    width:100%;
    overflow: hidden;
    min-height: 50px;
    row-gap:10px;
    align-items:center;
    gap: 20px;

    h3{
        position:relative;
        width:auto;
        text-align: left;
        font-size: 1.4em;

    }
    
    button{
        display:flex;
        width:auto;
        height: 30px;
        padding: 10px;
        background-color:var(--primaryColor);
        align-items:center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        border: none;

        p{
            color: var(--secundaryBackground);
            font-size:1em;
            font-weight: bold;

        }
    }
`;

export const Form = styled(motion.div)`

    position: fixed;
    display:flex;
    width: 100%;
    height: 100vh;
    padding:20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    transition: width 0.2s ease;
    right: 0;

    form{
        position: relative;
        display:flex;
        width: 95%;
        max-width: 800px;
        height: auto;
        align-items: center;
        justify-content: center;
        background-color:#FFFFFF;
        flex-direction: column;
        border-radius: 5px;
        padding: 20px;
        row-gap: 5px;


        label{
            width: 100%;
            font-weight: bold;
            padding-bottom: 5px;
        }

        input{
            width:100%;
            height:40px;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.2);
            font-size: 1.2em;
            padding-left: 5px;
        }
        span{
            display: flex;
            width:100%;
            column-gap: 20px;
            padding: 20px;
            align-items: center;
            justify-content: center;

            button{
                width: 100px;
                padding:5px 0;
                cursor: pointer;
                border:none;
                border-radius: 5px;
                font-weight: bold;
                color:var(--secundaryBackground);
            }

            button:nth-child(1){
                background-color: var(--primaryColor);
            }

            button:nth-child(2){
                background-color: red;
            }
        }
    }

    @media screen and (min-width:768px){
        width: ${({ activeMenu }) => activeMenu ? '100%' : 'calc(100% - 150px)'};

        form{
        overflow-y: initial;
        column-gap: 20px;
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: flex-start;

            label{
                width: calc(25% - 20px);
            }
            input{
                width: calc(25% - 20px);
            }
        }
    }
`;

export const BoxLoader = styled.div`
    position: absolute;
    display: grid;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color:rgba(255,255,255,.8);
    place-content: center;
`;
export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: hidden;
    padding:0 20px;
`;
export const DataTable = styled.div`

    width: 100%;
    height: auto;
    overflow-y: scroll;

table{
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 1000px;

    thead {
    display:flex;
    position: relative;
    background-color: var(--primaryColor);
    color: var(--secundaryBackground);
    
        tr {
        display: grid;
        place-content: center;
        width: 100%;
        height: 40px;
        grid-template-columns: 10% 10% 20% 10% 15% 15% 20%;
        }
    
    }
    tbody{
        height: auto;
    
    &:nth-child(odd){
        background-color: var(--secundaryBackground);
    }
        tr{
            display: grid;
            width: 100%;
            min-height: 40px;
            grid-template-columns: 10% 10% 20% 10% 15% 15% 20%;
    
            th{
                display: flex;
                height: 100%;
                justify-content: center;
                font-weight: normal;
                gap: 10px;
                align-items: center;
                
    
                button{
                    display: grid;
                    width: 35px;
                    height: 35px;
                    border: 1px solid rgba(0,0,0, 0.2);
                    border-radius: 5px;
                    cursor: pointer;
                    place-content: center;
    
                    svg{
                        width: 1.2em;
                        height: 1.2em;
                    }
                }
                button:nth-child(1){
                    width: 90px;
                    font-weight: bold;

                }
                button:nth-child(2){
                    svg{
                        fill:var(--secundaryColor);
                    }

                }
                button:nth-child(3){
                    svg{
                        fill:red;
                    }
                }
            }
        }
    
    }
    
}

`;

export const Filter = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    padding: 20px;
    padding-top: 0;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    div:nth-child(1){
        display: flex;
        width: 100%;
        padding: 10px;
        border: 1px solid rgba(0,0,0,0.2);

        span{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap:10px;

        }
    }
    div:nth-child(3){
        width: 100%;

        form{
            display: flex;
            width: 100%;

            input{
                width: calc(100% - 40px);
                height: 40px;
                font-size: 1em;
                border: 1px solid rgba(0,0,0,0.2);
                border-radius: 5px 0 0 5px;
                padding-left:5px ;
            }
            button{
                display:grid;
                width:40px;
                height: 40px;
                place-content: center;
                border: 1px solid rgba(0,0,0,0.2);
                border-radius: 0 5px 5px 0;
                border-left: none;
            }
        }
    }

    @media screen and (min-width:768px){

        div:nth-child(1){

            width: 49%;
        }
    }
        
    @media screen and (min-width:1330px){
        div:nth-child(1){

            width:20%;
        }
        div:nth-child(3){
            width: 58%;
        }

    }

`;

export const Switch = styled.span`
    display:flex;
    width:100%;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p{
        padding:10px;
        text-align: center;
    }

    span{
        position: relative;
        display: flex;
        width:120px;
        height:30px;
        border-radius: 15px;
        border: 1px solid rgba(0,0,0,0.2);
        column-gap:0;
        
        button{
            display: flex;
            width: 50%;
            height: 100%;
            border:none;
            background-color:transparent;
            align-items: center;
            justify-content: center;
            color:var(--primaryColor);
            cursor: pointer;
            font-weight: bold;
            transform: translateZ(0);
            transition: color 0.3s ease;
        }

        &::before{
            position: absolute;
            content:'';
            display:flex;
            width: 60px;
            height: 105%;
            font-size: .8em;
            background: var(--primaryColor);
            transform: translateX(0px);
            top:-1px;
            transition:transform .3s ease, border-radius .3s ease;
            border-radius:${({ orderMode }) => orderMode === 'ASC' ? '26px 0 0 26px' : '0 26px 26px 0'};
            transform: ${({ orderMode }) => orderMode === 'ASC' ? 'translateX(0)' : 'translateX(100%)'};
        }
    }

    @media screen and (min-width:768px){
        width:49%;
        flex-direction: initial;

        p{
        padding:0;
        padding-right: 10px;
        text-align: left;
    }
    }

    
    @media screen and (min-width:1330px){
        width:20%;

    }
`;
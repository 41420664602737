import React from 'react'
import styles from '../../styles/surrenders.module.css'
import useSurrenders from '../../hooks/useSurrenders'
import ListItem from './listItem'

const ListCashControl = () => {

        const { allSurrenders, deleteCashControl } = useSurrenders()

        return (
                <div style={{ width: '100%' }} className={styles.container_surrenders}>
                        <table>
                                <thead>
                                        <tr>
                                                <th>N°</th>
                                                <th>Responsable</th>
                                                <th>Vendedor</th>
                                                <th>Total efectivo pedidos</th>
                                                <th>Total guia pedidos</th>
                                                <th>Total factura pedidos</th>
                                                <th>Efectivo entregado</th>
                                                <th>Monto guia entregados</th>
                                                <th>Monto factura entregados</th>
                                                <th>Diferencia efectivo</th>
                                                <th>Diferencia guia</th>
                                                <th>Diferencia factura</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {allSurrenders === null ? (
                                                <tr><td>Cargando...</td></tr>
                                        ) : (
                                                allSurrenders === 0 ? (
                                                        <tr><td>No hay datos</td></tr>
                                                ) : (
                                                        allSurrenders.results.map((surrender, index) => (
                                                                <ListItem
                                                                        key={surrender.id}
                                                                        item={surrender}
                                                                        deleteCashControl={deleteCashControl}
                                                                />
                                                        ))
                                                )
                                        )}
                                </tbody>
                        </table>
                </div>
        )
}

export default ListCashControl
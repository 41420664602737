import { useContext, useState, useEffect } from 'react'
import { GET_LOC } from '../services/get'
import { API, dateTime, specialPermission } from '../services/globals'
import LoginContext from '../contexts/login'

export const useShipping = () => {

        const { authenticated, setToast, activeToast } = useContext(LoginContext)
        const [list, setList] = useState(null)
        const [getAllShipping, setGetAllShipping] = useState(null)
        const [loading, setLoading] = useState(false)
        const [saveDataFilter, setSaveDataFilter] = useState(null)
        const { currentTime, currentDay } = dateTime()
        const inititalFilters = {
                start_date: currentDay,
                end_date: currentTime,
                order_mode: 'ASC',
                order_by: 'shipping.id',
                search: ''
        }

        const getShipping = (filters) => {
                setLoading(true)

                const { start_date, end_date, order_mode, order_by, search } = filters

                GET_LOC({
                        table: 'shipping',
                        params: `?action=list&start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`
                }).then(response => {
                        if (response.status === 200) {
                                setGetAllShipping(response.results)
                        } else {
                                setGetAllShipping(0)
                        }
                }).finally(() => {
                        setLoading(false)
                })
        }


        const filterShipping = (e) => {
                e.preventDefault();

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                let data = Object.fromEntries(new FormData(e.target))
                data.order_mode = data.order_mode === 'on' ? 'DESC' : 'ASC'
                setSaveDataFilter(data)
                getShipping(data)
        }

        useEffect(() => {
                getShipping(inititalFilters)

        }, [])

        const shipping = (idDeliveryman) => {

                GET_LOC({
                        table: 'buy_order',
                        params: `?id_deliveryman=${idDeliveryman}`
                }).then(response => {
                        setList(response)
                })
        }
        const downloadShipping = () => {

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                const { start_date, end_date, order_mode, order_by, search } = inititalFilters

                let params = `start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`

                if (saveDataFilter !== null) {
                        params = `start_date=${saveDataFilter.start_date}&end_date=${saveDataFilter.end_date}&order_mode=${saveDataFilter.order_mode}&order_by=${saveDataFilter.order_by}&search=${saveDataFilter.search}`
                }

                fetch(`${API}/shipping?action=list&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'control_envios.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });

        }

        return {
                list,
                shipping,
                getAllShipping,
                loading,
                filterShipping,
                downloadShipping,
                inititalFilters
        }
}



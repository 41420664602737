import React from 'react'
import styles from '../../styles/control.module.css'
import { Download } from '../../img/svg/download'

const Filters = ({ inititalFilters, findByFilters, downloadDrumControl }) => {

        const { start_date, end_date, order_mode, order_by, search } = inititalFilters

        return (
                <div className={styles.container_filters_control}>
                        <form onSubmit={findByFilters}>
                                <span>
                                        <label htmlFor="start_date">Fecha inicio</label>
                                        <input type="datetime-local" name="start_date" id="start_date" defaultValue={start_date} />
                                </span>
                                <span>
                                        <label htmlFor="end_date">Fecha termino</label>
                                        <input type="datetime-local" name="end_date" id="end_date" defaultValue={end_date} />
                                </span>
                                <span>
                                        <label htmlFor='order_mode'>Ordenar</label>
                                        <select id='order_mode' name='order_mode' defaultValue={order_mode}>
                                                <option value="ASC">ASC</option>
                                                <option value="DESC">DESC</option>
                                        </select>
                                </span>
                                <span>
                                        <label htmlFor='order_by'>Por</label>
                                        <select id='order_by' name='order_by' defaultValue={order_by}>
                                                <option value="id_drumcontrol">N°</option>
                                                <option value="name_deliveryman_drumcontrol">Vendedor</option>
                                        </select>
                                </span>
                                <span>
                                        <span>
                                                <input type='search' name='search' placeholder='buscar' defaultValue={search} />
                                        </span>
                                        <span>
                                                <button type='submit'>Aplicar</button>
                                        </span>
                                        <span>
                                                <button type='button' title='exportar a excel' onClick={() => downloadDrumControl()}>
                                                        <Download />
                                                </button>
                                        </span>
                                </span>
                        </form>
                </div>
        )
}

export default Filters
import React from 'react'
import ContainerPages from '../../components/containerPages'
import { BoxTitle } from '../../styles/myAsignments'
import TableAsignments from './components/tableAsignments'
import { useAssignment } from '../../hooks/useAssignment'

const MyAssignments = () => {

    const { myAsignments } = useAssignment()

    return (
        <ContainerPages>
            <BoxTitle>
                <h3>Mis asignaciones {`(${myAsignments?.total ?? 0})`}</h3>
            </BoxTitle>
            <TableAsignments
                myAsignments={myAsignments}
            />
        </ContainerPages>
    )
}

export default MyAssignments
import React, { useContext, useEffect, useState } from 'react'
import { GET_LOC } from '../services/get'
import LoginContext from '../contexts/login'
import { POST_LOC } from '../services/post'
import { API, dateTime, specialPermission } from '../services/globals'
import { DELETE_LOC } from '../services/delete'

export const useControl = () => {

        const { setToast, activeToast, authenticated, navigation, permissions } = useContext(LoginContext)
        const [loading, setLoading] = useState(false)
        const [getAllControl, setGetAllControl] = useState(null)
        const [getAtControl, setGetAtControl] = useState(null)
        const { currentTime, currentDay } = dateTime()
        const [formData, sertFormData] = useState(null)
        const inititalFilters = {
                start_date: currentDay,
                end_date: currentTime,
                order_mode: 'ASC',
                order_by: 'id_drumcontrol',
                search: ''
        }

        const getControl = (filters) => {
                setLoading(true)
                setGetAllControl(null)
                let { start_date, end_date, order_mode, order_by, search } = filters

                GET_LOC({
                        table: 'drumcontrols',
                        params: `?action=list&start_date=${start_date}&end_date=${end_date}&order_by=${order_by}&order_mode=${order_mode}&search=${search}`
                }).then(response => {

                        if (response.status === 200) {
                                setGetAllControl(response)
                        } else {
                                setGetAllControl(0)
                        }
                }).finally(() => {
                        setLoading(false)
                })
        }

        const getOneControl = (id) => {
                GET_LOC({
                        table: 'drumcontrols',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setGetAtControl(response.results[0])
                        } else {
                                setGetAtControl(0)
                        }
                })
        }

        const addExitControl = (e) => {
                e.preventDefault()
                setLoading(true)
                let data = new FormData(e.target)
                data.append('id_dispatcher_drumcontrol', authenticated.user[0].id_user)
                data.append('name_dispatcher_drumcontrol', authenticated.user[0].name_user)
                if (data.get('id_deliveryman_drumcontrol') == 0) {
                        setToast({
                                message: 'Seleccione un trabajador',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'drumcontrols',
                        data: data
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: "Registro creado exitosamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/control', { replace: true })
                        } else {

                                setToast({
                                        message: 'Ocurrio un error, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                }).finally(() => {
                        setLoading(false)
                })
        }

        const updateArrivalControl = (e) => {
                e.preventDefault()
                setLoading(true)
                const today = new Date()
                let now = today.toLocaleTimeString('es-CL');
                let data = new FormData(e.target)
                data.append('date_arrival_drumcontrol', now)
                POST_LOC({
                        table: 'drumcontrols?action=update',
                        data: data
                }).then(response => {

                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/control', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                }).finally(() => {
                        setLoading(false)
                })
        }

        const submitSalesControl = (e) => {
                e.preventDefault()
                setLoading(true)
                let data = new FormData(e.target)
                POST_LOC({
                        table: 'drumcontrols?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/control', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }

                }).then(() => {
                        setLoading(false)
                })
        }

        const deleteControl = (id, name) => {

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para usar esta función",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let isDelete = window.confirm(`¿Esta seguro que desea eliminar el registro N° ${id} de ${name}?`)
                if (isDelete) {
                        DELETE_LOC({
                                table: 'drumcontrols',
                                params: `?id=${id}`
                        }).then(response => {
                                setToast({
                                        message: 'Registro eliminado exitosamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                let newData = {}
                                newData.results = getAllControl.results.filter(item => parseInt(item.id_drumcontrol) !== parseInt(id))
                                newData.total = parseInt(getAllControl.total) - 1
                                setGetAllControl(newData)
                        })
                }
        }

        const findByFilters = (e) => {
                e.preventDefault();
                let data = Object.fromEntries(new FormData(e.target))
                sertFormData(data)
                getControl(data)
        }

        const downloadDrumControl = () => {

                if (getAllControl === 0) {
                        setToast({
                                message: "No hay datos para descargar",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let { start_date, end_date, order_mode, order_by, search } = inititalFilters

                let params = `start_date=${start_date}&end_date=${end_date}&order_by=${order_by}&order_mode=${order_mode}&search=${search}`
                if (formData !== null) {

                        params = `start_date=${formData.start_date}&end_date=${formData.end_date}&order_by=${formData.order_by}&order_mode=${formData.order_mode}&search=${formData.search}`
                }

                fetch(`${API}/drumcontrols?action=list&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'control.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });

        }

        const signSales = (item) => {

                const { id_deliveryman_drumcontrol, id_drumcontrol, name_deliveryman_drumcontrol } = item;
                let { id_user, name_user } = authenticated.user[0]

                if (id_deliveryman_drumcontrol !== id_user) {
                        setToast({
                                message: "No puedes firmar las ventas  que no te pertenece",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let isRegisterSigned = window.confirm(`¿Esta seguro que desea firmar las ventas de ${name_deliveryman_drumcontrol}?`)
                if (!isRegisterSigned) return;

                let data = new FormData()
                data.append('id_drumcontrol', id_drumcontrol)
                data.append('firm_sales', 1)
                POST_LOC({
                        table: 'drumcontrols?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/control', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }

                }).then(() => {
                        setLoading(false)
                })

        }
        const signRegister = (item) => {

                let { id_user, name_user } = authenticated.user[0]

                if (item.id_dispatcher_drumcontrol !== id_user) {
                        setToast({
                                message: "No puedes firmar un registro que no te pertenece",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let isRegisterSigned = window.confirm(`¿Esta seguro que desea firmar el registro N° ${item.id_drumcontrol} de ${item.name_deliveryman_drumcontrol}?`)
                if (!isRegisterSigned) return;

                let data = new FormData()
                data.append('id_drumcontrol', item.id_drumcontrol)
                data.append('firm_register_drumcontrol', 1)
                POST_LOC({
                        table: 'drumcontrols?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/control', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }

                }).then(() => {
                        setLoading(false)
                })

        }


        useEffect(() => {
                getControl(inititalFilters)
        }, [])

        return {
                getAllControl,
                addExitControl,
                getAtControl,
                getOneControl,
                navigation,
                updateArrivalControl,
                submitSalesControl,
                loading,
                deleteControl,
                permissions,
                findByFilters,
                inititalFilters,
                downloadDrumControl,
                signRegister,
                signSales
        }
}

import React from 'react'
import Search from '../../img/svg/search'
import { Download } from '../../img/svg/download'
import styles from '../../styles/clients.module.css'

const Searcher = ({ searching, download }) => {

        return (
                <div className={styles.container_filters}>
                        <form onSubmit={searching}>
                                <span>
                                        <label className={styles.switch}>
                                                <p>ASC</p>
                                                <input type="checkbox" name="order_by" />
                                                <span className={styles.slider}></span>
                                                <p>DESC</p>
                                        </label>
                                </span>
                                <select name="name_order">
                                        <option value="id_client">N°</option>
                                        <option value="name_client">Nombre</option>
                                        <option value="email_client">Email</option>
                                        <option value="rut_client">Rut</option>
                                        <option value="phone_client">Telefono</option>
                                </select>
                                <input type="search" name="search" placeholder="busca por nombre, correo, telefono o direccion" />
                                <button type="submit">
                                        <Search />
                                </button>
                        </form>
                        <button type="button" onClick={() => download()}>
                                <Download />
                        </button>
                </div>
        )
}

export default Searcher
import React from 'react'
import Delete from '../../img/svg/delete';
import Edit from '../../img/svg/edit';
import Review from '../../img/svg/review';
import styles from '../../styles/toListOrder.module.css'
import { chileanCurrency } from '../../services/globals';

const ToListOrder = ({ getOrders, permissions, deleteOrder }) => {

        return (
                <div className={styles.container_list_order}>
                        <table>
                                <thead>
                                        <tr>
                                                <th>N° Orden</th>
                                                <th>Nombre</th>
                                                <th>Email</th>
                                                <th>Metodo de envio</th>
                                                <th>Cantidad productos</th>
                                                <th>Total</th>
                                                <th>Estado del pago</th>
                                                <th>Estado de envio</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {getOrders === null ? (
                                                <tr>
                                                        <td>cargando...</td>
                                                </tr>
                                        ) : getOrders === 0 ? (
                                                <tr>
                                                        <td>Sin pedidos</td>
                                                </tr>
                                        ) : (
                                                getOrders.map((order, index) => (
                                                        <tr key={index}>
                                                                <td>{order.id_order}</td>
                                                                <td>{order.name_client}</td>
                                                                <td>{order.email_client}</td>
                                                                <td>{order.manage_shipping}</td>
                                                                <td>{order.quantity_total}</td>
                                                                <td>${chileanCurrency(order.subtotal_sum)}</td>
                                                                <td>{order.name_payment}</td>
                                                                <td>
                                                                        {order.delivered == 0 && order.in_transit == 0 && order.quantity_to_sent == 0 && (
                                                                                <p style={{ background: 'orangered' }}>Sin Acciones</p>
                                                                        )}

                                                                        {order.quantity_total != order.delivered ? (
                                                                                <>
                                                                                        {order.quantity_to_sent > 0 && (
                                                                                                <p style={{ background: 'red' }}>{order.quantity_to_sent} Pendientes de envio</p>
                                                                                        )}
                                                                                        {order.in_transit > 0 && (
                                                                                                <p style={{ background: 'orange' }}>{order.in_transit} En transito</p>
                                                                                        )}
                                                                                        {order.delivered > 0 && (
                                                                                                <p style={{ background: 'green' }}>{order.delivered} Entregado</p>
                                                                                        )}
                                                                                </>
                                                                        ) : (
                                                                                <p style={{ background: 'green', color: '#FFFFFF' }}>Todos Entregados</p>
                                                                        )}
                                                                </td>
                                                                <td>
                                                                        {order.quantity_to_sent > 0 ? (
                                                                                <button style={{ background: 'orange' }} type='button' onClick={() => permissions(`/orders/update/${order.id_order}`, 'submenu')} title='Asignar pedido'>
                                                                                        <Edit />
                                                                                </button>
                                                                        ) : (
                                                                                <button style={{ background: 'green' }} type='button' onClick={() => permissions(`/orders/update/${order.id_order}`, 'submenu')} title='ver detalles'>
                                                                                        <Review />
                                                                                </button>
                                                                        )}
                                                                        <button style={{ background: 'red' }} type='button' title='eliminar pedido' onClick={() => deleteOrder(order.id_order,order.payment_accepted,order.in_transit)}>
                                                                                <Delete />
                                                                        </button>
                                                                </td>
                                                        </tr>
                                                ))
                                        )}
                                </tbody>
                        </table>
                </div >
        )
}

export default ToListOrder
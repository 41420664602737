import React, { useContext } from 'react'
import styled from 'styled-components';
import LoginContext from '../contexts/login';

const IconMenu = () => {

  const {activeMenu, setActiveMenu} = useContext(LoginContext)
  return (
    <Div  activeMenu={activeMenu} onClick={()=> setActiveMenu(!activeMenu)}></Div>
  )
}

const Div = styled.div`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  transition: transform .3s ease;
  border-radius: 50%;
  cursor: pointer;
  transform: ${({activeMenu})=> activeMenu ? 'rotate(0)' : 'rotate(180deg)'};


  &::before,
  &::after{
    position: absolute;
    content: '';
    height: 15px;
    width: 3px;
    background-color:var(--primaryColor);
  }
  &::before{
    transform: rotate(45deg);
    top: 8px;
  }
  
  &::after{
    transform: rotate(-45deg);
    bottom:8px;
  }


`;

export default IconMenu
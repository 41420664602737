import React from 'react'
import { Section } from '../../../styles/reports'
import { useFetch } from '../../../hooks/useFetch';
import { useReports } from '../../../hooks/useReports';

const BoxFilter = () => {

        const { filterByDate, setFilterByDate } = useReports()
  
        return (
                <Section>
                        <form>
                                <div>
                                        <p>Periodo</p>
                                        <span>
                                                <label>Fecha inicio</label>
                                                <input type="date" defaultValue={filterByDate.start} onChange={(e) => setFilterByDate({ ...filterByDate, start: e.target.value })} />
                                        </span>
                                        <span>
                                                <label>Fecha Termino</label>
                                                <input type="date" defaultValue={filterByDate.end} onChange={(e) => setFilterByDate({...filterByDate, end :e.target.value})} />
                                        </span>
                                </div>
                                <div>
                                        <p>Selección</p>
                                        <span>
                                                <select>
                                                        <option value="all">Todos</option>
                                                </select>
                                        </span>
                                        <span>
                                        </span>
                                </div>
                                <div>
                                        <button type="submit">Buscar</button>
                                </div>
                        </form>
                </Section>
        )
}

export default BoxFilter
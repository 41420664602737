import { createContext, useContext, useEffect, useState } from "react";
import { DELETE } from "../services/delete";
import { GET, GET_LOC, RANGE } from "../services/get";
import { currentTime, randomNumer } from "../services/globals";
import { POST, POST_LOC } from "../services/post";
import { PUT, PUT_LOC } from "../services/put";
import LoginContext from "./login";

const OrderContext = createContext()

const initialFormOrders = {
        number_order: '',
        id_client_order: '',
        rut_client_order: '',
        email_client_order: '',
        phone_client_order: '',
        name_client_order: '',
        comunne_client_order: '',
        region_client_order: '',
        commune_client: '',
        direction_client_order: '',
        type_client_order: '',
        sales_type_client_order: '',
        id_product_order: '',
        name_product_order: '',
        description_product_order: '',
        amount_product_order: 1,
        total_price_product_order: '',
        id_deliveryman_order: '',
        name_deliveryman_order: '',
        email_deliveryman_order: '',
        additional_comments_order: '',
        unit_price_order: '',
        discount_order: 0,
}

const initialFormAssignments = {
        total_assignment: '',
        type_assignment: 'Llegada',
        additional_comments_assignment: ''

}

const OrderProvider = ({ children }) => {

        const { setToast, activeToast, authenticated, navigation, logout } = useContext(LoginContext)
        const [products, setProducts] = useState(null)
        const [formOrder, setFormOrder] = useState(initialFormOrders)
        const [active, setActive] = useState(false)
        const [loading, setLoading] = useState(false)
        const [loading2, setLoading2] = useState(false)
        const [getOrders, setGetOrders] = useState(null)
        const [totalOrders, setTotalOrders] = useState(null)
        const [getClient, setGetClient] = useState(null)
        const [getDeliveryMan, setGetDeliveryMan] = useState(null)

        const [dateInitial, setDateInitial] = useState(currentTime)
        const [dateFinal, setDateFinal] = useState(currentTime)
        const [saleTypes, setSalesTypes] = useState(null)
        const [phoneClient, setPhoneClient] = useState('')
        const [dataProduct, setDataProduct] = useState('')
        const [deliveryMan, setDeliveryMan] = useState('')
        const [activeRange, setActiveRange] = useState(false)
        const [activeOrder, setActiveOrder] = useState(false);
        const [formAssignment, setFormAssignment] = useState(initialFormAssignments)
        const [getAssignment, setGetAssignment] = useState(null)
        const [activeRange2, setActiveRange2] = useState(false)
        const [activeOrder2, setActiveOrder2] = useState(false);
        const [dateInitial2, setDateInitial2] = useState(currentTime)
        const [dateFinal2, setDateFinal2] = useState(currentTime)
        const [stateOrders, setStateOrders] = useState('all')
        const [paymentStatus, setPaymentStatus] = useState('all')
        const [totalSend, setTotalSend] = useState(0)
        const [detailsOrder, setDetailsOrder] = useState(null)

        const getProduct = () => {
                GET({
                        table: 'products',
                })
                        .then(response => {
                                if (response.status === 200) {
                                        setProducts(response.results)
                                } else {
                                        setProducts(0)
                                }
                        })
        }

        const getAllDeliveryMan = () => {

                GET_LOC({
                        table: 'users',
                        params: '?user_action=[13]'
                }).then(response => {
                        if (response.status === 200) {
                                setGetDeliveryMan(response.results)
                        } else {
                                setGetDeliveryMan(0)
                        }

                })

        }

        useEffect(() => {
                getAllDeliveryMan()
                getProduct()
        }, [])

        useEffect(() => {
                GET({
                        table: 'clients',
                }).then(response => {
                        if (response.status === 200) {
                                setGetClient(response.results)
                        }
                })
        }, [])

        const orders = () => {
                GET_LOC({
                        table: 'buy_order',
                        params: `?all=true&status=${paymentStatus}&shipping=${stateOrders}`
                }).then(response => {
                        if (response.status === 200) {
                                setTotalOrders(response.total)
                                setGetOrders(response.results)
                        }
                        else {
                                setTotalOrders(response.total)
                                setGetOrders(0)
                        }
                })
        }

        useEffect(() => {
                orders()
        }, [stateOrders, paymentStatus])


        const getDetailsOrder = (id) => {
                GET_LOC({
                        table: 'buy_order',
                        params: `?details=true&id_order=${id}`,
                }).then(response => {
                        if (response.status === 200) {


                                setTotalSend(response.results.products.reduce((acum, product) => acum + parseInt(product.quantity_to_sent), 0))
                                setDetailsOrder(response.results)
                        } else {
                                setDetailsOrder(0)
                                setTotalSend(0)

                        }
                        return;
                })
        }


        const handleAssignDeliveryMan = (e) => {

                e.preventDefault()

                let tmp = [1, 2]
                if (tmp.includes(parseInt(detailsOrder.order[0].payment_accepted))) {
                        setToast({
                                message: 'El pedido aun no esta pagado',
                                error: true,
                                time: 5000,
                        })
                        activeToast()
                        return
                }

                /*
                if (totalSend === 0) {
                        setToast({
                                message: 'No hay pedidos para asignar',
                                error: true,
                                time: 5000,
                        })
                        activeToast()
                        return
                };
                */
                let formData = new FormData(e.target);

                if (formData.get('id_deliveryman') == '0') {
                        setToast({
                                message: 'Debes seleccionar un repartidor',
                                error: true,
                                time: 2000,
                        })
                        activeToast()
                        return
                }
                POST_LOC({
                        table: 'shipping',
                        data: formData
                }).then(response => {

                        setToast({
                                message: "Asignación realizada correctamente",
                                error: false,
                                time: 3000
                        })
                        getDetailsOrder(formData.get('id_buy_order'))
                        activeToast()
                        return;
                })

        }


        const changeStatusPayment = (e) => {
                e.preventDefault()
                let formData = Object.fromEntries(new FormData(e.target))

                let update = new URLSearchParams()
                update.append('payment_accepted', formData.payment_accepted)
                update.append('id', formData.id_order)

                PUT_LOC({
                        table: 'buy_order',
                        data: update
                }).then(response => {

                        getDetailsOrder(formData.id_order)
                        setToast({
                                message: response.comment,
                                error: false,
                                time: 5000,
                        })
                        activeToast()
                })
        }

        const handleChangeAssignment = (e) => {
                setFormAssignment({
                        ...formAssignment,
                        [e.target.name]: e.target.value
                })
        }

        const TotalProductAssignment = () => {
                let total = (dataProduct.price_product * formAssignment.amount_assignment);
                return total;
        }

        const bntCancelAssignment = (activeModal, setActiveModal) => {
                setFormAssignment(initialFormAssignments)
                setDeliveryMan('')
                setDataProduct('')

                setActiveModal(!activeModal)
        }


        const deleteAssignment = (item) => {

                let isDelete = window.confirm(
                        `¿Estás seguro que quires eliminar la ${item.type_assignment} de ${item.amount_assignment} ${item.product_assignment} de ${item.email_user_assignment}?. 
            Recuerda que si eliminas este registro tambien de devolvera el stock del producto asociado`
                );

                const deleteAss = () => {
                        DELETE({
                                table: 'assignments',
                                id: item.id_assignment,
                                nameId: 'id_assignment',
                                tableToken: 'users',
                                suffix: 'user',
                                token: authenticated.token_user
                        }).then(response => {
                                if (response.status === 200) {
                                        let newData = getAssignment.filter((el) => el.id_assignment !== item.id_assignment)
                                        setGetAssignment(newData)
                                        setToast({
                                                message: "Registro eliminado correctamente",
                                                error: false,
                                                time: 4000
                                        })
                                        activeToast()
                                        return;
                                } else if (response.status === 400 || response.status === 303) {
                                        setToast({
                                                message: "token de seguridad expirado, porfavor inicie sesión nuevamente",
                                                error: true,
                                                time: 4000
                                        })
                                        activeToast()
                                        logout()
                                        return navigation("/login", { replace: true })
                                }
                        })
                }

                if (isDelete) {
                        GET({ table: 'products', linkTo: 'linkTo=id_product', search: `equalTo=${item.id_product_assignment}` })
                                .then(response => {
                                        if (response.status === 200) {
                                                let product = response.results[0]
                                                if (item.type_assignment === 'Salida') {
                                                        let updateStock = new URLSearchParams();
                                                        updateStock.append("stock_product", parseInt(product.stock_product) + parseInt(item.amount_assignment));
                                                        PUT({ table: 'products', id: product.id_product, nameId: 'id_product', data: updateStock })
                                                        deleteAss()
                                                        return
                                                } else {

                                                        let updateStock = new URLSearchParams();
                                                        updateStock.append("stock_product", parseInt(product.stock_product) + parseInt(item.amount_assignment));
                                                        PUT({ table: 'products', id: product.id_product, nameId: 'id_product', data: updateStock })
                                                        deleteAss()
                                                        return;
                                                }


                                        } else {
                                                deleteAss()
                                        }
                                })


                }
        }

        useEffect(() => {

                if (activeRange2) {
                        RANGE({
                                table: 'assignments',
                                linkTo: 'linkTo=date_created_assignment',
                                rangeInitial: dateInitial2,
                                rangeFinal: dateFinal2
                        }).then(response => {
                                if (response.status === 200) {
                                        let rol = authenticated !== null && authenticated.rol_user
                                        if (rol === 'Root' || rol === 'Administrador' || rol === 'Control de Bodega') {
                                                setGetAssignment(response.results)
                                        } else {
                                                setGetAssignment(response.results.filter((el => el.type_user_assignment === 'Repartidor')))
                                        }

                                } else {
                                        setGetAssignment(0)

                                }
                        })
                } else {
                        GET({
                                table: 'assignments',
                                linkTo: 'linkTo=date_created_assignment',
                                search: `search=${dateFinal2}`
                        })
                                .then(response => {
                                        if (response.status === 200) {
                                                let rol = authenticated !== null && authenticated.rol_user
                                                if (rol === 'Root' || rol === 'Administrador' || rol === 'Control de Bodega') {
                                                        setGetAssignment(response.results)
                                                } else {
                                                        setGetAssignment(response.results.filter((el => el.type_user_assignment === 'Repartidor')))
                                                }
                                        }
                                })
                }


        }, [loading2, dateInitial2, dateFinal2, activeRange2, authenticated === null])

        const data = {
                initialFormAssignments,
                TotalProductAssignment,
                dataProduct,
                setLoading,
                setLoading2,
                loading2,
                setGetAssignment,
                deleteAssignment,
                bntCancelAssignment,
                handleChangeAssignment,
                formAssignment,
                setFormAssignment,
                products,
                formOrder,
                setFormOrder,
                totalOrders,
                setActive,
                active,
                formOrder,
                getClient,
                setSalesTypes,
                saleTypes,
                setPhoneClient,
                phoneClient,
                setDataProduct,
                dataProduct,
                setDeliveryMan,
                deliveryMan,
                getOrders,
                setDateInitial,
                setDateFinal,
                setDateInitial2,
                setDateFinal2,
                dateInitial,
                dateFinal,
                dateInitial2,
                dateFinal2,
                activeRange,
                setActiveRange,
                activeRange2,
                setActiveRange2,
                activeOrder2,
                activeOrder,
                setActiveOrder,
                getAssignment,
                stateOrders,
                setStateOrders,
                paymentStatus,
                setPaymentStatus,
                getDeliveryMan,
                handleAssignDeliveryMan,
                totalSend,
                setTotalSend,
                changeStatusPayment,
                getDetailsOrder,
                detailsOrder

        }

        return <OrderContext.Provider value={data}>{children}</OrderContext.Provider>
}

export { OrderProvider }
export default OrderContext;
import { createGlobalStyle } from "styled-components";
import "react-widgets/styles.css";

export const GlobalStyles = createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif;
}

:root{
    --primaryColor: #16163F; //azul oscuro
    --textColor:#171717; // negro
    --cardColor:#FFEAC8; //beige
    --secundaryColor:#00845E; //verde oscuro
    --primaryBackground :#F3F3F3; //plomo claro
    --secundaryBackground: #FFFFFF; // blanco
    --heltpColor :#F8C91C; //amarillo
    --borderColor :#BBBBBB; //plomo
}
tr{
    font-size: .9em;
}




`;
import React from 'react'
import styles from '../../styles/shipping.module.css'

const DeliveryStatus = ({status}) => {

        const { quantity_to_sent, in_transit, delivered, quantity_delivery_error } = status;
        return (
                <>
                        {parseInt(quantity_to_sent) > 0 && <p style={{background:'orangered'}} className={styles.delivery_status}>{quantity_to_sent} para enviar</p>}
                        {parseInt(in_transit) > 0 && <p style={{background:'orange'}} className={styles.delivery_status}>{in_transit} en transito</p>}
                        {parseInt(delivered) > 0 && <p style={{background:'green'}} className={styles.delivery_status}>{delivered} entregados</p>}
                        {parseInt(quantity_delivery_error) > 0 && <p style={{background:'red'}}className={styles.delivery_status}>{quantity_delivery_error} con error</p>}
                </>
        )
}

export default DeliveryStatus
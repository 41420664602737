import React from 'react'
import LoginContext from '../contexts/login'
import { useContext } from 'react'
import { POST_LOC } from '../services/post'
import { GET_LOC } from '../services/get'
import { useState } from 'react'
import { useEffect } from 'react'
import { API, dateTime, specialPermission } from '../services/globals'

export const useAssignment = () => {

        const { setToast, activeToast, permissions, authenticated, navigation } = useContext(LoginContext)
        const [allAssignments, setAllAssignments] = useState(null)
        const { currentTime, currentDay } = dateTime()
        const [myAsignments, setMyAsignments] = useState(null)
        const [saveDataFilter, setSaveDataFilter] = useState(null)
        const inititalFilters = {
                start_date: currentDay,
                end_date: currentTime,
                order_mode: 'ASC',
                order_by: 'id',
                search: ''
        }


        const getAllAssignments = (filters) => {

                const { start_date, end_date, order_mode, order_by, search } = filters

                GET_LOC({
                        table: 'daily_assignments',
                        params: `?action=list&start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`
                }).then(response => {
                        if (response.status === 200) {
                                setAllAssignments(response)
                        } else {
                                setAllAssignments(0)
                        }
                })
        }

        const getMyAssignments = () => {
                GET_LOC({
                        table: 'daily_assignments',
                        params: `?id=${authenticated?.user[0].id_user}`
                }).then(response => {
                        if (response.status === 200) {
                                setMyAsignments(response)
                        } else {
                                setMyAsignments(0)
                        }
                })
        }

        const filterAssignments = (e) => {
                e.preventDefault();

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                let data = Object.fromEntries(new FormData(e.target))
                data.order_mode = data.order_mode === 'on' ? 'DESC' : 'ASC'
                setSaveDataFilter(data)
                getAllAssignments(data)
        }

        const addAssignment = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                data.append('dispatcher_id', authenticated.user[0].id_user)
                data.append('dispatcher_name', authenticated.user[0].name_user)
                if (data.get('id_assigned_user') === '0' || data.get('id_product') === '') {
                        setToast({
                                message: "Debes seleccionar un trabajador y un producto",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                POST_LOC({
                        table: 'daily_assignments',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: "Asignación agregada correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/assignments', { replace: true })
                        } else {
                                setToast({
                                        message: "Error al agregar la asignación,intente nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })
                return;
        }

        const downloadAssignments = () => {

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                const { start_date, end_date, order_mode, order_by, search } = inititalFilters

                let params = `start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`

                if (saveDataFilter !== null) {
                        params = `start_date=${saveDataFilter.start_date}&end_date=${saveDataFilter.end_date}&order_mode=${saveDataFilter.order_mode}&order_by=${saveDataFilter.order_by}&search=${saveDataFilter.search}`
                }

                fetch(`${API}/daily_assignments?action=list&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'asignaciones_diarias.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });

        }

        useEffect(() => {
                getAllAssignments(inititalFilters)
                getMyAssignments()
        }, [])

        useEffect(() => {
                getMyAssignments()
        }, [myAsignments === null])

        return {
                permissions,
                addAssignment,
                allAssignments,
                inititalFilters,
                filterAssignments,
                downloadAssignments,
                myAsignments
        }
}

import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_LOC } from "../services/get";
import { POST_LOC } from "../services/post";

const LoginContext = createContext();

const LoginProvider = ({ children }) => {

        const navigation = useNavigate()
        const { pathname } = useLocation()
        const [activeMenu, setActiveMenu] = useState(false)
        const [authenticated, setAuthenticated] = useState(null)
        const [loader, setLoader] = useState(false)
        const [state, setState] = useState(false)
        const [menu, setMenu] = useState(null)
        const [submenu, setSubmenu] = useState(null)
        const [toast, setToast] = useState({
                message: 'Sin datos',
                error: false,
                time: 5000,
        })

        const activeToast = () => {
                setState(true)
        }
        const closeToast = () => {
                setState(false)
        }


        useEffect(() => {
                const loginActive = JSON.parse(window.localStorage.getItem('credentials'));

                if (loginActive) {
                        let userTimeExp = parseInt(loginActive.user[0].token_exp_user)
                        let now = Math.floor(Date.now() / 1000);

                        if (userTimeExp < now) {
                                window.localStorage.removeItem('credentials')
                                setToast({
                                        message: "Su sesión ha expirado, inicie nuevamente",
                                        error: true,
                                        time: 2000
                                })
                                activeToast()
                                return navigation("/login", { replace: true })
                        }

                        setAuthenticated(loginActive)
                        return navigation(pathname, { replace: true })
                } else {
                        window.localStorage.removeItem('credentials')
                        return navigation("/login", { replace: true })
                }
        }, [])

        const permissions = (path, type) => {
                if (authenticated !== null) {
                        if (type === "menu") {
                                let menuPermissions = authenticated.menu.filter((route) => route.slug === path);
                                if (menuPermissions.length === 0) {
                                        setToast({
                                                message: "No tienes permisos para acceder a esta funcionalidad",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return;
                                } else {
                                        return navigation(path, { replace: true })

                                }
                        } else if (type === "submenu") {

                                //const lastSlashIndex = path.lastIndexOf("/");
                                //const result = path.substring(0, lastSlashIndex); // extrae la subcadena hasta el último slash
                                const result = path.replace(/\/\d+$/, "");
                                let submenuPermissions = authenticated.submenu.filter((route) => route.path === result);
                                if (submenuPermissions.length === 0) {
                                        setToast({
                                                message: "No tienes permisos para acceder a esta funcionalidad",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return;
                                } else {
                                        return navigation(path, { replace: true })
                                }

                        } else {
                                return;
                        }

                }
        };

        const downloadPermission = () => {

        }


        const getAllMenu = () => {
                GET_LOC({
                        table: 'menu',
                        params: `?list=menu`
                }).then(response => {

                        setMenu(response)
                })
        }
        const getAllSubMenu = () => {
                GET_LOC({
                        table: 'menu',
                        params: `?list=submenu`
                }).then(response => {
                        setSubmenu(response)
                })
        }

        useEffect(() => {
                getAllMenu()
                getAllSubMenu()
        }, [])

        const handleAccess = (e) => {
                e.preventDefault();
                setLoader(true)
                let data = new FormData(e.target);

                POST_LOC({
                        table: "users?login=true",
                        data: data
                }).then(response => {
                        setLoader(false)

                        if (response.status === 200) {
                                if (response.results.user[0].state_user == 0) {
                                        setToast({
                                                message: "El usuario se encuentra inactivo, contacte al administrador",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return;
                                }

                                e.target.reset()
                                window.localStorage.setItem('credentials', JSON.stringify(response.results))
                                setAuthenticated(response.results)
                                return navigation("/", { replace: true })
                        } else {
                                setToast({
                                        message: "Credenciales de acceso incorrectas",
                                        error: true,
                                        time: 2000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        useEffect(() => {
                const interval = setInterval(closeToast, toast.time)
                return () => {
                        clearInterval(interval)
                }
        }, [activeToast]);

        const logout = () => {
                setAuthenticated(null);
                window.localStorage.removeItem('credentials')
                navigation("/login", { replace: true });
        }

        const data = {
                handleAccess,
                authenticated,
                activeMenu,
                setActiveMenu,
                navigation,
                toast,
                setToast,
                activeToast,
                closeToast,
                state,
                loader,
                logout,
                menu,
                submenu,
                permissions
        }

        return <LoginContext.Provider value={data}>{children}</LoginContext.Provider>
}

export { LoginProvider }
export default LoginContext;
import React from 'react'
import styles from '../../styles/production.module.css'
import Delete from '../../img/svg/delete'
import Edit from '../../img/svg/edit'

const ListProduction = ({ production, deleteProduction, permissions }) => {

        return (
                <div className={styles.container_table}>
                        <table width='100%'>
                                <thead>
                                        <tr>
                                                <th rowSpan='2'>N°</th>
                                                <th rowSpan='2'>Inicio turno</th>
                                                <th rowSpan='2'>Usuarios</th>
                                                <th colSpan='3'>Producidos</th>
                                                <th colSpan='3'>Malos</th>
                                                <th rowSpan='2'>Hielos</th>
                                                <th rowSpan='2'>Termino turno</th>
                                                <th rowSpan='2'>Fecha de creación</th>
                                                <th rowSpan='2'>Acciones</th>
                                        </tr>
                                        <tr>
                                                <th>20LTS</th>
                                                <th>10LTS</th>
                                                <th>5LTS</th>
                                                <th>20LTS</th>
                                                <th>10LTS</th>
                                                <th>5LTS</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {production === 0 ? (
                                                <tr>
                                                        <td colSpan='2'>No hay datos</td>
                                                </tr>
                                        ) : (
                                                production === null ? (
                                                        <tr>
                                                                <td colSpan='2'>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        production.map((item, index) => (
                                                                <tr key={item.id}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.start_of_shift}</td>
                                                                        <td style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                                                {item.users.map((user, index) => (
                                                                                        <span key={index}>{user.name_user}</span>
                                                                                ))}
                                                                        </td>
                                                                        <td>{item.created_20lt}</td>
                                                                        <td>{item.created_10lt}</td>
                                                                        <td>{item.created_5lt}</td>
                                                                        <td>{item.filling_20lt}</td>
                                                                        <td>{item.filling_10lt}</td>
                                                                        <td>{item.filling_5lt}</td>
                                                                        <td>{item.ice}</td>
                                                                        <td>{item.end_of_shift}</td>
                                                                        <td>{item.creation_date.split(' ')[0]}</td>
                                                                        <td>
                                                                                <button type='button' onClick={() => permissions(`/production/update/${item.id}`, 'submenu')}>
                                                                                        <Edit />
                                                                                </button>
                                                                                <button type='button' onClick={() => deleteProduction(item.id)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </td>
                                                                </tr>
                                                        ))
                                                ))}
                                </tbody>
                                <tfoot>
                                        <tr>
                                                <td colSpan='2'>Total</td>
                                                {production === null ? (
                                                        <td colSpan='10'>Cargando...</td>
                                                ) : (
                                                        production === 0 ? (
                                                                <td colSpan='10'>No hay datos</td>
                                                        ) : (
                                                                <>
                                                                        <td rowSpan={2}></td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.created_20lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.created_10lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.created_5lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.filling_20lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.filling_10lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => a + parseInt(b.filling_5lt), 0)}</td>
                                                                        <td>{production === null ? 0 : production.reduce((a, b) => (a === "" || a === null ? 0 : a)  + parseInt(b.ice === "" || b.ice === null ? 0 : b.ice), 0)}</td>
                                                                        <td></td>
                                                                </>
                                                        )
                                                )}
                                        </tr>
                                </tfoot>
                        </table>
                </div>
        )
}

export default ListProduction
import { GlobalStyles } from './styles/globalStyles';
import { Routes, Route, Outlet } from 'react-router-dom'
import Menu from './components/menu';
import Dashboard from './pages/dashboard/dashboard';
import Clients from './pages/clients/clients';
import Products from './pages/products/products';
import { LoginProvider } from './contexts/login';
import Login from './pages/login/login';
import Toast from './components/toast';
import NotFound from './pages/404/notFound';
import Orders from './pages/orders';
import Deliveries from './pages/deliveries/deliveries';
import Workers from './pages/administrator/workers';
import { OrderProvider } from './contexts/orders';
import Reports from './pages/reports/reports';
import Control from './pages/control/drumControl';
import Assignments from './pages/assignments';
import DailyManagement from './pages/dailyManagement/dailyManagement';
import Surrenders from './pages/surrenders/surrenders';
import MyAssignments from './pages/myassignments/myAssignments';
import Coupons from './pages/coupons/coupons';
import OrderDetails from './pages/orderDetails';
import DeliveryId from './pages/deliveryId';
import AddOrder from './pages/addOrder';
import Add from './components/products/add';
import Update from './components/products/update';
import Promotions from './pages/promotions';
import AddClient from './pages/addClient';
import UpdateClient from './components/clients/updateClient';
import UpdateWorker from './components/workers/updateWorker';
import AddWorker from './components/workers/addWorker';
import AddPromotion from './components/promotions/addPromotion';
import MenuManagement from './pages/menuManagement';
import Production from './pages/production';
import AddProduction from './components/production/addProduction';
import AddAssignment from './components/assignments/add';
import Caps from './pages/caps';
import AddExitControl from './components/control/addExitControl';
import AddArrivalControl from './components/control/addArrivalControl';
import SalesControl from './components/control/salesControl';
import PackageStore from './pages/packageStore';
import AddPackageStore from './components/packageStore/addPackageStore';
import AddPackageStoreArrival from './components/packageStore/addPackageStoreArrival';
import UpdateProduction from './components/production/updateProduction';
import Shipping from './pages/shipping';
import AddCaps from './components/caps/addCaps';
import UpdateCap from './components/caps/updateCaps';
import AddCash from './components/surrenders/addCash';
import CashControl from './components/surrenders/cashControl';
import UpdateCashControl from './components/surrenders/updateCashControl';
import ListCashControl from './components/surrenders/listCashControl';
import ListQuadrature from './pages/surrenders/listQuadrature';
import AddDailyManager from './components/dailyManager/addDailyManager';
import UpdateAccExpences from './components/dailyManager/updateAccExpences';
import ParamsAndMaintenance from './pages/paramsAndMaintenance';
import AddParams from './components/params/addParams';
import Maintenance from './pages/maintenance';
import AddMaintenance from './components/maintenance/addMaintenance';
import UpdateParam from './components/params/updateParam';
import UpdateMaintenance from './components/maintenance/updateMaintenance';

function App() {

	return (
		<>
			<GlobalStyles />
			<LoginProvider>
				<OrderProvider>
					<Menu />
					<Routes>
						<Route path='/' element={<Dashboard />} />
						<Route path='/clients' element={<Clients />} />
						<Route path='/clients/add' element={<AddClient />} />
						<Route path='/clients/update/:id' element={<UpdateClient />} />
						<Route path='/products' element={<Products />} />
						<Route path='/products/add' element={<Add />} />
						<Route path='/products/update/:id' element={<Update />} />
						<Route path='/control' element={<Control />} />
						<Route path='/control/add-exit' element={<AddExitControl />} />
						<Route path='/control/arrival/:id' element={<AddArrivalControl />} />
						<Route path='/control/sales/:id' element={<SalesControl />} />
						<Route path='/assignments' element={<Assignments />} />
						<Route path='/assignments/add' element={<AddAssignment />} />
						<Route path='/orders' element={<Orders />} />
						<Route path='/orders/update/:id' element={<OrderDetails />} />
						<Route path='/orders/add' element={<AddOrder />} />
						<Route path='/deliveries' element={<Deliveries />} />
						<Route path='/deliveries/update/:id' element={<DeliveryId />} />
						<Route path='/workers' element={<Workers />} />
						<Route path='/workers/add' element={<AddWorker />} />
						<Route path='/workers/update/:id' element={<UpdateWorker />} />
						<Route path='/reports' element={<Reports />} />
						<Route path='/daily-management' element={<DailyManagement />} />
						<Route path='/daily-management/add' element={<AddDailyManager />} />
						<Route path='/daily-management/update/:id' element={<UpdateAccExpences />} />
						<Route path='/surrenders' element={<Surrenders />}>
							<Route path='/surrenders/list-cash-control' element={<ListCashControl />} />
							<Route path='/surrenders/list-quadrature' element={<ListQuadrature />} />
						</Route>
						<Route path='/maintenance' element={<ParamsAndMaintenance />} />
						<Route path='/maintenance/maintenance' element={<Maintenance />} />
						<Route path='/maintenance/add-param' element={<AddParams />} />
						<Route path='/maintenance/update-param/:id' element={<UpdateParam />} />
						<Route path='/maintenance/add-maintenance' element={<AddMaintenance />} />
						<Route path='/maintenance/update-maintenance/:id' element={<UpdateMaintenance />} />
						<Route path='/surrenders/add' element={<AddCash />} />
						<Route path='/surrenders/add-cash-control' element={<CashControl />} />
						<Route path='/surrenders/update-cash-control/:id' element={<UpdateCashControl />} />
						<Route path='/my-assignments' element={<MyAssignments />} />
						<Route path='/coupons' element={<Coupons />} />
						<Route path='/promotions' element={<Promotions />} />
						<Route path='/promotions/add' element={<AddPromotion />} />
						<Route path='/login' element={<Login />} />
						<Route path='/menu' element={<MenuManagement />} />
						<Route path='/production' element={<Production />} />
						<Route path='/production/add' element={<AddProduction />} />
						<Route path='/production/update/:id' element={< UpdateProduction />} />
						<Route path='/caps' element={<Caps />} />
						<Route path='/caps/add' element={<AddCaps />} />
						<Route path='/caps/update/:id' element={<UpdateCap />} />
						<Route path='/package-store' element={<PackageStore />} />
						<Route path='/package-store/add' element={<AddPackageStore />} />
						<Route path='/package-store/add-arrival/:id' element={<AddPackageStoreArrival />} />
						<Route path='/shipping' element={<Shipping />} />
						<Route path='/404' element={<NotFound />} />
					</Routes>
					<Toast />
				</OrderProvider>
			</LoginProvider>
		</>
	);
}

export default App;

import { API, BASE_API } from "./globals";

export const POST = ({
        table = null,
        except = null,
        data = null
}) =>
        fetch(`${BASE_API}${table}?token=no&except=${except}`, {
                method: 'POST',
                body: data,
                redirect: 'follow'
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));

export const login = (formdata) =>
        fetch(`${BASE_API}users?login=true&suffix=user`, {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));

export const register = (data) =>
        fetch(`${BASE_API}users?register=true&suffix=user&token=no&except=id_user`, {
                method: 'POST',
                body: data,
                redirect: 'follow'
        })
                .then(response => response.json())
                .catch(error => console.log('error', error));

export const POST_LOC = ({
        table = null,
        data = null
}) =>
        fetch(`${API}/${table}`, {

                method: 'POST',
                body: data,
                redirect: 'follow'
        }).then(response => response.json())
                .catch(error => console.log('error', error))

import React from 'react'
import styles from '../../styles/caps.module.css'
import Search from '../../img/svg/search'
import { Download } from '../../img/svg/download'

const Filters = ({ inititalFilters, filterCaps }) => {

        const { start_date, end_date, order_mode, order_by, search } = inititalFilters

        return (
                <div className={styles.container_filters}>
                        <form onSubmit={filterCaps}>
                                <span>
                                        <label>Desde</label>
                                        <input type="datetime-local" name="start_date" defaultValue={start_date} />
                                </span>
                                <span>
                                        <label>Hasta</label>
                                        <input type="datetime-local" name="end_date" defaultValue={end_date} />
                                </span>
                                <span>
                                        <label className={styles.switch}>
                                                <p>ASC</p>
                                                <input type="checkbox" name="order_mode" />
                                                <span className={styles.slider}></span>
                                                <p>DESC</p>
                                        </label>
                                </span>
                                <span>
                                        <select name="order_by" defaultValue={order_by}>
                                                <option value="id">N°</option>
                                                <option value="name_product">Producto</option>
                                                <option value="name_created_by">Quien entrega</option>
                                                <option value="name_user">Quien retira</option>
                                        </select>
                                </span>
                                <span>
                                        <input type="search" name="search" placeholder="buscar" defaultValue={search} />
                                </span>
                                <span>
                                        <button type="submit">
                                                <Search />
                                        </button>
                                </span>
                                <span>
                                        <button type="button">
                                                <Download />
                                        </button>
                                </span>
                        </form>
                </div>
        )
}

export default Filters
import React from 'react'
import ContainerPages from '../components/containerPages'
import styles from '../styles/packageStore.module.css'
import usePackageStore from '../hooks/usePackageStore'
import FilterDate from '../components/packageStore/filters'
import Delete from '../img/svg/delete'

const PackageStore = () => {

        const { permissions, getPackageStore, inititalFilters, deletePackageStore, filterPackageStore, downloadPackageStore } = usePackageStore();

        return (
                <ContainerPages>
                        <div className={styles.container_filters}>
                                <span>
                                        <h3>Control de bodega 2</h3>
                                        <button type='button' onClick={() => permissions('/package-store/add', 'submenu')}>Agregar</button>
                                </span>
                        </div>
                        <FilterDate
                                inititalFilters={inititalFilters}
                                filterPackageStore={filterPackageStore}
                                downloadPackageStore={downloadPackageStore}
                        />
                        <div className={styles.container_table_store}>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th rowSpan='2'>N°</th>
                                                        <th rowSpan='2'>Nombre producto</th>
                                                        <th colSpan='2'>Entrada</th>
                                                        <th colSpan='3'>Salida</th>
                                                        <th rowSpan='2'>Diferencia</th>
                                                        <th rowSpan='2'>Quien entrega</th>
                                                        <th rowSpan='2'>Quien Retira</th>
                                                        <th colSpan='2'>Firmas</th>
                                                        <th rowSpan='2'>Acciones</th>
                                                </tr>
                                                <tr>
                                                        <th>Hora</th>
                                                        <th>Cantidad</th>
                                                        <th>Hora</th>
                                                        <th>Cantidad</th>
                                                        <th>Acciones</th>
                                                        <th>Quien entrega</th>
                                                        <th>Quien retira</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {getPackageStore === 0 ? (
                                                        <tr>
                                                                <td colSpan='12'>No hay registros</td>
                                                        </tr>
                                                ) : (
                                                        getPackageStore === null ? (
                                                                <tr>
                                                                        <td colSpan='12'>Cargando...</td>
                                                                </tr>
                                                        ) : (
                                                                getPackageStore.results.map((packageStore) => (
                                                                        <tr key={packageStore.id}>
                                                                                <td>{packageStore.id}</td>
                                                                                <td>{packageStore.name_product}</td>
                                                                                <td>{packageStore.exit_time}</td>
                                                                                <td>{packageStore.exit_quantity}</td>
                                                                                <td>{packageStore.arrival_time}</td>
                                                                                <td>{packageStore.arrival_quantity}</td>
                                                                                <td>
                                                                                        <button type='button' onClick={() => permissions(`/package-store/add-arrival/${packageStore.id}`, 'submenu')}>Agregar</button>
                                                                                </td>
                                                                                <td>{packageStore.difference_quantity}</td>
                                                                                <td>{packageStore.dispatcher_name}</td>
                                                                                <td>{packageStore.assigned_salesperson_name}</td>
                                                                                <td>{packageStore.sign_dispatcher == 1 ? "Firmado" : "Por firmar"}</td>
                                                                                <td>{packageStore.sign_seller == 1 ? "Firmado" : "Por firmar"}</td>
                                                                                <td>
                                                                                        <button type='button' onClick={() => deletePackageStore(packageStore.id, packageStore.assigned_salesperson_name)}>
                                                                                                <Delete />
                                                                                        </button>
                                                                                </td>
                                                                        </tr>
                                                                ))
                                                        )
                                                )}
                                        </tbody>
                                </table>
                        </div>
                </ContainerPages>
        )
}

export default PackageStore
import React from 'react'
import styles from '../../styles/production.module.css'
import { Download } from '../../img/svg/download'
import { dateTime } from '../../services/globals'
import { useWorkers } from '../../hooks/useWorkers'

const Filter = ({ getFiltersProduction, download, production }) => {


        const { currentDay, currentTime } = dateTime()
        const { getAllWorkers } = useWorkers()

        return (
                <div className={styles.container_filters}>
                        <div className={styles.box_filter}>
                                <h3>Filtrar por</h3>
                                <span>
                                        <form onSubmit={getFiltersProduction}>
                                                <input type='datetime-local' name='start_date' defaultValue={currentDay} />
                                                <p>Hasta</p>
                                                <input type='datetime-local' name='end_date' defaultValue={currentTime} />
                                                <select name='user'>
                                                        {getAllWorkers === null ? (
                                                                <option value=''>Cargando...</option>
                                                        ) : (
                                                                <>
                                                                        <option value='all'>Todos</option>
                                                                        {getAllWorkers.map((worker, index) => (
                                                                                <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                        ))}
                                                                </>
                                                        )}
                                                </select>
                                                <button type='submit'>Filtrar</button>
                                        </form>
                                        <button type='button' title='exportar a excel' onClick={() => download()}>
                                                <Download />
                                        </button>
                                </span>
                        </div>
                        <div className={styles.box_information_table}>
                                {production === 0 || production === null ? (
                                        <p>No hay registros</p>
                                ) : (
                                        <>
                                                <p>Mostrando</p>
                                                <p>{production.length} de {production.length}</p>
                                        </>
                                )}
                        </div>
                </div>
        )
}

export default Filter
import React, { useContext } from 'react'
import styled from 'styled-components';
import LoginContext from '../../../contexts/login';

const ActionFinalDelivery = ({ exportItem }) => {

    const { cancelOrder, btnCancelOrder, commentsDelivery,deliveryOrderProduct } = exportItem
    const { activeMenu } = useContext(LoginContext)

    return (
        <Modal style={{ width: activeMenu ? '100%' : 'calc(100% - 150px' }}>
            <form onSubmit={commentsDelivery.status === "Error" ? cancelOrder : deliveryOrderProduct}>
                <h3>{commentsDelivery.title}</h3>
                <input type="hidden" name="id_cart_detail" defaultValue={commentsDelivery.id} />
                <input type="hidden" name="id_shipping" defaultValue={commentsDelivery.id_shipping} />
                <input type="hidden" name="type" defaultValue={commentsDelivery.status} />
                <input type="hidden" name="quantity_product" defaultValue={commentsDelivery.in_transit} />
                <textarea name="comments" placeholder="escribe algo" required />
                <span>
                    <button type="submit">Guardar</button>
                    <button type="button" onClick={(e) => btnCancelOrder()}>Cancelar</button>
                </span>
            </form>
        </Modal>
    )
}


const Modal = styled.div`

    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    align-items:center;
    justify-content: center;
    right: 0;
    transition: width 0.3s ease;



form{
    display:flex;
    width:90%;
    height:auto;
    padding:20px;
    background-color: #FFFFFF;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    h3{
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    textarea{
        width: 100%;
        max-width: 100%;
        min-height: 100px;
    }
    span{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
        padding-top: 20px;

        button{
                width:100px;
                padding: 5px 0;
                border: none;
                border-radius: 5px;
                color: var(--secundaryBackground);
                cursor: pointer;

            }
            button:nth-child(1){
                background-color:var(--primaryColor);

                &:disabled{
                    background-color:rgba(0,0,0,0.2);

                }
            }
            button:nth-child(2){
                background-color:red;
            }
    }

}
`;


export default ActionFinalDelivery
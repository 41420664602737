import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import LoginContext from '../contexts/login'
import { GET_LOC } from '../services/get'
import { POST_LOC } from '../services/post'

export const useProducts = () => {

        const { setToast, activeToast, navigation } = useContext(LoginContext)
        const [product, setProduct] = useState(null);
        const [getAllProducts, setGetAllProducts] = useState(null);
        const [totalProducts, setTotalProducts] = useState(0);
        //crud de productos

        const searchProduct = (param, value) => {
                GET_LOC({
                        table: 'products',
                        params: `?param=${param}&value=${value}`,
                }).then(response => {
                        if (response.status === 200) {
                                setProduct(response.results)
                        } else {
                                setProduct(0)
                        }
                })
        }

        const searchAllProducts = () => {
                GET_LOC({
                        table: 'products',
                        params:'?list=true'
                }).then(response => {
                        if (response.status === 200) {
                                setTotalProducts(response.total)
                                setGetAllProducts(response.results)
                        } else {
                                setGetAllProducts(0)
                        }
                })
        }

        useEffect(() => {
                searchAllProducts()
        }, [])
        

        const addProduct = (e) => {
                e.preventDefault()
                let form = new FormData(e.target)

                POST_LOC({
                        table: 'products',
                        data: form
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: "Producto agregado correctamente",
                                        error: false,
                                        time: 3000,
                                })
                                activeToast()
                                return navigation('/products', { replace: true })

                        } else if (response.status == 404) {
                                setToast({
                                        message: response.results,
                                        error: true,
                                        time: 3000,
                                })
                                activeToast()
                                return;
                        } else {
                                setToast({
                                        message: "Error al agregar el producto",
                                        error: true,
                                        time: 2000,
                                })
                                activeToast()
                        }
                })

        }

        const updateProduct = (e) => {
                e.preventDefault()
                let form = new FormData(e.target)

                POST_LOC({
                        table: 'products?update=true',
                        data: form
                }).then(response => {
                        setToast({
                                message: "Producto actualizado correctamente",
                                error: false,
                                time: 3000,
                        })
                        activeToast()
                        return navigation('/products', { replace: true })

                })
        }

        const deleteProduct = (id) => {
                
        }

                return { 
                        addProduct, 
                        updateProduct, 
                        searchProduct, 
                        product,
                        deleteProduct,
                        getAllProducts,
                        totalProducts
                 }
}

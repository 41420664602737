import React from 'react'
import styles from '../../styles/dailyManagement.module.css'
import { Download } from '../../img/svg/download'

const Filters = ({ getSubAcc, getAcc, filterInitial, filterAccountsExpenses,downloadAccountsExpenses }) => {

        const { start, end, account, subaccount } = filterInitial

        return (
                <form className={styles.container_filter_daily} onSubmit={filterAccountsExpenses}>
                        <span>
                                <label htmlFor=''>Fecha inicial</label>
                                <input type='datetime-local' name='start' defaultValue={start} />
                        </span>
                        <span>
                                <label htmlFor=''>Fecha final</label>
                                <input type='datetime-local' name='end' defaultValue={end} />
                        </span>
                        <span>
                                <select name='account' id='account' defaultValue={account}>
                                        {getAcc === 0 ? (
                                                <option value=''>Sin cuentas</option>
                                        ) : (
                                                getAcc === null ? (
                                                        <option value=''>Cargando...</option>
                                                ) : (
                                                        <>
                                                                <option value='0'>Todas las cuentas</option>
                                                                {getAcc.map((item, index) => (
                                                                        <option key={item.id_account} value={item.id_account}>{item.name_account}</option>
                                                                ))}
                                                        </>
                                                )
                                        )}
                                </select>
                        </span>
                        <span>
                                <select name='subaccount' id='subaccount' defaultValue={subaccount}>
                                        {getSubAcc === 0 ? (
                                                <option value=''>Sin subcuentas</option>
                                        ) : (
                                                getSubAcc === null ? (
                                                        <option value=''>Cargando...</option>
                                                ) : (
                                                        <>
                                                                <option value='0'>Todas las subcuentas</option>
                                                                {getSubAcc.map((item, index) => (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))}
                                                        </>
                                                )
                                        )}
                                </select>
                        </span>
                        <span>
                                <button type='submit'>Filtrar</button>
                        </span>
                        <span>
                                <button type='button' onClick={() => downloadAccountsExpenses()}>
                                        <Download />
                                </button>
                        </span>
                </form>
        )
}

export default Filters
import React from 'react'
import { chileanCurrency } from '../../services/globals'
import Delete from '../../img/svg/delete'
import Edit from '../../img/svg/edit'

const ListItem = ({ item,deleteCashControl }) => {

        const { id, delivery_data, name_seller, name_responsible_cashier, sales_with_cash, sales_with_guide, sales_with_invoice, seller_sales_with_cash, seller_sales_with_guide, seller_sales_with_invoice, end_date, start_date, creation_date, update_date, difference_cash, difference_guides, difference_invoices } = item

        return (
                <>
                        <tr>
                                <td>{id}</td>
                                <td>{name_responsible_cashier}</td>
                                <td>{name_seller}</td>
                                <td>{chileanCurrency(seller_sales_with_cash)}</td>
                                <td>{chileanCurrency(seller_sales_with_guide)}</td>
                                <td>{chileanCurrency(seller_sales_with_invoice)}</td>
                                <td>{chileanCurrency(sales_with_cash)}</td>
                                <td>{chileanCurrency(sales_with_guide)}</td>
                                <td>{chileanCurrency(sales_with_invoice)}</td>
                                <td>{chileanCurrency(difference_cash)}</td>
                                <td>{chileanCurrency(difference_guides)}</td>
                                <td>{chileanCurrency(difference_invoices)}</td>
                                <td>
                                        <button type='button'>
                                                <Edit />
                                        </button>
                                        <button type='button' onClick={()=> deleteCashControl(id)}>
                                                <Delete />
                                        </button>
                                </td>
                        </tr >
                </>
        )
}

export default ListItem
import styles from '../styles/loaderSubmit.module.css'
const Loading = () => {
        return (
                <div className={styles.box_loader}>
                        <div className={styles.loader}></div>
                        <p>Guardando...</p>
                </div>
        )
}

export default Loading;
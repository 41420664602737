import React, { useState } from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/addPromotion.module.css'
import { useProducts } from '../../hooks/useProducts'
import { API, chileanCurrency } from '../../services/globals'
import sinImagen from '../../img/sin-imagen.png'
import { usePromotions } from '../../hooks/usePromotions'

const AddPromotion = () => {

        const { getAllProducts, totalProducts } = useProducts()

        const { addPromotion, products, setProducts } = usePromotions()

        if (getAllProducts === null) return;

        const addProduct = (product) => {

                let duplicate = products.find(item => item.id_product === product.id_product)
                if (duplicate !== undefined) {
                        let newProducts = products.map(item => {
                                if (item.id_product === product.id_product) {
                                        item.quantity = item.quantity + 1
                                }
                                return item
                        })

                        setProducts(newProducts)
                        return;

                }
                setProducts([...products, product])
        }

        const removeProduct = (id) => {
                let newProducts = products.filter(item => item.id_product !== id)
                setProducts(newProducts)
        }

        return (
                <ContainerPages>
                        <div className={styles.container_add_promotion}>
                                <div className={styles.container_form_add_promotion}>
                                        <div className={styles.title}>
                                                <h3>Agregar promoción</h3>
                                        </div>
                                        <form onSubmit={addPromotion}>
                                                <label>Imagen </label>
                                                <input type='file' name='main_image' />
                                                <label htmlFor='name_promotion'>Nombre</label>
                                                <input type='text' name='name_promotion' id='name_promotion' required />
                                                <label htmlFor='description'>Descripción</label>
                                                <textarea type='text' name='description' id='description' required></textarea>
                                                <label htmlFor='price_promotion'>Precio promoción</label>
                                                <input type='number' name='price_promotion' id='price_promotion' required />
                                                <label htmlFor='is_active'>¿Esta activo?</label>
                                                <input type='checkbox' name='is_active' id='is_active' defaultChecked />
                                                <div className={styles.products_of_promotions}>
                                                        <table>
                                                                <caption>Productos de la promoción</caption>
                                                                <thead>
                                                                        <tr>
                                                                                <th>Producto</th>
                                                                                <th>Stock</th>
                                                                                <th>Cantidad</th>
                                                                                <th>Acciones</th>
                                                                        </tr>
                                                                </thead>
                                                                <tbody>
                                                                        {products.length > 0 ? (
                                                                                products.map((product, index) => (
                                                                                        <tr key={product.id_product}>
                                                                                                <td>{product.name_product}</td>
                                                                                                <td>{product.stock_product}</td>
                                                                                                <td>{product.quantity}</td>
                                                                                                <td>
                                                                                                        <button type='button' onClick={() => removeProduct(product.id_product)}>X</button>
                                                                                                </td>
                                                                                        </tr>
                                                                                ))
                                                                        ) : (
                                                                                <tr>
                                                                                        <td>Ningun </td>
                                                                                        <td>producto</td>
                                                                                        <td>agregado</td>
                                                                                        <td></td>
                                                                                </tr>
                                                                        )}
                                                                </tbody>
                                                        </table>
                                                </div>
                                                <span>
                                                        <button type='submit'>Guardar</button>
                                                </span>
                                        </form>
                                </div>
                                <div className={styles.container_products}>
                                        <div className={styles.title}>
                                                <h3>Productos disponibles ({totalProducts ?? 0})</h3>
                                        </div>
                                        <div className={styles.box_product}>
                                                {getAllProducts.filter(item => item.is_active_product != 0).map(product => (
                                                        <span key={product.id_product}>
                                                                {product.image_product !== "" ? (
                                                                        <img src={`${API}/uploads/products/${product.image_product}`} />
                                                                ) : (
                                                                        <img src={sinImagen} title='sin imagen' />
                                                                )}
                                                                <p>{product.name_product}</p>
                                                                <p>Stock ({product.stock_product})</p>
                                                                <p>Ref ${chileanCurrency(product.price_product)}</p>
                                                                <button type='button' onClick={() => addProduct({ ...product, quantity: 1 })}>Agregar</button>
                                                        </span>
                                                ))}
                                        </div>
                                </div>
                        </div >
                </ContainerPages >
        )
}

export default React.memo(AddPromotion)
import React from 'react'
import ContainerPages from '../containerPages'
import { useWorkers } from '../../hooks/useWorkers'
import styles from '../../styles/parameters.module.css'
import { useParamsMaintenance } from '../../hooks/useParamsMaintenance'
import { listAction } from '../../services/globals'

const AddMaintenance = () => {

        const { getAllWorkers } = useWorkers()
        const { newMaintenance,navigation } = useParamsMaintenance()

        return (
                <ContainerPages>
                        <div className={styles.form_add_maintenance}>
                                <h3>Nueva mantencion programada</h3>
                                <form onSubmit={newMaintenance}>
                                        <div>
                                                <label htmlFor='name'>Nombre</label>
                                                <input type='text' name='name' id='name' required />
                                        </div>
                                        <div>
                                                <label htmlFor='programmed_date'>Fecha</label>
                                                <input type='datetime-local' name='programmed_date' id='programmed_date' required />
                                        </div>
                                        <div>
                                                <label htmlFor='action'>Accion</label>
                                                <select name='action' id='action'>
                                                        {listAction.map(item => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                </select>
                                        </div>
                                        <div>
                                                <label htmlFor='associated_user'>Usuario</label>
                                                <select name='associated_user'>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay usuarios</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione un usuario</option>
                                                                                {getAllWorkers.map((worker) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </div>
                                        <div>
                                                <label htmlFor='observations'>Observaciones</label>
                                                <textarea name='observations' id='observations' cols='30' rows='10'></textarea>
                                        </div>
                                        <div>
                                                <button type='submit'>Guardar</button>
                                                <button type='button' onClick={()=> navigation('/maintenance/maintenance')}>Cancelar</button>
                                        </div>

                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddMaintenance
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styles from '../../styles/addDailyManager.module.css'

const AddFormSubAccount = ({ activeFormSubAccount, deleteSubAccount, getSubAcc, addSubAccount, setActiveFormSubAccount }) => {

        return (
                <AnimatePresence>
                        {activeFormSubAccount && (
                                <motion.div className={styles.box_add_subaccount} layout>
                                        <div>
                                                <form onSubmit={addSubAccount}>
                                                        <span>
                                                                <button type='button' onClick={() => setActiveFormSubAccount(false)}>Cancelar</button>
                                                                <h3>Nueva subcuenta</h3>
                                                        </span>
                                                        <input type='text' name='name' placeholder='nombre de la subcuenta' />
                                                        <span>
                                                                <button type='submit'>Agregar</button>
                                                        </span>
                                                </form>
                                                <div>
                                                        <table>
                                                                <thead>
                                                                        <tr>
                                                                                <th>Id</th>
                                                                                <th>Nombre</th>
                                                                                <th>Acciones</th>
                                                                        </tr>
                                                                </thead>
                                                                <tbody>
                                                                        {getSubAcc === 0 ? (
                                                                                <tr>
                                                                                        <td colSpan='3'>Sin subcuentas</td>
                                                                                </tr>
                                                                        ) : (
                                                                                getSubAcc === null ? (
                                                                                        <tr>
                                                                                                <td colSpan='3'>Cargando...</td>
                                                                                        </tr>
                                                                                ) : (
                                                                                        getSubAcc.map((item, index) => (
                                                                                                <tr key={item.id}>
                                                                                                        <td>{item.id}</td>
                                                                                                        <td>{item.name}</td>
                                                                                                        <td>
                                                                                                                <button type='button' onClick={() => deleteSubAccount(item.id)}>Eliminar</button>
                                                                                                        </td>
                                                                                                </tr>
                                                                                        ))
                                                                                )
                                                                        )}
                                                                </tbody>
                                                        </table>
                                                </div>
                                        </div>
                                </motion.div>
                        )}
                </AnimatePresence>
        )
}

export default AddFormSubAccount
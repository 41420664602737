import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/addProduct.module.css'
import { useProducts } from '../../hooks/useProducts'

const Add = () => {

        const { addProduct } = useProducts()

        return (
                <ContainerPages>
                        <div className={styles.container_add}>
                                <form onSubmit={addProduct}>
                                        <h3>AGREGAR NUEVO PRODUCTO</h3>
                                        <label htmlFor='image_product'>Imagen</label>
                                        <input type="file" name="image_product" id='image_product' />
                                        <label htmlFor='name_product'>Nombre</label>
                                        <input type="text" name="name_product" id='name_product' required />
                                        <label htmlFor='description_product'>Descripción del producto</label>
                                        <textarea name="description_product" id='description_product' required></textarea>
                                        <label htmlFor='vendor_product'>Proveedor</label>
                                        <input name="vendor_product" id='vendor_product' />
                                        <label htmlFor='category_product'>Categoria</label>
                                        <input type='text' name='category_product' id='category_product' />
                                        <label htmlFor='price_product'>Precio</label>
                                        <input type="number" name="price_product" id='price_product' required />
                                        <label htmlFor='company_price'>Precio Empresa</label>
                                        <input type="number" name="company_price" id='company_price' required />
                                        <label htmlFor='distributor_price'>Precio Distribuidor</label>
                                        <input type='number' name='distributor_price' id='distributor_price' required />
                                        <label htmlFor='stock_product'>Stock</label>
                                        <input type="number" name="stock_product" id='stock_product' required />
                                        <label htmlFor='is_internal'>Tipo de producto</label>
                                        <select defaultValue='0' id='is_internal' name='is_internal'>
                                                <option value="0">Para venta</option>
                                                <option value="1">producto interno</option>
                                        </select>
                                        <span>
                                                <label htmlFor='is_active_product'>¿Esta activo?</label>
                                                <input type='checkbox' name='is_active_product' id='is_active_product' defaultChecked />
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type="button">Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default Add
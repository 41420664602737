import { useClients } from '../../../hooks/useClients'

const LastClientAdded = () => {

        const { getAllClients } = useClients()

        return (
                <div>
                        <table>
                                <caption>Ultimos clientes agregados</caption>
                                <thead>
                                        <tr>
                                                <th>Nombre</th>
                                                <th>Rut</th>
                                                <th>Telefono</th>
                                                <th>Dirección</th>
                                        </tr>
                                </thead>
                                {getAllClients === null ? (
                                        <tbody>
                                                <tr>
                                                        <th>Cargando...</th>
                                                        <th></th>
                                                </tr>
                                        </tbody>
                                ) : (getAllClients === 0 ? (
                                        <tbody>
                                                <tr>
                                                        <th>Sin registros</th>
                                                        <th></th>
                                                </tr>
                                        </tbody>
                                ) : (
                                        getAllClients.slice(0, 3).map((item, index) => (
                                                <tbody key={index}>
                                                        <tr>
                                                                <th>{item.name_client}</th>
                                                                <th>{item.rut_client}</th>
                                                                <th>{item.phone_client}</th>
                                                                <th>{item.direction_client}</th>
                                                        </tr>
                                                </tbody>
                                        ))
                                ))}
                        </table>
                </div>
        )
}

export default LastClientAdded
import { API, BASE_API } from "./globals";


export const DELETE = ({
    table = null,
    id = null,
    nameId = null,
    tableToken = null,
    suffix = null,
    token = null,
}) =>
    fetch(`${BASE_API}${table}?id=${id}&nameId=${nameId}&table=${tableToken}&suffix=${suffix}&token=${token}`, {
        method: 'DELETE',
        redirect: 'follow'
    })
        .then(response => response.json())
        .catch(err => console.log(err))

export const DELETE_LOC = ({
    table = null,
    params = null,
}) =>
    fetch(`${API}/${table}${params}`, {
        method: 'DELETE',
    })
        .then(response => response.json())
        .catch(err => console.log(err))

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { POST_LOC } from '../services/post'
import { useContext } from 'react'
import LoginContext from '../contexts/login'
import { GET, GET_LOC } from '../services/get'
import { DELETE_LOC } from '../services/delete'
import { API, dateTime } from '../services/globals'

export const useParamsMaintenance = () => {
        const { setToast, activeToast } = useContext(LoginContext)
        const navigation = useNavigate()
        const [allParameters, setAllParameters] = useState(null)
        const [allMaintenance, setAllMaintenance] = useState(null)
        const { currentDate, initialMonthDate } = dateTime()
        const [atMaintenance, setAtMaintenance] = useState([])
        const [atParams, setAtParams] = useState([])
        const [saveFilters, setSaveFilters] = useState(null)
        const [saveFiltersMaintenance, setSaveFiltersMaintenance] = useState(null)

        const initialMaintenance = {
                start: initialMonthDate,
                end: currentDate,
                associated_user: 0,
                action: 0,
                search: ""

        }
        const initialParams = {
                start: initialMonthDate,
                end: currentDate,
                associated_user: 0
        }

        const submitAddparams = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                if (data.get('associated_user') === '0') {
                        setToast({
                                message: 'Sebes seleccionar un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'parameters',
                        data: data
                }).then(response => {

                        if (response.lastId) {
                                setToast({
                                        message: 'Los parametros han sido agregados correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/maintenance', { replace: true })
                                return;
                        }
                        setToast({
                                message: 'Ha ocurrido un error al agregar los parametros',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                        //   navigation('/maintenance', { replace: true })
                }).catch(error => console.log(error))
                        .finally(() => {

                        })
        }

        const submitUpdateParams = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                if (data.get('associated_user') === '0') {
                        setToast({
                                message: 'Sebes seleccionar un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'parameters?action=update',
                        data: data
                }).then(response => {

                        if (response.status === 200) {
                                setToast({
                                        message: 'Los parametros han sido actualizados correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/maintenance', { replace: true })
                                return;
                        }
                        setToast({
                                message: 'Ha ocurrido un error al actualizar los datos',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                        //   navigation('/maintenance', { replace: true })
                }).catch(error => console.log(error))
                        .finally(() => {

                        })

        }

        const deleteParam = (id) => {

                let isDelete = window.confirm('¿Estas seguro de eliminar este parametros?');
                if (!isDelete) return;

                DELETE_LOC({
                        table: 'parameters',
                        params: `?id=${id}`
                }).then(response => {

                        if (response.status === 200) {

                                setAllParameters(prevParam => prevParam.filter(param => param.id !== id))

                                setToast({
                                        message: 'Los parametros han sido eliminados correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                        setToast({
                                message: 'Ha ocurrido un error al eliminar los parametros',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                })
        }

        const getParams = (filters) => {

                const { start, end, associated_user } = filters
                GET_LOC({
                        table: 'parameters',
                        params: `?start=${start}&end=${end}&user=${associated_user}`
                }).then(response => {
                        if (response.status === 200) {
                                setAllParameters(response.results)
                        }

                        else {
                                setSaveFilters(null)
                                setAllParameters(0)
                        }

                })
        }

        const filterParameters = (e) => {
                e.preventDefault()
                let data = Object.fromEntries(new FormData(e.target))
                setSaveFilters(data)
                getParams(data)

        }

        const downloadParameters = () => {

                if (allParameters === null || allParameters === 0) {
                        setToast({
                                message: 'No hay datos para descargar',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                };

                const { start, end, associated_user } = saveFilters !== null ? saveFilters : initialParams

                fetch(`${API}/parameters?start=${start}&end=${end}&user=${associated_user}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `parametros(desde ${start} hasta ${end}).xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const downloadMaintenance = () => {

                if (allMaintenance === null || allMaintenance === 0) {
                        setToast({
                                message: 'No hay datos para descargar',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                };

                const { start, end, associated_user, action, search } = saveFiltersMaintenance !== null ? saveFiltersMaintenance : initialMaintenance

                fetch(`${API}/maintenance?start=${start}&end=${end}&associated_user=${associated_user}&action=${action}&search=${search}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `Mantenciones(desde ${start} hasta ${end}).xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const getMaintenance = (filters) => {

                const { start, end, associated_user, action, search } = filters
                GET_LOC({
                        table: 'maintenance',
                        params: `?start=${start}&end=${end}&associated_user=${associated_user}&action=${action}&search=${search}`
                }).then(response => {
                        if (response.status === 200) {
                                setAllMaintenance(response.results)
                        }
                        else {
                                setAllMaintenance(0)
                        }

                })
        }

        const findMaintenance = (id) => {
                GET_LOC({
                        table: 'maintenance',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setAtMaintenance(response.results[0])
                        }
                        else {
                                setAtMaintenance(0)
                        }

                }
                )
        }

        const findParam = (id) => {
                GET_LOC({
                        table: 'parameters',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setAtParams(response.results)
                        }
                        else {
                                setAtParams(0)
                        }

                }
                )
        }

        const submitUpdateMaintenance = (e) => {
                e.preventDefault()

                let data = new FormData(e.target)

                if (data.get('action') === '0') {
                        setToast({
                                message: 'Debes seleccionar una acción',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                if (data.get('associated_user') === '0') {
                        setToast({
                                message: 'Debes seleccionar un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'maintenance?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: 'La mantención ha sido actualizada correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/maintenance/maintenance', { replace: true })
                                return;
                        } else {
                                setToast({
                                        message: 'Ha ocurrido un error al actualizar los datos,intenta nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                }).catch(error => console.log(error))
        }

        const newMaintenance = (e) => {
                e.preventDefault()

                let data = new FormData(e.target)

                if (data.get('action') === '0') {
                        setToast({
                                message: 'Debes seleccionar una acción',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                if (data.get('associated_user') === '0') {
                        setToast({
                                message: 'Debes seleccionar un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'maintenance',
                        data: data
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: 'La mantención ha sido agregada correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/maintenance/maintenance', { replace: true })
                                return;
                        }
                }).catch(error => console.log(error))
        }

        const deleteMaintenance = (id) => {

                let isDelete = window.confirm('¿Estas seguro de eliminar esta mantención?');
                if (!isDelete) return;

                DELETE_LOC({
                        table: 'maintenance',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {

                                setAllMaintenance(prevMaintenance => prevMaintenance.filter(maintenance => maintenance.id !== id))
                                setToast({
                                        message: 'La mantención ha sido eliminada correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        } else {
                                setToast({
                                        message: 'Ha ocurrido un error al eliminar la mantención',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        const filterMaintenance = (e) => {
                e.preventDefault()
                let data = Object.fromEntries(new FormData(e.target))
                setSaveFiltersMaintenance(data)
                getMaintenance(data)
        }

        useEffect(() => {
                getParams(initialParams)
                getMaintenance(initialMaintenance)
        }, [])

        return {
                navigation,
                submitAddparams,
                allParameters,
                deleteParam,
                newMaintenance,
                allMaintenance,
                deleteMaintenance,
                initialParams,
                filterParameters,
                initialMaintenance,
                filterMaintenance,
                submitUpdateParams,
                findMaintenance,
                atMaintenance,
                findParam,
                atParams,
                submitUpdateMaintenance,
                downloadParameters,
                downloadMaintenance
        }
}

import { useContext, useEffect, useState } from 'react'
import { GET_LOC } from '../services/get'
import { POST_LOC } from '../services/post'
import LoginContext from '../contexts/login'
import { DELETE_LOC } from '../services/delete'

export const usePromotions = () => {

        const { setToast, activeToast, navigation } = useContext(LoginContext)
        const [getAllPromotions, setGetAllPromotions] = useState(null)
        const [totalPromotions, setTotalPromotions] = useState(0)
        const [products, setProducts] = useState([])

        const searchAllPromotions = () => {
                GET_LOC({
                        table: 'promotions',
                        params: '?list=true'
                }).then(response => {
                        if (response.status === 200) {
                                setTotalPromotions(response.total)
                                setGetAllPromotions(response.results)
                        } else {
                                setGetAllPromotions(0)
                        }
                })
        }

        const deletePromotion = (id) => {
                let isDelete = window.confirm('¿Está seguro de eliminar esta promoción?')

                if (isDelete) {
                        DELETE_LOC({
                                table: 'promotions',
                                params: `?id=${id}`
                        })
                                .then(response => {
                                        if (response.status === 200) {
                                                setToast({
                                                        message: "Promoción eliminada correctamente",
                                                        error: false,
                                                        time: 3000
                                                })
                                                activeToast()
                                                searchAllPromotions()
                                        }
                                })
                }
        }

        const addPromotion = (e) => {
                e.preventDefault()

                let formData = new FormData(e.target)

                formData.append('products', JSON.stringify(products))


                if (products.length === 0) {
                        setToast({
                                message: "Debe seleccionar al menos un producto",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'promotions',
                        data: formData
                })
                        .then(response => {
                                if (response.status === 200) {
                                        setToast({
                                                message: "Promoción agregada correctamente",
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                        navigation('/promotions', { replace: true })
                                } else {
                                        setToast({
                                                message: response.results,
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return;
                                }
                        })
                        .catch(error => console.log(error))
        }

        useEffect(() => {
                searchAllPromotions()
        }, [])

        return {
                totalPromotions,
                getAllPromotions,
                deletePromotion,
                products,
                setProducts,
                addPromotion,
        }
}

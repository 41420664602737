import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ContainerPages from '../components/containerPages'
import LoginContext from '../contexts/login'
import OrderContext from '../contexts/orders'
import { POST_LOC } from '../services/post'
import { PUT_LOC } from '../services/put'
import styles from '../styles/deliveryId.module.css'
import ActionFinalDelivery from './deliveries/components/cancelDelivery'

const DeliveryId = () => {

        const { id } = useParams()
        const { setToast, activeToast, navigation, authenticated } = useContext(LoginContext)
        const { getDetailsOrder, detailsOrder } = useContext(OrderContext)
        const [activeCancelOrder, setActiveCancelOrder] = useState(false)
        const [commentsDelivery, setCommentsDelivery] = useState([])

        const btnCancelOrder = () => {
                setActiveCancelOrder(!activeCancelOrder)
        }

        const deliveryOrderProduct = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                POST_LOC({
                        table: 'shipping_comments',
                        data: data
                }).then(response => {

                        setToast({
                                message: 'Producto entregado exitosamente',
                                error: false,
                                time: 3000,
                        })
                        activeToast()
                        return navigation('/deliveries', { replace: true })
                })
        }

        const cancelOrder = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                POST_LOC({
                        table: 'shipping_comments',
                        data: data
                }).then(response => {

                        setToast({
                                message: 'Producto cancelado de entrega',
                                error: false,
                                time: 3000,
                        })
                        activeToast()
                        navigation('/deliveries', { replace: true })
                })

        }

        const activateModalComments = (product) => {
                if (product.id_deliveryman !== authenticated.user[0].id_user) {
                        setToast({
                                message: 'La entrega de este producto a sido reasignado a otro vendedor',
                                error: true,
                                time: 3000,
                        })
                        activeToast()
                        return;
                }
                //id_deliveryman
                setCommentsDelivery(product)
                setActiveCancelOrder(!activeCancelOrder)
        }


        const deliverOrder = (product) => {

                let data = new URLSearchParams()
                data.append('id', product.id)
                data.append('delivered', product.in_transit)

                PUT_LOC({
                        table: 'cart_detail',
                        data: data
                }).then(response => {
                        setToast({
                                message: 'Producto entregado exitosamente',
                                error: false,
                                time: 3000,
                        })
                        activeToast()
                        getDetailsOrder(id)

                })
        }

        const deliverAllOrder = () => {

                let verification = detailsOrder.products.filter(product => parseInt(product.in_transit) > 0 && parseInt(product.id_deliveryman) === parseInt(authenticated.user[0].id_user))


                if (verification.length === 0) {
                        setToast({
                                message: 'No hay productos pendientes de entrega',
                                error: true,
                                time: 3000,
                        })
                        activeToast()
                        return;
                }

                Promise.allSettled(
                        verification.map((product) => {
                                let allProducts = new FormData()
                                allProducts.append('id_cart_detail', product.id)
                                allProducts.append('id_shipping', product.id_shipping)
                                allProducts.append('type', 'Success')
                                allProducts.append('quantity_product', product.in_transit)
                                allProducts.append('comments', 'Producto entregado')
                                return POST_LOC({
                                        table: 'shipping_comments',
                                        data: allProducts
                                })
                        })
                ).then(response => {
                        console.log(response)
                        setToast({
                                message: `Pedidos entregados`,
                                error: false,
                                time: 3000,
                        })

                        activeToast()
                        getDetailsOrder(id)
                        return navigation(`/deliveries/update/${id}`, { replace: true })

                })

        }
        useEffect(() => {
                getDetailsOrder(id)
        }, [])


        const exportItem = {
                setActiveCancelOrder,
                btnCancelOrder,
                activateModalComments,
                commentsDelivery,
                cancelOrder,
                deliveryOrderProduct,
                deliverOrder
        }

        if (detailsOrder === null) return;

        return (
                <ContainerPages>
                        <div className={styles.box_back}>
                                <button onClick={() => navigation('/deliveries', { replace: true })}>Volver</button>
                        </div>
                        <div className={styles.box_details_order}>
                                <h3>Detalles del pedido N°{id}</h3>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>Nombre cliente</th>
                                                        <th>Rut</th>
                                                        <th>Telefono</th>
                                                        <th>Direccion</th>
                                                        <th>Productos totales</th>
                                                        <th>Fecha del pedido</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {detailsOrder.order.map((order, index) => (
                                                        <tr key={index}>
                                                                <td>{order.name_client}</td>
                                                                <td>{order.rut_client}</td>
                                                                <td>{order.phone_client}</td>
                                                                <td>{order.direction_client}</td>
                                                                <td>{order.additional_comments}</td>
                                                                <td>{order.creation_date}</td>
                                                        </tr>
                                                ))}
                                        </tbody>
                                </table>
                        </div>
                        <div className={styles.box_detail_product_order}>
                                <h3>Productos del pedido</h3>
                                <button type='button' onClick={() => deliverAllOrder()}>Entregar Todos</button>
                                <p><strong style={{ color: 'red' }}>NOTA:</strong>solo se pueden entregar los pedidos en transito </p>
                                <div>
                                        <table>
                                                <thead>
                                                        <tr>
                                                                <th>N°</th>
                                                                <th>Nombre Producto</th>
                                                                <th>Cantidad</th>
                                                                <th>Detalles de envio</th>
                                                                <th>Acciones</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {detailsOrder.products.map((product, index) => (
                                                                <tr key={index}>
                                                                        <td>{product.id}</td>
                                                                        <td>{product.name_product}</td>
                                                                        <td>{product.quantity}</td>
                                                                        <td>
                                                                                {product.quantity != product.delivered ? (
                                                                                        <>
                                                                                                {product.quantity_to_sent > 0 && (
                                                                                                        <p style={{ background: 'gold' }}>{product.quantity_to_sent} No asignado</p>
                                                                                                )}
                                                                                                {product.in_transit > 0 && (
                                                                                                        <>
                                                                                                                <p style={{ background: 'orange' }}>{product.in_transit} En transito  {product.id_deliveryman !== authenticated.user[0].id_user && <b style={{ color: 'red' }}>reasignado</b>}</p>
                                                                                                        </>
                                                                                                )}
                                                                                                {product.delivered > 0 && (
                                                                                                        <p style={{ background: 'green' }}>{product.delivered} Entregado</p>
                                                                                                )}
                                                                                        </>
                                                                                ) : (
                                                                                        <p style={{ background: 'green', color: '#FFFFFF' }}>Todos Entregados</p>
                                                                                )}
                                                                        </td>
                                                                        <td>
                                                                                {product.in_transit > 0 && (
                                                                                        <>
                                                                                                <button type='button' onClick={() => activateModalComments({ ...product, status: 'Success', title: 'Comentarios de entrega' })}>Entregar</button>
                                                                                                <button type='button' onClick={() => activateModalComments({ ...product, status: 'Error', title: 'Error de entrega' })}>Tengo un problema</button>
                                                                                        </>
                                                                                )}
                                                                        </td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                        </table>
                                </div>
                        </div>
                        {activeCancelOrder && <ActionFinalDelivery
                                exportItem={exportItem} />}
                </ContainerPages>
        )
}

export default DeliveryId
import React, { useContext  } from 'react'
import ContainerPages from '../../components/containerPages'
import LoginContext from '../../contexts/login'
import { useProducts } from '../../hooks/useProducts'
import { Title } from '../../styles/clients'
import TableProduct from './components/tableProduct'

const Products = () => {

        const { permissions } = useContext(LoginContext)
        const { totalProducts } = useProducts()

        return (
                <ContainerPages>
                        <Title>
                                <h3>{totalProducts ?? 0} PRODUCTO(S)</h3>
                                <button type="button" onClick={() => permissions('/products/add', 'submenu')}>
                                        <p>AGREGAR PRODUCTO</p>
                                </button>
                        </Title>
                        <TableProduct />
                </ContainerPages>
        )

}

export default React.memo(Products)
import { useContext, useEffect, useState } from 'react'
import LoginContext from '../contexts/login'
import { GET, RANGE } from '../services/get'
import { currentTime } from '../services/globals'

export const useFetch = () => {

    const { authenticated } = useContext(LoginContext)
    const [totalProduct, setTotalProduct] = useState(null)
    const [totalOrderNow, setTotalOrderNow] = useState(null)
    const [getDeliveryManActive, setGetDeliveryManActive] = useState(null)
    const [dateInitial, setDateInitial] = useState(currentTime)
    const [dateFinal, setDateFinal] = useState(currentTime)
    const [ordersStore, setOrdersStore] = useState(null)
    const [lastProduct, setLastProduct] = useState(null)
    const [orderCompleted, setOrderCompleted] = useState(null)
    const [lastOrders, setLastOrders] = useState(null)

    useEffect(() => {
        Promise.allSettled([
            GET({ table: 'users', linkTo: 'linkTo=state_user', search: 'search=1' }),
            RANGE({ table: 'orders', linkTo: 'linkTo=date_created_order', rangeInitial: dateInitial, rangeFinal: dateFinal }),
            GET({ table: 'products', orderBy: 'orderBy=id', orderMode: 'orderMode=DESC' }),
            GET({ table: 'orders', linkTo: 'linkTo=acept_delivery_order', search: 'equalTo=1', orderBy: 'orderBy=id_order', orderMode: 'orderMode=DESC' }),
            GET({ table: 'orders', linkTo: 'linkTo=acept_delivery_order', search: 'equalTo=1', startAt: 'startAt=0', endAt: 'endAt=3', orderBy: 'orderBy=id_order', orderMode: 'orderMode=DESC' }),
        ]).then((values) => {
            const [
                { value: deliverymanActive },
                { value: ordersNow },
                { value: products },
                { value: orders },
                { value: lastOrders },
            ] = values

            if (deliverymanActive.status === 200) {
                let rol = authenticated !== null && authenticated.rol_user
                if (rol === 'Root' || rol === 'Administrador' || rol === 'Control de Bodega') {
                    setGetDeliveryManActive(deliverymanActive.results)
                } else {
                    setGetDeliveryManActive(deliverymanActive.results.filter(el => el.rol_user === 'Repartidor'))
                }
            } else {
                setGetDeliveryManActive(0)
            }
            if (ordersNow.status === 200) {
                setTotalOrderNow(ordersNow.total)
            } else {
                setTotalOrderNow(0)
            }

            if (products.status === 200) {
                setLastProduct(products.results.slice(0, 3))
            }

            if (orders.status === 200) {
                setOrderCompleted(orders.total)
            } else {
                setOrderCompleted(0)
            }

            if (lastOrders.status === 200) {
                setLastOrders(lastOrders.results)
            } else {
                setLastOrders(0)
            }

        })
    }, [authenticated === null])

    return {
        totalProduct,
        totalOrderNow,
        getDeliveryManActive,
        ordersStore,
        setOrdersStore,
        setTotalProduct,
        lastProduct,
        orderCompleted,
        lastOrders,
    }
}

import React from 'react'
import ToListOrder from '../components/buy_orders/toListOrder'
import ContainerPages from '../components/containerPages'
import { useBuyOrder } from '../hooks/useBuyOrder'
import Filters from '../components/buy_orders/filters'
import styles from '../styles/orders.module.css'

const Orders = () => {

        const { getOrders, totalOrders, permissions, getFilterOrders, initialFilters, download,deleteOrder } = useBuyOrder()

        return (
                <ContainerPages>
                        <div className={styles.container_title}>
                                <h3>{totalOrders ?? 0} PEDIDOS HOY</h3>
                                <button type="button" onClick={() => permissions('/orders/add', 'submenu')}>
                                        <p>AGREGAR</p>
                                </button>
                        </div>
                        <Filters
                                getFilterOrders={getFilterOrders}
                                initialFilters={initialFilters}
                                download={download}
                        />
                        <ToListOrder
                                deleteOrder={deleteOrder}
                                getOrders={getOrders}
                                permissions={permissions}
                        />
                </ContainerPages>
        )
}

export default Orders
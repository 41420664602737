import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ContainerPages from '../containerPages'
import styles from '../../styles/updateProduct.module.css'
import { useProducts } from '../../hooks/useProducts'
import { useState } from 'react'
import { API } from '../../services/globals'

const Update = () => {

        const { id } = useParams()
        const { updateProduct, searchProduct, product } = useProducts()
        const [imgProduct, setImgProduct] = useState('')

        useEffect(() => {
                searchProduct('id_product', id)
                if (product) {
                        setImgProduct(product[0].image_product)
                }
        }, [id, product == null])

        const viewImg = (e) => {
                const image = e.target.files[0];
                setImgProduct(URL.createObjectURL(image));
        }


        if (!product && imgProduct == "") return

        return (
                <ContainerPages>
                        <div className={styles.container_add}>
                                <form onSubmit={updateProduct}>
                                        <h3>ACTUALIZACIÓN PRODUCTO</h3>
                                        <span>

                                                {imgProduct !== null && <img src={`${API}/uploads/products/${imgProduct}`} alt="Imagen del producto" />}
                                        </span>
                                        <input type="file" name="image_product" onChange={viewImg} />
                                        <input type="hidden" name="id_product" defaultValue={product[0].id_product} />
                                        <label>Nombre</label>
                                        <input type="text" name="name_product" defaultValue={product[0].name_product} required />
                                        <label>Descripción</label>
                                        <input name="description_product" defaultValue={product[0].description_product} required />
                                        <label>Proveedor</label>
                                        <input name="vendor_product" defaultValue={product[0].vendor_product} />
                                        <label>Precio</label>
                                        <input type="number" name="price_product" defaultValue={product[0].price_product} required />
                                        <label htmlFor='company_price'>Precio Empresa</label>
                                        <input type="number" name="company_price" id='company_price' defaultValue={product[0].company_price} required />
                                        <label htmlFor='distributor_price'>Precio Distribuidor</label>
                                        <input type='number' name='distributor_price' id='distributor_price' defaultValue={product[0].distributor_price} required />
                                        <label>Stock</label>
                                        <input type="number" name="stock_product" defaultValue={product[0].stock_product} required />
                                        <label>Tipo de producto</label>
                                        <select defaultValue={product[0].is_internal} id='is_internal' name='is_internal'>
                                                <option value="0">Para venta</option>
                                                <option value="1">producto interno</option>
                                        </select>
                                        <span>
                                                <label htmlFor='is_active_product'>¿Esta activo?</label>
                                                {product[0].is_active_product == 1 ? (
                                                        <input type='checkbox' name='is_active_product' id='is_active_product' defaultChecked />
                                                ) : (
                                                        <input type='checkbox' name='is_active_product' id='is_active_product' />
                                                )}
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type="button">Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default Update
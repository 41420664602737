import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalVoucher from '../components/buy_orders/modalVoucher'
import ContainerPages from '../components/containerPages'
import LoginContext from '../contexts/login'
import OrderContext from '../contexts/orders'
import { API_FRONT, chileanCurrency, methodPayment } from '../services/globals'
import { PUT_LOC } from '../services/put'
import styles from '../styles/orderDetails.module.css'
import { useBuyOrder } from '../hooks/useBuyOrder'

const OrderDetails = () => {

        const { id } = useParams()
        const { navigation, setToast, activeToast } = useContext(LoginContext)
        const { getDeliveryMan, handleAssignDeliveryMan, changeStatusPayment, getDetailsOrder, detailsOrder } = useContext(OrderContext)
        const {activeSendMail} = useBuyOrder()

        const [activeImgVocher, setActiveImgVocher] = useState(false)

        useEffect(() => {
                getDetailsOrder(id)
        }, [])

        const showModalVoucher = () => {
                setActiveImgVocher(!activeImgVocher)
        }

        if (detailsOrder === null) return;

        const updateDetailShipping = (e) => {
                e.preventDefault()
                const data = Object.fromEntries(new FormData(e.target))
                if (data.quantity_to_sent == '' || data.quantity_to_sent == '0') {
                        return;
                }

                let update = new URLSearchParams(data)
                update.append('id', data.id)
                update.append('quantity_to_sent', data.quantity_to_sent)

                PUT_LOC({
                        table: 'cart_detail?active_send=true',
                        data: update,
                })
                        .then(res => {
                                if (res.status === 200) {
                                        setToast({
                                                message: res.message,
                                                error: false,
                                                time: 2000,
                                        })
                                        activeToast()
                                        getDetailsOrder(id)
                                        return
                                }
                                if (res.status === 400) {
                                        setToast({
                                                message: res.message,
                                                error: true,
                                                time: 2000,
                                        })
                                        activeToast()
                                        return
                                } else {
                                        setToast({
                                                message: 'Error al actualizar',
                                                error: true,
                                                time: 2000,
                                        })
                                        activeToast()
                                        return
                                }
                        })
        }

        return (
                <ContainerPages>
                        <div className={styles.box_back}>
                                <button onClick={() => navigation('/orders', { replace: true })}>Volver</button>
                        </div>
                        <div className={styles.box_details_order}>
                                <h3>Detalles del pedido N°{id}</h3>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>Nombre cliente</th>
                                                        <th>Rut</th>
                                                        <th>Telefono</th>
                                                        <th>Direccion</th>
                                                        <th>Estado del pago</th>
                                                        <th>Productos totales</th>
                                                        <th>Total de la compra</th>
                                                        <th>Comentarios de envio</th>
                                                        <th>Comprobante de compra</th>
                                                        <th>Repartidor asignado</th>
                                                        <th>Fecha y hora del pedido</th>
                                                        <th>Enviar correo de compra</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {detailsOrder.order.map((order, index) => (
                                                        <tr key={index}>
                                                                <td>{order.name_client}</td>
                                                                <td>{order.rut_client}</td>
                                                                <td>{order.phone_client}</td>
                                                                <td>{order.direction_client}</td>
                                                                <td>
                                                                        <form onSubmit={changeStatusPayment}>
                                                                                <input type='hidden' name='id_order' defaultValue={order.id} />
                                                                                <select name='payment_accepted' defaultValue={order.payment_accepted}>
                                                                                        {methodPayment.map((payment, index) => (
                                                                                                <option key={payment.id} value={payment.id}>{payment.name}</option>
                                                                                        ))}
                                                                                </select>
                                                                                <button type='submit'>Cambiar</button>
                                                                        </form>
                                                                </td>
                                                                <td>{order.quantity_total}</td>
                                                                <td>$ {chileanCurrency(order.total_to_pay)}</td>
                                                                <td>{order.additional_comments}</td>
                                                                <td>{
                                                                        order.voucher_image === null
                                                                                ? 'no se ha agregado'
                                                                                :
                                                                                <>
                                                                                        <button type='button' onClick={() => showModalVoucher(order.voucher_image)}>Ver</button>
                                                                                        <button type='button'><a href={`${API_FRONT}/uploads/voucher/${order.voucher_image}`} target='_BLANK'>Descargar</a></button>
                                                                                </>
                                                                }
                                                                        <AnimatePresence>
                                                                                {activeImgVocher && (
                                                                                        <ModalVoucher voucher={order.voucher_image} showModalVoucher={showModalVoucher} />
                                                                                )}
                                                                        </AnimatePresence>
                                                                </td>
                                                                <td>
                                                                        <form onSubmit={handleAssignDeliveryMan}>
                                                                                <input type='hidden' name='id_buy_order' defaultValue={order.id} />
                                                                                <select name='id_deliveryman'>
                                                                                        {getDeliveryMan === null ? (
                                                                                                <option value=''>Cargando...</option>
                                                                                        ) : (
                                                                                                getDeliveryMan === 0 ? (
                                                                                                        <option value='0'>No hay repartidores</option>
                                                                                                ) : (
                                                                                                        <>
                                                                                                                <option value='0'>Sin Repartidor</option>
                                                                                                                {getDeliveryMan.map((delivery, index) => (
                                                                                                                        detailsOrder.products[0].id_deliveryman !== null && detailsOrder.products[0].id_deliveryman === delivery.id_user ? (
                                                                                                                                <option key={index} value={delivery.id_user} selected>{delivery.name_user}</option>
                                                                                                                        ) : (
                                                                                                                                <option key={index} value={delivery.id_user}>{delivery.name_user}</option>
                                                                                                                        )
                                                                                                                ))}
                                                                                                        </>
                                                                                                )
                                                                                        )}
                                                                                </select>
                                                                                <button type='submit'>Asignar pedido</button>
                                                                        </form>
                                                                </td>
                                                                <td>{order.creation_date}</td>
                                                                <td>
                                                                        <button type='button' onClick={() => activeSendMail(id)}>Enviar</button>
                                                                </td>
                                                        </tr>
                                                ))}
                                        </tbody>
                                </table>
                        </div>
                        <div className={styles.box_detail_product_order}>
                                <h3>Productos del pedido</h3>
                                <div>
                                        <table>
                                                <thead>
                                                        <tr>
                                                                <th>N°</th>
                                                                <th>Nombre Producto</th>
                                                                <th>Cantidad</th>
                                                                <th>Detalles de envio</th>
                                                                <th>Acciones</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {detailsOrder.products.map((product, index) => (
                                                                <tr key={index}>
                                                                        <td>{product.id}</td>
                                                                        <td>{product.name_product}</td>
                                                                        <td>{product.quantity}</td>
                                                                        <td>
                                                                                {product.quantity != product.delivered ? (
                                                                                        <>
                                                                                                {product.quantity_to_sent > 0 && (
                                                                                                        <p style={{ background: 'red' }}>{product.quantity_to_sent} Para asignar</p>
                                                                                                )}
                                                                                                {product.in_transit > 0 && (
                                                                                                        <p style={{ background: 'orange' }}>{product.in_transit} En transito</p>
                                                                                                )}
                                                                                                {product.delivered > 0 && (
                                                                                                        <p style={{ background: 'green' }}>{product.delivered} Entregado</p>
                                                                                                )}
                                                                                        </>
                                                                                ) : (
                                                                                        <p style={{ background: 'green' }}>Todos Entregados</p>
                                                                                )}
                                                                                {product.quantity_to_sent == 0 && product.in_transit == 0 && product.delivered == 0 && (
                                                                                        <p style={{ background: 'orangered' }}>Sin acciones</p>
                                                                                )}
                                                                        </td>
                                                                        <td>
                                                                                {product.quantity_to_sent == 0 && product.in_transit == 0 && product.delivered == 0 && (
                                                                                        <form onSubmit={updateDetailShipping}>
                                                                                                <input type='hidden' name='id' value={product.id} />
                                                                                                <input style={{ width: '40px' }} type='number' min={1} max={product.quantity} name='quantity_to_sent' />
                                                                                                <button type='submit' title='permite asignar el envio del producto a algun repartidor'>Permitir </button>
                                                                                        </form>
                                                                                )}
                                                                        </td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                                <tfoot>
                                                        <tr>
                                                                <td colSpan='4'>Total</td>
                                                                <td colSpan='1'>$ {chileanCurrency(detailsOrder.order[0].total_to_pay)}</td>
                                                        </tr>
                                                </tfoot>
                                        </table>
                                </div>
                        </div>

                </ContainerPages>
        )
}

export default OrderDetails
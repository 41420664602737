import React from 'react'
import Delete from '../../../img/svg/delete'
import Edit from '../../../img/svg/edit'
import { ContainerTable, DataTable } from '../../../styles/clients'

const Table = ({ getAllClients, deleteClient, navigation, permissions }) => {

        return (
                <ContainerTable>
                        <DataTable>
                                <table>
                                        <thead>
                                                <tr>
                                                        <th>N° Cliente</th>
                                                        <th>Nombre</th>
                                                        <th>Rut</th>
                                                        <th>Email</th>
                                                        <th>Telefono</th>
                                                        <th>Dirección</th>
                                                        <th>Referencia</th>
                                                        <th>Estado cliente</th>
                                                        <th>Fecha de creación</th>
                                                        <th>Ultima actualización</th>
                                                        <th>Acciones</th>
                                                </tr>
                                        </thead>
                                        {getAllClients === null ? (
                                                <tbody>
                                                        <tr>
                                                                <th>Cargando...</th>
                                                        </tr>
                                                </tbody>
                                        ) : (
                                                getAllClients !== 0 ? (
                                                        <tbody >
                                                                {getAllClients.map((item, index) => (
                                                                        <tr key={item.id_client}>
                                                                                <td>{item.id_client}</td>
                                                                                <td>{item.name_client}</td>
                                                                                <td>{item.rut_client}</td>
                                                                                <td>{item.email_client}</td>
                                                                                <td>{item.phone_client}</td>
                                                                                <td>{item.direction_client}</td>
                                                                                <td>{item.reference_client}</td>
                                                                                <td>
                                                                                        {item.state_client == 0 ? (
                                                                                                <p style={{color:'white',background:'red',padding:'3px 10px',borderRadius:'5px'}}>Pendiente</p>
                                                                                        ) : (
                                                                                                item.state_client == 1 ? (
                                                                                                        <p style={{color:'white',background:'green',padding:'3px 10px',borderRadius:'5px'}}>Activo</p>
                                                                                                ) : (
                                                                                                        <p style={{color:'white',background:'orange',padding:'3px 10px',borderRadius:'5px'}}>Rechazado</p>
                                                                                                )
                                                                                        )}
                                                                                </td>
                                                                                <td>{item.date_created_client}</td>
                                                                                <td>{item.date_update_client}</td>
                                                                                <td>
                                                                                        <button type="button" onClick={() => permissions(`/clients/update/${item.id_client}`, 'submenu')}>
                                                                                                <Edit />
                                                                                        </button>
                                                                                        <button type="button" onClick={() => deleteClient(item.id_client, item.email_client)}>
                                                                                                <Delete />
                                                                                        </button>
                                                                                </td>
                                                                        </tr>
                                                                ))}
                                                        </tbody>
                                                ) : (
                                                        <tbody>
                                                                <tr>
                                                                        <th>No se</th>
                                                                        <th>encontraron datos</th>
                                                                </tr>
                                                        </tbody>
                                                )
                                        )}
                                </table>
                        </DataTable>
                </ContainerTable>
        )
}

export default Table
import React, { useEffect } from 'react'
import ContainerPages from '../../components/containerPages'
import { Title } from '../../styles/administrators'
import Filters from './components/filters'
import Table from './components/table'
import { useWorkers } from '../../hooks/useWorkers'

const Administrator = () => {

        const { getAllWorkers, deleteWorker, permissions, stateAdministrator, totalWorkers, searching,downloadData } = useWorkers()

        return (
                <ContainerPages>
                        <Title>
                                <h3>{totalWorkers ?? 0} TRABAJADOR(ES)</h3>
                                <button type="button" onClick={() => permissions('/workers/add', 'submenu')}>
                                        <p>AGREGAR</p>
                                </button>
                        </Title>
                        <Filters
                                searching={searching}
                                downloadData={downloadData}
                        />
                        <Table
                                getAllWorkers={getAllWorkers}
                                deleteWorker={deleteWorker}
                                stateAdministrator={stateAdministrator}
                                permissions={permissions} />
                </ContainerPages>
        )
}

export default Administrator
import styled from "styled-components";
import { motion } from "framer-motion"

export const Horizontal = styled.div`
    position: fixed;
    display:flex;
    height:40px;
    width: ${({ activeMenu }) => activeMenu ? '100%' : 'calc(100% - 150px)'};
    right: 0;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    align-items:center;
    justify-content: space-between;
    transition: width 0.2s ease;
    z-index: 1000;
    top:0;
    background-color: var(--secundaryBackground);

    .box_user{
            position: relative;
            display: flex;
            column-gap: 10px;
            align-items:center;
            justify-content:space-around;
            cursor:pointer;
            max-width: 200px;
            height:40px;
            background-color:var(--secundaryBackground);
            padding: 0 10px;


        span:nth-child(1){
            display: flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content:center;
            border-radius: 50%;
            border: 1px solid var(--primaryColor);
         
            p{
                font-weight: bold;
                font-size: 1.3em;
            }
        }
        .box_hover_arrow{
            position: relative;
            display: flex;
            width: 30px;
            height: 30px;
            align-items:center;
            transition: transform .3s ease;
            border-radius: 50%;
            cursor: pointer;
            //transform: rotate(90deg);

            &::before,
            &::after{
                position: absolute;
                content: '';
                height: 10px;
                width: 2px;
                background-color:var(--primaryColor);
            }
            &::before{
                transform: rotate(-45deg);
                left: 11px;
            }
            
            &::after{
                transform: rotate(45deg);
                right: 11px;
            }
        }
        span:nth-child(4){
            position: absolute;
            display: flex;
            width: 195px;
            height:auto;
            flex-direction: column;
            top:40px;
            right:0;
            z-index: -2;
            transform: translateY(-233px);
            opacity: 0;
            transition: transform .3s ease, opacity .3s ease;
            box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
            background: var(--secundaryBackground);
            
            .box_hover_data{
                display:flex;
                width: 100%;
                height: auto;
                align-items:center;
                justify-content: center;
                padding: 20px;
                flex-direction: column;

                span{
                    display: flex;
                    width: 70px;
                    height: 70px;
                    align-items:center;
                    justify-content: center;
                    margin-bottom: 20px;
                    p{
                        font-size: 2em;
                    }
                }
                p{
                    font-size: .8em;
                }
            }
            .box_hover_button{
                display:flex;
                width: 100%;
                height: auto;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                padding: 10px 0;

                button{
                    width: 80px;
                    padding: 5px 0;
                    cursor: pointer;
                }
            }
        }
        &:hover .box_hover_arrow{
            transform: rotate(180deg);
        }
        &:hover span:nth-child(4){
            transform: translateY(0);
            opacity: 1;
        }
    }



`;

export const Vertical = styled(motion.div)`
    position: fixed;
    display:flex;
    width: 150px;
    height:100vh;
    max-height: 100vh;
    flex-direction: column;
    background: var(--primaryColor);
    z-index:1000;
    top: 0;

    span{
        display: flex;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: .9em;
        

        h3{
            color:var(--secundaryBackground);
            font-weight: 400;
        }
    }

    header{
        display: flex;
        height:100%;
        align-items: stretch;
        padding: 20px 0;
        overflow-y:scroll;
        overflow-x:hidden;
        &::-webkit-scrollbar{
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--secundaryBackground);
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track{
            background-color: var(--primaryColor);
            border-radius: 5px;
        }
        nav{
            height: 100%;

            ul{
                width:150px;
                height:auto;
                transition: all 0.2s ease;

            a{
                position: relative;
                display: flex;
                width: calc(100% - 20px);
                min-height: 40px;
                border-radius: 5px;
                align-items: center;
                text-decoration: none;
                gap: 10px;
                background-color:transparent;
                transition:background-color 0.2s ease;
                font-size: .9em;
                padding: 0 10px;
                margin:0 auto;
                overflow: hidden;
                
                h4{
                        color: var(--secundaryBackground);
                        transition:color 0.3s ease;
                        font-weight:400;
                        white-space: nowrap;
            }
    }

    .is-active{
        background-color:var(--secundaryBackground);

        h4{
            color: var(--primaryColor);
        }

    }
            }
        }
    }
`;

import React, { useState, useEffect, useContext } from 'react'
import { POST_LOC } from '../services/post'
import LoginContext from '../contexts/login'
import { GET_LOC } from '../services/get'
import { DELETE_LOC } from '../services/delete'
import { dateTime, specialPermission } from '../services/globals'

const useCaps = () => {

        const { setToast, activeToast, authenticated, permissions, navigation } = useContext(LoginContext)
        const [caps, setCaps] = useState(null)
        const { currentTime, currentDay } = dateTime()
        const [oneCap, setOneCap] = useState(null)
        const [saveDataFilter, setSaveDataFilter] = useState(null)
        const inititalFilters = {
                start_date: currentDay,
                end_date: currentTime,
                order_mode: 'ASC',
                order_by: 'id',
                search: ''
        }

        const createCaps = (e) => {
                e.preventDefault()

                let data = new FormData(e.target)

                if (data.get('id_user') === '0') {
                        setToast({
                                message: 'Debe seleccionar un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }
                data.append('id_created_by', authenticated.user[0].id_user)

                POST_LOC({
                        table: 'caps',
                        data: data
                }).then(response => {
                        if (response.status === 200) {

                                setToast({
                                        message: 'Registro creado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/caps', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, no se pudo crear el registro, intente nuevamente',
                                        error: true,
                                        time: 5000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        const getAtCap = (id) => {
                GET_LOC({
                        table: 'caps',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setOneCap(response.results)
                        } else {
                                setOneCap(0)
                        }
                })
        }

        const filterCaps = (e) => {
                e.preventDefault();

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                let data = Object.fromEntries(new FormData(e.target))
                data.order_mode = data.order_mode === 'on' ? 'DESC' : 'ASC'
                setSaveDataFilter(data)
                getAllCaps(data)
        }

        const getAllCaps = (filters) => {

                const { start_date, end_date, order_mode, order_by, search } = filters

                GET_LOC({
                        table: 'caps',
                        params: `?action=list&start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`
                }).then(response => {
                        if (response.status === 200) {
                                setCaps(response)
                        } else {
                                setCaps(0)
                        }
                })
        }

        const deleteCap = (id, user) => {


                let isDelete = window.confirm(`¿Está seguro de eliminar este registro del usuario ${user}?`)

                if (!isDelete) return

                DELETE_LOC({
                        table: 'caps',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                let newCaps = caps.filter(item => item.id !== id)
                                setCaps(newCaps)
                                setToast({
                                        message: 'Registro eliminado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                        } else {
                                setToast({
                                        message: "Error, no se pudo eliminar el registro, intente nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                        }
                })

        }

        const updateCap = (e) => {
                e.preventDefault()

                let data = new FormData(e.target)
                POST_LOC({
                        table: 'caps?action=update',
                        data: data
                }).then(response => {
                        if (response.status == 200) {
                                setToast({
                                        message: 'Registro actualizado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/caps', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error, no se pudo actualizar el registro, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }

                })
        }

        useEffect(() => {
                getAllCaps(inititalFilters)
        }, [])

        return {
                createCaps,
                getAllCaps,
                caps,
                setCaps,
                deleteCap,
                permissions,
                updateCap,
                oneCap,
                getAtCap,
                inititalFilters,
                filterCaps
        }
}

export default useCaps
import React from 'react'
import ContainerPages from '../containerPages'
import { useProducts } from '../../hooks/useProducts'
import { useWorkers } from '../../hooks/useWorkers'
import { typeAssignments } from '../../services/globals'
import styles from '../../styles/assignments.module.css'
import { useAssignment } from '../../hooks/useAssignment'

const AddAssignment = () => {

        const { getAllProducts } = useProducts()

        const { getAllWorkers } = useWorkers()
        const { addAssignment } = useAssignment()
        // , , assigned_user, id_assigned_user, additional_comments, assignment_type, dispatcher_id, dispatcher_name, 
        return (
                <ContainerPages>
                        <div className={styles.container_assignments}>
                                <form onSubmit={addAssignment}>
                                        <h3>NUEVA ASIGNACIÓN</h3>
                                        <span>
                                                <label>Producto</label>
                                                <select name='id_product'>
                                                        {getAllProducts === 0 ? (
                                                                <option value=''>No hay productos</option>
                                                        ) : (
                                                                getAllProducts === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione un producto</option>
                                                                                {getAllProducts.map((product, index) => (
                                                                                        <option key={product.id_product} value={product.id_product}>{product.name_product}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label>Cantidad</label>
                                                <input type='number' name='assigned_quantity' min={1} defaultValue={1} />
                                        </span>
                                        <span>
                                                <label>Trabajador asignado</label>
                                                <select name='id_assigned_user'>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay trabajadores</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione un trabajador</option>
                                                                                {getAllWorkers.map((worker, index) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </span>
                                        <span>
                                                <label>Tipo de Asignación</label>
                                                <select name='assignment_type' defaultValue='1' required>
                                                        {typeAssignments.map((type, index) => (
                                                                <option key={type.id} value={type.id}>{type.name}</option>
                                                        ))}
                                                </select>
                                        </span>
                                        <span>
                                                <label>Comentarios adicionales</label>
                                                <textarea name='additional_comments'/>
                                        </span>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddAssignment
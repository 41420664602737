import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import ContainerPages from '../components/containerPages'
import styles from '../styles/addOrder.module.css'
import Search from '../img/svg/search'
import ListProducts from '../components/buy_orders/listProducts'
import ProductsAssociated from '../components/buy_orders/productsAssociated'
import ClientAssociated from '../components/buy_orders/clientAssociated'
import { useBuyOrder } from '../hooks/useBuyOrder'

const AddOrder = () => {

        const { searchClients, permissions, activeTable, clientSelected, formClient, productSelected, addNewOrder, products, addProductToCart, dataClient, dataClientSelected, setProductSelected } = useBuyOrder()

        return (
                <ContainerPages>
                        <div className={styles.container_add_order}>
                                <h3>AGREGAR NUEVO PEDIDO</h3>
                                <div className={styles.box_search_client}>
                                        <form onSubmit={searchClients}>
                                                <input type='search' name="search_client" placeholder='Busca aqui un cliente por telefono,nombre,apellido o email' />
                                                <button type='submit'>
                                                        <Search />
                                                </button>
                                        </form>
                                        <button type='button' onClick={() => permissions('/clients/add', 'submenu')}>
                                                <p>Crear nuevo cliente</p>
                                        </button>
                                </div>
                                <AnimatePresence>
                                        {
                                                activeTable && (
                                                        <motion.div
                                                                className={styles.customers_found_table}
                                                                initial={{ opacity: 0 }}
                                                                animate={{ opacity: 1 }}
                                                                exit={{ opacity: 0 }}
                                                        >
                                                                <table>
                                                                        <thead>
                                                                                <tr>
                                                                                        <th>Nombre</th>
                                                                                        <th>Rut</th>
                                                                                        <th>Email</th>
                                                                                        <th>Teléfono</th>
                                                                                        <th>Dirección</th>
                                                                                        <th>Comentarios adicionales</th>
                                                                                        <th>Acciones</th>
                                                                                </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                                {dataClient?.map((item, index) => (
                                                                                        <tr key={index}>
                                                                                                <td>{item.name_client}</td>
                                                                                                <td>{item.rut_client}</td>
                                                                                                <td>{item.email_client}</td>
                                                                                                <td>{item.phone_client}</td>
                                                                                                <td>{item.direction_client}</td>
                                                                                                <td>{item.reference_client}</td>
                                                                                                <td>
                                                                                                        <button type='button' onClick={() => dataClientSelected(item)}>
                                                                                                                <p>Seleccionar</p>
                                                                                                        </button>
                                                                                                </td>
                                                                                        </tr>
                                                                                ))}
                                                                        </tbody>
                                                                </table>
                                                        </motion.div>
                                                )
                                        }
                                </AnimatePresence>
                                {clientSelected && (
                                        <ClientAssociated
                                                clientSelected={clientSelected}
                                                formClient={formClient} />
                                )}
                                <ProductsAssociated
                                        productSelected={productSelected}
                                        setProductSelected={setProductSelected}
                                        clientSelected={clientSelected}
                                        addNewOrder={addNewOrder} />
                                <ListProducts products={products} addProductToCart={addProductToCart} />
                        </div>
                </ContainerPages>
        )
}

export default AddOrder
import React from 'react'
import styles from '../../../styles/control.module.css'
import Delete from '../../../img/svg/delete'

const TableControl = ({ getAllControl, deleteControl, permissions, signRegister, signSales }) => {

        const totals = () => {
                let tot = [0, 0, 0, 0, 0, "", "", 0, 0, 0, 0, 0, 0, 0, 0, "", 0, 0, 0, "", "", 0, 0, 0, 0,]
                getAllControl?.results?.map((item, index) => {
                        tot[0] += parseInt(item.exit_20L_drumcontrol)
                        tot[1] += parseInt(item.exit_10L_returnable_drumcontrol)
                        tot[2] += parseInt(item.exit_10L_disposable_drumcontrol)
                        tot[3] += parseInt(item.exit_cube_drumcontrol)
                        tot[4] += parseInt(item.exit_frape_drumcontrol)
                        tot[7] += parseInt(item.arrival_20L_full_drumcontrol)
                        tot[8] += parseInt(item.arrival_20L_empty_drumcontrol)
                        tot[9] += parseInt(item.arrival_20L_taken_drumcontrol)
                        tot[10] += parseInt(item.arrival_20L_recovered_drumcontrol)
                        tot[11] += parseInt(item.arrival_10L_full_drumcontrol)
                        tot[12] += parseInt(item.arrival_10L_empty_drumcontrol)
                        tot[13] += parseInt(item.arrival_10L_taken_drumcontrol)
                        tot[14] += parseInt(item.arrival_10L_recovered_drumcontrol)

                        tot[16] += parseInt(item.sale_20L_drumcontrol)
                        tot[17] += parseInt(item.sale_10L_returnable_drumcontrol)
                        tot[18] += parseInt(item.sale_10L_disposable_drumcontrol)

                        tot[21] += parseInt(item.difference_20Lt_drumcontrol)
                        tot[22] += parseInt(item.difference_10Lt_returnable_drumcontrol)
                        tot[23] += parseInt(item.difference_10Lt_disposable_drumcontrol)
                        tot[24] += parseInt(item.difference_cube_drumcontrol)
                }
                )
                return tot
        }

        const finalTotals = totals()
        return (

                <div className={styles.container_control}>
                        <table>
                                <thead className="header-title">
                                        <tr>
                                                <th rowSpan='2'>N°</th>
                                                <th rowSpan='2'>VENDEDOR</th>
                                                <th colSpan='7'>SALIDA</th>
                                                <th colSpan='10'>LLEGADA</th>
                                                <th colSpan='5'>VENTAS</th>
                                                <th colSpan='4'>DIFERENCIA</th>
                                                <th rowSpan='2'>ACCIONES</th>
                                        </tr>
                                        <tr>
                                                <th>Hora</th>
                                                <th>20Lt</th>
                                                <th>10lt Retor</th>
                                                <th>10Lt Desech.</th>
                                                <th>Cubos</th>
                                                <th>Frape</th>
                                                <th>Firma</th>
                                                <th>Hora</th>
                                                <th>20L lleno</th>
                                                <th>20L vacio</th>
                                                <th>Prestado</th>
                                                <th>Recup.</th>
                                                <th>10L lleno</th>
                                                <th>10L vacio</th>
                                                <th>Prestado</th>
                                                <th>Recup</th>
                                                <th>Acciones</th>
                                                <th>20L</th>
                                                <th>10L Retor</th>
                                                <th>10L Desech.</th>
                                                <th>Firma</th>
                                                <th>Acciones</th>
                                                <th>20LTS</th>
                                                <th>10LTS Retor</th>
                                                <th>10LTS Desech</th>
                                                <th>Cubos</th>

                                        </tr>
                                </thead>
                                <tbody>
                                        {getAllControl === 0 ? (
                                                <tr>
                                                        <td colSpan='30'>No hay datos</td>
                                                </tr>
                                        ) : (
                                                getAllControl === null ? (
                                                        <tr>
                                                                <td colSpan='30'>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        getAllControl.results.map((item, index) => (
                                                                <tr key={item.id_drumcontrol}>
                                                                        <td>{item.id_drumcontrol}</td>
                                                                        <td>{item.name_deliveryman_drumcontrol}</td>
                                                                        <td>{item.date_created_drumcontrol.split(' ')[1]}</td>
                                                                        <td>{item.exit_20L_drumcontrol}</td>
                                                                        <td>{item.exit_10L_returnable_drumcontrol}</td>
                                                                        <td>{item.exit_10L_disposable_drumcontrol}</td>
                                                                        <td>{item.exit_cube_drumcontrol}</td>
                                                                        <td>{item.exit_frape_drumcontrol}</td>
                                                                        <td>
                                                                                {parseInt(item.firm_register_drumcontrol) === 1 ? (
                                                                                        'Firmado'
                                                                                ) : (
                                                                                        <button type='button' onClick={() => signRegister(item)}>FIRMAR</button>
                                                                                )}
                                                                        </td>
                                                                        <td>{item.date_arrival_drumcontrol}</td>
                                                                        <td>{item.arrival_20L_full_drumcontrol}</td>
                                                                        <td>{item.arrival_20L_empty_drumcontrol}</td>
                                                                        <td>{item.arrival_20L_taken_drumcontrol}</td>
                                                                        <td>{item.arrival_20L_recovered_drumcontrol}</td>
                                                                        <td>{item.arrival_10L_full_drumcontrol}</td>
                                                                        <td>{item.arrival_10L_empty_drumcontrol}</td>
                                                                        <td>{item.arrival_10L_taken_drumcontrol}</td>
                                                                        <td>{item.arrival_10L_recovered_drumcontrol}</td>
                                                                        <td>
                                                                                <button type='button' onClick={() => permissions(`/control/arrival/${item.id_drumcontrol}`, 'submenu')}>Agregar</button>
                                                                        </td>
                                                                        <td>{item.sale_20L_drumcontrol}</td>
                                                                        <td>{item.sale_10L_returnable_drumcontrol}</td>
                                                                        <td>{item.sale_10L_disposable_drumcontrol}</td>
                                                                        <td>
                                                                                {parseInt(item.firm_sales) === 1 ? (
                                                                                        'Firmado'
                                                                                ) : (
                                                                                        <button style={{background:'red'}} type='button' onClick={() => signSales(item)}>FIRMAR</button>
                                                                                )}
                                                                        </td>
                                                                        <td>
                                                                                <button type='button' onClick={() => permissions(`/control/sales/${item.id_drumcontrol}`, 'submenu')}>Agregar</button>
                                                                        </td>
                                                                        <td>{item.difference_20Lt_drumcontrol}</td>
                                                                        <td>{item.difference_10Lt_returnable_drumcontrol}</td>
                                                                        <td>{item.difference_10Lt_disposable_drumcontrol}</td>
                                                                        <td>{item.difference_cube_drumcontrol}</td>

                                                                        <td>
                                                                                <button type='button' onClick={() => deleteControl(item.id_drumcontrol, item.name_deliveryman_drumcontrol)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </td>
                                                                </tr>
                                                        ))
                                                )
                                        )}
                                </tbody>
                                <tfoot>
                                        <tr>
                                                <td colSpan='3'>Totales</td>
                                                {finalTotals.map((item, index) => (
                                                        <td key={index}>{item}</td>
                                                ))}

                                        </tr>
                                </tfoot>
                        </table>
                </div>
        )
}

export default TableControl
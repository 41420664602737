import React from 'react'
import styles from '../../../styles/assignments.module.css'
import Edit from '../../../img/svg/edit'
import Delete from '../../../img/svg/delete'

const TableAsignments = ({ myAsignments }) => {

        return (
                <div className={styles.container_list_asignments}>
                        <table>
                                <thead>
                                        <tr>
                                                <th>N°</th>
                                                <th>Nombre producto</th>
                                                <th>Cantidad</th>
                                                <th>Asignado a</th>
                                                <th>Asignado por</th>
                                                <th>Tipo asignación</th>
                                                <th>Comentarios adicionales</th>
                                                <th>Fecha de creación</th>
                                                <th>Ultima actualización</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {myAsignments === 0 ? (
                                                <tr>
                                                        <td colSpan='10'>No hay asignaciones</td>
                                                </tr>
                                        ) : (
                                                myAsignments === null ? (
                                                        <tr>
                                                                <td colSpan='10'>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        myAsignments.results.map((assignment, index) => (
                                                                <tr key={assignment.id}>
                                                                        <td>{assignment.id}</td>
                                                                        <td>{assignment.name_product}</td>
                                                                        <td>{assignment.assigned_quantity}</td>
                                                                        <td>{assignment.assigned_name_user}</td>
                                                                        <td>{assignment.dispatcher_name}</td>
                                                                        <td>{assignment.assignment_type === "1" ? "Entrada" : "Salida"}</td>
                                                                        <td>{assignment.additional_comments}</td>
                                                                        <td>{assignment.creation_date}</td>
                                                                        <td>{assignment.update_date}</td>
                                                                        <td>
                                                                                <button style={{ width: '80px', color: '#FFFFFF' }}>Aceptar</button>
                                                                                <button style={{ width: '80px', color: '#FFFFFF' }}>Rechazar</button>
                                                                        </td>
                                                                </tr>
                                                        ))
                                                )
                                        )}

                                </tbody>
                        </table>
                </div>
        )
}

export default TableAsignments
import React from 'react'
import ContainerPages from '../components/containerPages'
import useProduction from '../hooks/useProduction'
import styles from '../styles/production.module.css'
import { useEffect } from 'react'
import ListProduction from '../components/production/listProduction'
import Filter from '../components/production/filter'
import { dateTime } from '../services/globals'

const Production = () => {

        const { production, getAllProduction, getFiltersProduction, permissions, download, deleteProduction } = useProduction()
        const { currentDay, currentTime } = dateTime()

        useEffect(() => {
                getAllProduction(currentDay, currentTime, 'all')
        }, [])

        return (
                <ContainerPages>
                        <div className={styles.container_production}>
                                <div className={styles.container_title}>
                                        <p>Movimiento producción</p>
                                        <button type='button' onClick={() => permissions('/production/add', 'submenu')}>Agregar</button>
                                </div>
                                <Filter
                                        production={production}
                                        getFiltersProduction={getFiltersProduction}
                                        download={download}
                                />
                                <ListProduction
                                        permissions={permissions}
                                        production={production}
                                        deleteProduction={deleteProduction}
                                />
                        </div>
                </ContainerPages>
        )
}

export default Production
import styled from "styled-components";

export const BoxTitle = styled.div`

    display: flex;
    width: 100%;
    padding:20px;
    h3{
        text-transform: uppercase;
    }

`;

export const ContainerTable = styled.div`

width: 100%;
    height: auto;
    overflow-y: scroll;

table{
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 1000px;

    thead {
    display:flex;
    position: relative;
    background-color: var(--primaryColor);
    color: var(--secundaryBackground);
    
        tr {
        display: grid;
        place-content: center;
        width: 100%;
        height: 40px;
        grid-template-columns: 15% 20% 10% 15% 15% 10% 15%;
        }
    
    }
    tbody{
        height: auto;
    
    &:nth-child(odd){
        background-color: var(--secundaryBackground);
    }
        tr{
            display: grid;
            width: 100%;
            min-height: 40px;
            grid-template-columns:15% 20% 10% 15% 15% 10% 15%;
    
            th{
                display: flex;
                height: 100%;
                
                font-weight: normal;
                gap: 10px;
                align-items: center;
                padding:0 20px;
    
                button{
                    display: grid;
                    width: 35px;
                    height: 35px;
                    border: 1px solid rgba(0,0,0, 0.2);
                    border-radius: 5px;
                    cursor: pointer;
                    place-content: center;
    
                    svg{
                        width: 1.2em;
                        height: 1.2em;
                    }
                }
                button:nth-child(1){
                    width: 100px;
                    font-weight: bold;

                }
                button:nth-child(2){
                    svg{
                        fill:var(--secundaryColor);
                    }

                }
                button:nth-child(3){
                    svg{
                        fill:red;
                    }
                }
            }
        }
    
    }
    
}


`;
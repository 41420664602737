import React from 'react'
import styles from '../../styles/caps.module.css'
import Delete from '../../img/svg/delete'
import Edit from '../../img/svg/edit'

const ListCaps = ({ caps, deleteCap, permissions }) => {

        return (
                <div className={styles.container_table_caps}>
                        <table>
                                <thead>
                                        <tr>
                                                <th rowSpan='2'>N°</th>
                                                <th rowSpan='2'>Persona que recibe</th>
                                                <th colSpan='3'>Tapas entregadas</th>
                                                <th colSpan='3'>Tapas devueltas</th>
                                                <th colSpan='3'>Diferencia</th>
                                                <th rowSpan='2'>Fecha de creación</th>
                                                <th rowSpan='2'>Acciones</th>
                                        </tr>
                                        <tr>
                                                <th>20LTS RET</th>
                                                <th>10LTS RET</th>
                                                <th>10LTS DESC</th>
                                                <th>20LTS RET</th>
                                                <th>10LTS RET</th>
                                                <th>10LTS DESC</th>
                                                <th>20LTS RET</th>
                                                <th>10LTS RET</th>
                                                <th>10LTS DESC</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {caps === 0 ? (
                                                <tr>
                                                        <td colSpan='7'>No hay datos</td>
                                                </tr>
                                        ) : (
                                                caps === null ? (
                                                        <tr>
                                                                <td colSpan='7'>Cargando...</td>
                                                        </tr>
                                                ) : (
                                                        caps.results.map((item) => (
                                                                <tr key={item.id}>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.name_user}</td>
                                                                        <td>{item.caps_20lt_returnable}</td>
                                                                        <td>{item.caps_10lt_returnable}</td>
                                                                        <td>{item.caps_10lt_disposable}</td>
                                                                        <td>{item.returned_20lt_returnable}</td>
                                                                        <td>{item.returned_10lt_returnable}</td>
                                                                        <td>{item.returned_10lts_disposable}</td>
                                                                        <td>{item.difference_20lts_returnable}</td>
                                                                        <td>{item.difference_10lts_returnable}</td>
                                                                        <td>{item.difference_10lts_disposable}</td>
                                                                        <td>{item.creation_date}</td>
                                                                        <td>
                                                                                <button type='button' onClick={() => permissions(`/caps/update/${item.id}`, 'submenu')}>
                                                                                        <Edit />
                                                                                </button>
                                                                                <button onClick={() => deleteCap(item.id, item.name_user)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </td>
                                                                </tr>
                                                        ))
                                                )
                                        )}
                                </tbody>
                                <tfoot>
                                        {caps === 0 ? (
                                                <tr>
                                                        <td colSpan='7'>No hay datos para los totales</td>
                                                </tr>
                                        ) : (
                                                caps === null ? (
                                                        <tr>
                                                                <td colSpan='7'>Cargando...</td>
                                                        </tr>

                                                ) : (
                                                        <tr>
                                                                <td colSpan='2'>Totales</td>
                                                                <td>{caps.totals.total_caps_20lt_returnable}</td>
                                                                <td>{caps.totals.total_caps_10lt_returnable}</td>
                                                                <td>{caps.totals.total_caps_10lt_disposable}</td>
                                                                <td>{caps.totals.total_returned_20lt_returnable}</td>
                                                                <td>{caps.totals.total_returned_10lt_returnable}</td>
                                                                <td>{caps.totals.total_returned_10lts_disposable}</td>
                                                                <td>{caps.totals.total_difference_20lts_returnable}</td>
                                                                <td>{caps.totals.total_difference_10lts_returnable}</td>
                                                                <td>{caps.totals.total_difference_10lts_disposable}</td>
                                                                <td></td>
                                                                <td></td>
                                                        </tr>
                                                )
                                        )}
                                </tfoot>
                        </table>
                </div>
        )
}

export default ListCaps
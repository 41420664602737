import React from 'react'
import styles from '../styles/detailsCashControlSeller.module.css'
import { chileanCurrency } from '../services/globals'
import Close from '../img/svg/close'
import { AnimatePresence, motion } from 'framer-motion'

const DetailsCashControlSeller = ({ totalsSeller, setActiveDetails, activeDetails }) => {

        return (
                <AnimatePresence>
                        {activeDetails && (
                                <motion.div
                                        key="6778f765s56"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className={styles.container_details_seller}>
                                        <span>
                                                <button type='button' onClick={() => setActiveDetails(false)}>
                                                        <Close />
                                                </button>
                                                <h3>Detalles </h3>
                                        </span>
                                        <div>
                                                <table>
                                                        <thead>
                                                                <tr>
                                                                        <th>Producto</th>
                                                                        <th>Cantidad</th>
                                                                        <th>Precio unitario</th>
                                                                        <th>Total</th>
                                                                        <th>Forma de pago</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                {totalsSeller?.details?.length > 0 && (
                                                                        totalsSeller.details.map((seller, index) => (
                                                                                <tr key={index}>
                                                                                        <td>{seller.name_product}</td>
                                                                                        <td>{seller.delivered}</td>
                                                                                        <td>{chileanCurrency(seller.unit_price)}</td>
                                                                                        <td>{chileanCurrency(seller.total)}</td>
                                                                                        <td>{seller.name_payment}</td>
                                                                                </tr>
                                                                        ))
                                                                )}
                                                        </tbody>
                                                </table>
                                        </div>
                                </motion.div>
                        )}
                </AnimatePresence>
        )
}

export default DetailsCashControlSeller
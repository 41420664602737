import React, { useEffect } from 'react'
import ContainerPages from '../containerPages'
import { useParamsMaintenance } from '../../hooks/useParamsMaintenance'
import styles from '../../styles/parameters.module.css'
import { listAction } from '../../services/globals'
import { useWorkers } from '../../hooks/useWorkers'
import { useParams } from 'react-router-dom'

const UpdateMaintenance = () => {

        const { id } = useParams()
        const { findMaintenance, atMaintenance, navigation, submitUpdateMaintenance } = useParamsMaintenance()
        const { getAllWorkers } = useWorkers()

        useEffect(() => {
                findMaintenance(id)
        }, [id])

        return (
                <ContainerPages>
                        <div className={styles.form_add_maintenance}>
                                <h3>Actualizar mantención</h3>
                                <form onSubmit={submitUpdateMaintenance}>
                                        <div>
                                                <input type='hidden' name='id' id='id' defaultValue={atMaintenance.id} />
                                                <label htmlFor='name'>Nombre</label>
                                                <input type='text' name='name' id='name' required defaultValue={atMaintenance.name} />
                                        </div>
                                        <div>
                                                <label htmlFor='programmed_date'>Fecha</label>
                                                <input type='datetime-local' name='programmed_date' id='programmed_date' required defaultValue={atMaintenance.programmed_date} />
                                        </div>
                                        <div>
                                                <label htmlFor='action'>Accion</label>
                                                <select name='action' id='action' value={atMaintenance.action}>
                                                        {listAction.map(item => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                </select>
                                        </div>
                                        <div>
                                                <label htmlFor='associated_user'>Usuario</label>
                                                <select name='associated_user' value={atMaintenance.associated_user}>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay usuarios</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione un usuario</option>
                                                                                {getAllWorkers.map((worker) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </div>
                                        <div>
                                                <label htmlFor='observations'>Observaciones</label>
                                                <textarea name='observations' id='observations' cols='30' rows='10' defaultValue={atMaintenance.observations}></textarea>
                                        </div>
                                        <div>
                                                <button type='submit'>Guardar</button>
                                                <button type='button' onClick={() => navigation('/maintenance/maintenance')}>Cancelar</button>
                                        </div>

                                </form>
                        </div>
                </ContainerPages>
        )
}

export default UpdateMaintenance
import React from 'react'
import { useState } from 'react'
import { POST_LOC } from '../../services/post'

const Item = ({ title, id, setItems, items,index }) => {

        const [setEdit, setSetEdit] = useState(false)

        const handleEdit = () => {
                setSetEdit(true)
        }

        const handleBlur = (e) => {

                let update = new FormData();
                update.append('id', e.target.dataset.id)
                update.append('title', e.target.value)

                if (e.target.value !== title) {
                        POST_LOC({
                                table: 'menu?action=update',
                                data: update,
                        }).then(response => {
                        }).catch(error => console.log(error))
                        let newItems = items.map(item => {
                                if (item.id == e.target.dataset.id) {
                                        return { ...item, title: e.target.value }
                                } else {
                                        return item
                                }
                        })
                        setItems(newItems)
                }
                setSetEdit(false)
        }

        return (
                <span onDoubleClick={handleEdit}>
                        {setEdit ? (
                                <input type='text' name='title' data-id={id} defaultValue={title} autoFocus onBlur={handleBlur} />
                        ) : (
                                <p>{title}</p>
                        )
                        }
                </span>
        )
}

export default Item
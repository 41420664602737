import React, { useEffect } from 'react'
import ContainerPages from '../containerPages'
import useProduction from '../../hooks/useProduction'
import Multiselect from "react-widgets/Multiselect";
import styles from '../../styles/production.module.css'
import { useWorkers } from '../../hooks/useWorkers'
import { useParams } from 'react-router-dom';

const UpdateProduction = () => {

        const { getAllWorkers } = useWorkers()
        const { id } = useParams()
        const { userSelect, setUserSelect, getAProduction, oneProduction, updateProduction } = useProduction()

        useEffect(() => {

                getAProduction(id)
        }, [id])

        return (
                <ContainerPages>
                        <div className={styles.container_add_turn}>
                                <form onSubmit={updateProduction}>
                                        <h3>ACTUALIZAR TURNO</h3>
                                        <span>
                                                <input type='hidden' name='id' defaultValue={oneProduction?.id} />
                                                <label>Bidones Malos</label>
                                                <input type='number' name='filling_20lt' placeholder='20 Lts' defaultValue={oneProduction?.filling_20lt} />
                                                <input type='number' name='filling_10lt' placeholder='10 Lts' defaultValue={oneProduction?.filling_10lt} />
                                                <input type='number' name='filling_5lt' placeholder='5 Lts' defaultValue={oneProduction?.filling_5lt} />
                                        </span>
                                        <span>
                                                <label>Bidones Producidos</label>
                                                <input type='number' name='created_20lt' placeholder='20 Lts' defaultValue={oneProduction?.created_20lt} />
                                                <input type='number' name='created_10lt' placeholder='10 Lts' defaultValue={oneProduction?.created_10lt} />
                                                <input type='number' name='created_5lt' placeholder='5 Lts' defaultValue={oneProduction?.created_5lt} />
                                        </span>
                                        <span>
                                                <label htmlFor='ice'>Hielos</label>
                                                <input type='number' name='ice' id='ice' defaultValue={oneProduction?.ice} />
                                        </span>
                                        <span>
                                                <label>Inicio del turno</label>
                                                <input type='time' name='start_of_shift' defaultValue={oneProduction?.start_of_shift} readOnly />
                                        </span>
                                        <span>
                                                <label>Termino del turno</label>
                                                <input type='time' name='end_of_shift' defaultValue={oneProduction?.end_of_shift} />
                                        </span>
                                        <span>
                                                <label>Usuarios asociados</label>
                                                {getAllWorkers !== null && (
                                                        <Multiselect style={{ width: '100%' }}
                                                                dataKey="id_user"
                                                                textField="name_user"
                                                                placeholder='Seleccione los usuarios del turno'
                                                                value={userSelect}
                                                                data={getAllWorkers}
                                                                onChange={(data) => setUserSelect(data)} />
                                                )}
                                        </span>
                                        <span>
                                                <button type='submit'>Actualizar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default UpdateProduction
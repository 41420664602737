import { useState } from 'react'
import { GET_LOC } from '../services/get'
import { POST_LOC } from '../services/post'
import LoginContext from '../contexts/login'
import { useContext } from 'react'
import { API, dateTime, specialPermission } from '../services/globals'
import { DELETE_LOC } from '../services/delete'

const useProduction = () => {

        const { authenticated, setToast, activeToast, navigation, permissions } = useContext(LoginContext)
        const { user } = authenticated !== null && authenticated
        const [production, setProduction] = useState(null)
        const [oneProduction, setOneProduction] = useState(null)
        const [formData, setFormData] = useState(null)
        const { currentDay, currentTime } = dateTime()
        const [userSelect, setUserSelect] = useState([])
        const [errors, setErrors] = useState({})

        const detectionErrors = (data, users) => {

                let errors = {}
                if (data.start_of_shift === '') {
                        errors.start_of_shift = 'Debe ingresar la hora de inicio del turno'
                }

                if (users.length === 0) {
                        errors.userSelect = 'Debe seleccionar al menos un usuario'
                }
                return errors;
        }

        const addProduction = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                if (data.get('start_of_shift') === '') {
                        setToast({
                                message: 'Debe ingresar la hora de inicio del turno',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }

                if (userSelect.length === 0) {
                        setToast({
                                message: 'Debe seleccionar al menos un usuario',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }

                let id_users = userSelect.map(user => parseInt(user.id_user));
                data.append('id_users', JSON.stringify(id_users))

                POST_LOC({
                        table: 'production',
                        data: data
                }).then(response => {

                        if (response.results.lastId) {
                                setToast({
                                        message: 'Registro agregado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/production', { replace: true })
                        } else {
                                setToast({
                                        message: 'Algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return
                        }
                })
        }

        const getAllProduction = (start, end, user) => {

                GET_LOC({
                        table: 'production',
                        params: `?action=list&start_date=${start}&end_date=${end}&user=${user}`
                }).then(response => {
                        if (response.status === 200) {
                                setProduction(response.results)
                        } else {
                                setProduction(0)
                        }
                }).catch(error => {
                        console.log(error)
                })

        }

        const getFiltersProduction = (e) => {
                e.preventDefault()
                if (parseInt(user[0].id_user) !== 63 && parseInt(user[0].id_user) !== 66) {
                        setToast({
                                message: 'No tienes permisos para acceder a esta funcionalidad',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let data = new FormData(e.target)
                setFormData(Object.fromEntries(data))

                getAllProduction(data.get('start_date'), data.get('end_date'), data.get('user'))
        }

        const download = () => {

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar estos datos",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                if (production === 0) {
                        setToast({
                                message: 'No hay registros para descargar',
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return
                }

                let params = `start_date=${currentDay}&end_date=${currentTime}&user=all`
                if (formData !== null) {
                        params = `start_date=${formData.start_date}&end_date=${formData.end_date}&user=${formData.user}`
                }


                fetch(`${API}/production?action=list&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'producción.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });
        }

        const deleteProduction = (id) => {
                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar estos datos",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }


                let isDelete = window.confirm(`¿Estas seguro de eliminar el registro N° ${id}?`)

                if (isDelete) {
                        DELETE_LOC({
                                table: 'production',
                                params: `?id=${id}`
                        }).then(response => {
                                if (response.status === 200) {
                                        setToast({
                                                message: 'Registro eliminado correctamente',
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                        let newProduction = production.filter(item => item.id !== id)
                                        setProduction(newProduction)

                                } else {
                                        setToast({
                                                message: 'Algo salio mal, intente nuevamente',
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                        return
                                }
                        })
                }
        }

        const getAProduction = (id) => {
                GET_LOC({
                        table: 'production',
                        params: `?&id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setOneProduction(response.results[0])
                                setUserSelect(response.results[0].users)
                        } else {
                                setOneProduction(0)
                        }
                }).catch(error => {
                        console.log(error)
                })
        }

        const updateProduction = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                let id_users = userSelect.map(user => parseInt(user.id_user));
                data.append('id_users', JSON.stringify(id_users))
                POST_LOC({
                        table: 'production?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: 'Registro actualizado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/production', { replace: true })
                        } else {
                                setToast({
                                        message: 'Algo salio mal, intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return
                        }
                })
        }

        return {
                addProduction,
                getAllProduction,
                production,
                getFiltersProduction,
                download,
                permissions,
                user,
                deleteProduction,
                userSelect,
                setUserSelect,
                errors,
                oneProduction,
                setOneProduction,
                getAProduction,
                updateProduction
        }
}

export default useProduction
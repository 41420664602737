import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/parameters.module.css'
import { useWorkers } from '../../hooks/useWorkers'
import { useParamsMaintenance } from '../../hooks/useParamsMaintenance'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const UpdateParam = () => {

        const { id } = useParams()
        const { getAllWorkers } = useWorkers()
        const { submitUpdateParams, navigation, findParam, atParams } = useParamsMaintenance()

        useEffect(() => {
                findParam(id)
        }, [id])

        return (
                <ContainerPages>
                        <article className={styles.container_add_param}>
                                <h3>Modificar Parametros</h3>
                                <form onSubmit={submitUpdateParams}>
                                        <div>
                                                <input type='hidden' name='id' id='id' defaultValue={atParams.id} />
                                                <label htmlFor='start_record'>Inicio registro</label>
                                                <input type='datetime-local' name='start_record' id='start_record' required defaultValue={atParams.start_record} />
                                        </div>
                                        <div>
                                                <label htmlFor='associated_user'>Usuario</label>
                                                <select name='associated_user' id='associated_user' required value={atParams.associated_user}>
                                                        {getAllWorkers === 0 ? (
                                                                <option value=''>No hay usuarios</option>
                                                        ) : (
                                                                getAllWorkers === null ? (
                                                                        <option value=''>Cargando...</option>
                                                                ) : (
                                                                        <>
                                                                                <option value='0'>Seleccione un usuario</option>
                                                                                {getAllWorkers.map((worker) => (
                                                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                                                ))}
                                                                        </>
                                                                )
                                                        )}
                                                </select>
                                        </div>
                                        <div>
                                                <label htmlFor='ph'>PH</label>
                                                <input type='number' step='any' name='ph' id='ph' required defaultValue={atParams.ph} />
                                        </div>
                                        <div>
                                                <label htmlFor='temp_ph'>Temperatura PH</label>
                                                <input type='number' step='any' name='temp_ph' id='temp_ph' required defaultValue={atParams.temp_ph}/>
                                        </div>
                                        <div>
                                                <label htmlFor='ppm'>PPM</label>
                                                <input type='number' name='ppm' id='ppm' required defaultValue={atParams.ppm} />
                                        </div>
                                        <div>
                                                <label htmlFor='temp_ppm'>Temperatura PPM</label>
                                                <input type='number' step='any' name='temp_ppm' id='temp_ppm' required defaultValue={atParams.temp_ppm} />
                                        </div>
                                        <div>
                                                <label htmlFor='hardness'>Dureza</label>
                                                <input type='number' name='hardness' id='hardness' required defaultValue={atParams.hardness} />
                                        </div>
                                        <div>
                                                <label>Estanques</label>
                                                <span>
                                                        <input type='number' step='any' name='pond_n_1' id='pond_n_1' placeholder='N°1' defaultValue={atParams.pond_n_1} />
                                                        <input type='number' step='any' name='pond_n_2' id='pond_n_2' placeholder='N°2' defaultValue={atParams.pond_n_2} />
                                                        <input type='number' step='any' name='pond_n_3' id='pond_n_3' placeholder='N°3' defaultValue={atParams.pond_n_3} />
                                                        <input type='number' step='any' name='pond_n_4' id='pond_n_4' placeholder='N°4' defaultValue={atParams.pond_n_4} />
                                                        <input type='number' step='any' name='pond_n_5' id='pond_n_5' placeholder='N°5' defaultValue={atParams.pond_n_5} />
                                                        <input type='number' step='any' name='pond_n_6' id='pond_n_6' placeholder='N°6' defaultValue={atParams.pond_n_6} />
                                                        <input type='number' step='any' name='pond_n_7' id='pond_n_7' placeholder='N°7' defaultValue={atParams.pond_n_7} />
                                                </span>
                                        </div>
                                        <div>
                                                <label htmlFor='observations'>Observaciones</label>
                                                <textarea name='observations' id='observations' cols='30' rows='10' value={atParams.observations}></textarea>
                                        </div>
                                        <div>
                                                <button type='submit'>Actualizar</button>
                                                <button type='button' onClick={() => navigation('/maintenance', { replace: true })}>Cancelar</button>
                                        </div>
                                </form>
                        </article>
                </ContainerPages>
        )
}

export default UpdateParam
import React from 'react'
import { useContext } from 'react'
import LoginContext from '../../../contexts/login'
import { useProducts } from '../../../hooks/useProducts'
import Delete from '../../../img/svg/delete'
import Edit from '../../../img/svg/edit'
import { API, chileanCurrency } from '../../../services/globals'
import styles from '../../../styles/products.module.css'

const TableProduct = ({ deleteProduct }) => {

        const { permissions } = useContext(LoginContext)
        const { getAllProducts } = useProducts()

        return (
                <div className={styles.container_table_list_products}>
                        <table>
                                <thead>
                                        <tr>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                                <th>Precio</th>
                                                <th>Precio empresa</th>
                                                <th>Precio distribuidor</th>
                                                <th>Proveedor</th>
                                                <th>Stock</th>
                                                <th>Tipo de producto</th>
                                                <th>Estado</th>
                                                <th>Acciones</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        {getAllProducts === null ? (
                                                <tr>
                                                        <th>Cargando...</th>
                                                </tr>
                                        ) : (
                                                getAllProducts !== 0 ? (
                                                        getAllProducts.map((item, index) => (
                                                                <tr key={item.id_product}>
                                                                        <td>
                                                                                {item.image_product === "" || item.image_product === null ? (
                                                                                        ''
                                                                                ) : (
                                                                                        <img src={`${API}/uploads/products/${item.image_product}`} />
                                                                                )}
                                                                        </td>
                                                                        <td>{item.name_product}</td>
                                                                        <td>{item.description_product}</td>
                                                                        <td>${chileanCurrency(item.price_product)}</td>
                                                                        <td>${chileanCurrency(item.company_price)}</td>
                                                                        <td>${chileanCurrency(item.distributor_price)}</td>
                                                                        <td>{item.vendor_product}</td>
                                                                        <td style={{ color: parseInt(item.stock_product) !== 0 ? '#7AD03A' : 'red' }}>{parseInt(item.stock_product) !== 0 ? `Hay existencias (${item.stock_product})` : 'Sin stock'}</td>
                                                                        <td>{parseInt(item.is_internal) === 0 ? "Para venta" : "Producto interno"}</td>
                                                                        <td>
                                                                                {item.is_active_product == 1 ? (
                                                                                        <p style={{ background: 'var(--primaryColor)' }}>Activo</p>
                                                                                ) : (
                                                                                        <p style={{ background: 'red' }}>Inactivo</p>
                                                                                )}
                                                                        </td>
                                                                        <td>
                                                                                <button type="button" onClick={() => permissions(`/products/update/${item.id_product}`, 'submenu')}>
                                                                                        <Edit />
                                                                                </button>
                                                                                <button type="button" onClick={() => deleteProduct(item.id_product, item.name_product)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </td>
                                                                </tr>
                                                        ))) : (
                                                        <tr>
                                                                <td>No se </td>
                                                                <td>encontraron productos</td>
                                                        </tr>
                                                ))}
                                </tbody>
                        </table>
                </div>
        )
}

export default TableProduct
import React from 'react'
import styles from '../../styles/addDailyManager.module.css'
import { AnimatePresence, motion } from 'framer-motion'

const AddFormAccount = ({ activeFormAccount, getAcc, deleteAccount, addAccount, setActiveFormAccount }) => {

        return (
                <AnimatePresence>
                        {activeFormAccount && (
                                <motion.div className={styles.box_add_account} layout>
                                        <div>
                                                <form onSubmit={addAccount}>
                                                        <span>
                                                                <button type='button' onClick={() => setActiveFormAccount(false)}>Cerrar</button>
                                                                <h3>Nueva cuenta</h3>
                                                        </span>
                                                        <input type='text' name='name_account' placeholder='nombre de la cuenta' />
                                                        <span>
                                                                <button type='submit'>Agregar</button>
                                                        </span>
                                                </form>
                                                <div>
                                                        <table>
                                                                <thead>
                                                                        <tr>
                                                                                <th>Id</th>
                                                                                <th>Nombre</th>
                                                                                <th>Acciones</th>
                                                                        </tr>
                                                                </thead>
                                                                <tbody>
                                                                        {getAcc === 0 ? (
                                                                                <tr>
                                                                                        <td colSpan='3'>Sin subcuentas</td>
                                                                                </tr>
                                                                        ) : (
                                                                                getAcc === null ? (
                                                                                        <tr>
                                                                                                <td colSpan='3'>Cargando...</td>
                                                                                        </tr>
                                                                                ) : (
                                                                                        getAcc.map((item, index) => (
                                                                                                <tr key={item.id_account}>
                                                                                                        <td>{item.id_account}</td>
                                                                                                        <td style={{ textAlign: 'left' }}>{item.name_account}</td>
                                                                                                        <td>
                                                                                                                <button type='button' onClick={() => deleteAccount(item.id_account)}>Eliminar</button>
                                                                                                        </td>
                                                                                                </tr>
                                                                                        ))
                                                                                )
                                                                        )}
                                                                </tbody>
                                                        </table>
                                                </div>
                                        </div>
                                </motion.div>
                        )}
                </AnimatePresence>
        )
}

export default AddFormAccount
import React, { useContext } from 'react'
import styles from '../../styles/addOrder.module.css'
import imgDefault from '../../img/sin-imagen.png'
import { chileanCurrency } from '../../services/globals'
import LoginContext from '../../contexts/login'


const ListProducts = ({ products, addProductToCart }) => {

        const { navigation } = useContext(LoginContext)

        return (
                <div className={styles.container_products}>
                        <h3 style={{ gridColumn: '1 / -1' }}>TODOS LOS PRODUCTOS</h3>
                        {products === null ? (
                                <p>Cargando...</p>
                        ) : (
                                products === 0 ? (
                                        <p>No hay productos</p>
                                ) : (
                                        <>
                                                {products.map((product, index) => (
                                                        <div key={index}>
                                                                <span>
                                                                        <img src={product.image_product === null || product.image_product == "" ? imgDefault : `https://api.aguadelzar.cl/administrador/uploads/products/${product.image_product}`} alt="img" />
                                                                </span>
                                                                <p>{product.name_product}</p>
                                                                <p>$ {chileanCurrency(product.price_product)}</p>
                                                                <span>
                                                                        <button type='button' onClick={() => addProductToCart(product)}>Agregar</button>
                                                                </span>
                                                        </div>
                                                ))}
                                                <div style={{ displey: 'grid', placeContent: 'center' }}>
                                                        <button type='button' style={{ aspectRatio: '1 / 1', fontSize: '5em', border: 'none', fontWeight: 'bold', cursor: 'pointer' }} title='Agregar nuevo producto' onClick={() => navigation('products/add', { replace: true })}>+</button>
                                                </div>
                                        </>
                                )
                        )}
                </div>
        )
}

export default ListProducts
import { Reorder } from 'framer-motion'
import React, { useState, useEffect, useContext } from 'react'
import ContainerPages from '../components/containerPages'
import Item from '../components/menuManagement/item'
import LoginContext from '../contexts/login'
import styles from '../styles/menuManagement.module.css'
import Loading from '../components/loaderSubmit'
import { POST_LOC } from '../services/post'

const MenuManagement = () => {

        const { menu, setToast, activeToast } = useContext(LoginContext)
        const [items, setItems] = useState([])
        const [loading, setLoading] = useState(false)

        useEffect(() => {
                if (menu === null) return
                setItems(menu)
        }, [menu === null])

        const submitReorderMemenu = () => {
                setLoading(true)
                const newOrder = items.map((item, index) => ({ id: item.id, position: index + 1 }))
                const formData = new FormData()
                formData.append('menus', JSON.stringify(newOrder));

                POST_LOC({
                        table: 'menu?reorder=true',
                        data: formData
                }).then(response => {
                        if(response.status == 200){
                                setToast({
                                        message: 'Menu reordenado correctamente, porfavor reinicia la session',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                }).catch(e => {
                        console.log(e)
                }).finally(() => {
                        setLoading(false)
                })
                //console.log(newOrder)
        }

        return (
                <ContainerPages>
                        <div className={styles.container_menu}>
                                <div>
                                        <h3><strong>Menu Principal</strong></h3>
                                        <span>
                                                <strong>NOTA:</strong>
                                                <ul >
                                                        <li>Para editar un item, haga doble click sobre el mismo.</li>
                                                        <li>para guardar solo presione fuera el elemento</li>
                                                        <li>Despues de reordenar el menu debes reiniciar la sesion para mantener los cambios</li>
                                                </ul>
                                        </span>
                                        <Reorder.Group axis="y" values={items} onReorder={setItems} className={styles.box_li}>
                                                {items.map((item, index) => (
                                                        <Reorder.Item key={item.id} value={item} className={styles.li}>
                                                                <Item
                                                                        index={index}
                                                                        items={items}
                                                                        setItems={setItems}
                                                                        title={item.title}
                                                                        id={item.id} />
                                                        </Reorder.Item>
                                                ))}
                                        </Reorder.Group>
                                </div>
                        </div>
                        <div className={styles.container_button_reorder}>
                                <button type='button' disabled={loading} onClick={() => submitReorderMemenu()}>{loading ? <Loading /> : 'Guardar Reorden'}
                                </button>
                        </div>
                </ContainerPages>
        )
}

export default React.memo(MenuManagement)
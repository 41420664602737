import React, { useContext } from 'react'
import ContainerPages from '../components/containerPages'
import LoginContext from '../contexts/login'
import { useClients } from '../hooks/useClients'
import styles from '../styles/addClient.module.css'
import { autoFormat } from '../services/globals'

const AddClient = () => {

        const { navigation } = useContext(LoginContext)
        const { addClient } = useClients()

        return (
                <ContainerPages>
                        <div className={styles.container_add_client}>
                                <h3>NUEVO CLIENTE</h3>
                                <form onSubmit={addClient}>
                                        <label>Nombre y Apellido</label>
                                        <input type="text" name="name_client" required />
                                        <label>Rut</label>
                                        <input type="text" name="rut_client" placeholder='ej : 11.111.111-1 o 11111111-1' onChange={autoFormat} />
                                        <label>Email</label>
                                        <input type="email" name="email_client" required />
                                        <label>Telefono</label>
                                        <input type="number" name="phone_client" required />
                                        <label>Tipo cliente</label>
                                        <select name='client_type_client' required>
                                                <option value="Particular" defaultValue>Particular</option>
                                                <option value="Empresa">Empresa</option>
                                                <option value="Distribuidor" >Distribuidor</option>
                                        </select>
                                        <label>Clasificación</label>
                                        <input type="text" name="classification_client" />
                                        <label>Dirección</label>
                                        <input type="text" name="direction_client" required />
                                        <label>Referencia</label>
                                        <input type="text" name="reference_client" required />
                                        <label>Estado cliente</label>
                                        <select name='state_client' defaultValue={1} required>
                                                <option value='0'>Pendiente</option>
                                                <option value='1'>Activo</option>
                                                <option value='2'>Rechazado</option>
                                        </select>
                                        <span>
                                                <button type='submit'>Guardar</button>
                                                <button type="button" onClick={() => navigation('/clients', { replace: true })}>Cancelar</button>
                                        </span>
                                </form>
                        </div>
                </ContainerPages>
        )
}

export default AddClient
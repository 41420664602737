import { StyleSheet } from '@react-pdf/renderer';

const width = 100 / 3


export const styles = StyleSheet.create({

    page :{
        display:'flex', 
        width:'90%', 
        height:'auto', 
        padding:'20px',
         border:'1px solid #0000',
    },
    header:{
        display:'flex', 
        width:'100%', 
        height:'auto', 
        flexDirection:'row'
    },
    boxHeader1:{
        display:'flex', 
        width:`${width}%`,
    },
    boxHeader2:{
        display:'flex', 
        width:`${width}%`, 
        alignItems:'center'
    },
    boxHeader3:{
        display:'flex', 
        width:`${width}%`, 
        alignItems:'flex-end', 
        justifyContent:'flex-start'
    },
    title:{
        fontSize:'13px'
    },
    title1:{
        fontSize:'12px'
    },
    boxDelivery:{
        width:'100%',
        paddingTop:'20px',
        paddingBottom:'20px'
    },
    thead :{
        width:'100%',
        height:'15px',
        flexDirection:'row',
        borderBottom:'1px solid #000000',
    },
    tbody:{
        width:'100%',
        minHeight:'12px',
        flexDirection:'row',
        borderBottom:'1px solid #BBBBBB'
    },
    tr: {
        display:'flex',
        minHeight:'20px',
        fontSize:'10px',
        fontWeight:'600',
        flexDirection:'column',
        paddingLeft:'5px'
    },
    th:{
        width:'100%',
        minHeight:'12px',

    },
    totals:{
        fontWeight:'bold',
        fontSize:'13px'
    }

})
import React from 'react'
import ContainerPages from '../components/containerPages'
import { usePromotions } from '../hooks/usePromotions'
import styles from '../styles/promotions.module.css'
import LoginContext from '../contexts/login'
import { useContext } from 'react'
import { API, chileanCurrency } from '../services/globals'
import Edit from '../img/svg/edit'
import Delete from '../img/svg/delete'

const Promotions = () => {

        const { permissions } = useContext(LoginContext)
        const { totalPromotions, getAllPromotions, deletePromotion } = usePromotions()

        return (
                <ContainerPages>
                        <div className={styles.container_promotions}>
                                <div className={styles.title}>
                                        <h3>{totalPromotions ?? 0} Promociones</h3>
                                        <button type='button' onClick={() => permissions('/promotions/add', 'submenu')}>Agregar</button>
                                </div>
                                <div className={styles.container_table_promotions}>
                                        <table>
                                                <thead>
                                                        <tr>
                                                                <th>N°</th>
                                                                <th>Imagen</th>
                                                                <th>Nombre</th>
                                                                <th>Descripción promoción</th>
                                                                <th>Precio promoción</th>
                                                                <th>Estado</th>
                                                                <th>Acciones</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        { }
                                                        {getAllPromotions === 0 ? (
                                                                <tr><td>Sin registros</td></tr>
                                                        ) : (
                                                                getAllPromotions === null ? (
                                                                        <tr><td>Cargando...</td></tr>
                                                                ) : (
                                                                        getAllPromotions.map((promotion, index) => (
                                                                                <tr key={promotion.id}>
                                                                                        <td>{promotion.id}</td>
                                                                                        <td>
                                                                                                {promotion.main_image !== null ? (
                                                                                                        <img src={`${API}/uploads/products/${promotion.main_image}`} alt={promotion.name_promotion} />
                                                                                                ) : (
                                                                                                        <p>Sin imagen</p>
                                                                                                )}
                                                                                        </td>
                                                                                        <td>{promotion.name_promotion}</td>
                                                                                        <td>{promotion.description}</td>
                                                                                        <td>$ {chileanCurrency(promotion.price_promotion)}</td>
                                                                                        <td>
                                                                                                {promotion.is_active == 1 ? (
                                                                                                        <p>Activo</p>
                                                                                                ) : (
                                                                                                        <p>Inactivo</p>
                                                                                                )}
                                                                                        </td>
                                                                                        <td>
                                                                                                <button type='button' onClick={() => permissions(`/promotions/update/${promotion.id}`,'submenu')} title='ver detalles'>
                                                                                                        <Edit />
                                                                                                </button>
                                                                                                <button type='button' onClick={() => permissions(`/promotions/update/${promotion.id}`,'submenu')} title='editar'>
                                                                                                        <Edit />
                                                                                                </button>
                                                                                                <button type='button' onClick={() => deletePromotion(promotion.id)} title='eliminar'>
                                                                                                        <Delete />
                                                                                                </button>
                                                                                        </td>
                                                                                </tr>
                                                                        )))
                                                        )}

                                                </tbody>
                                        </table>
                                </div>
                        </div>
                </ContainerPages>
        )
}

export default Promotions
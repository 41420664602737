import { motion } from "framer-motion";
import styled from "styled-components";

export const Title = styled.div`
    position: relative;
    display:flex;
    width:100%;
    height:auto;
    column-gap:20px;
    align-items:center;
    flex-wrap:wrap;

    h3{
        position:relative;
        width:auto;
        text-align: left;
        padding: 20px;
        font-size: 1.4em;

    }
    button{
        display:flex;
        width:auto;
        height: 30px;
        padding: 10px;
        background-color:var(--primaryColor);
        align-items:center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        
        p{
            color: var(--secundaryBackground);
            font-size:1em;
            font-weight: bold;

        }
    }
`;

export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: hidden;
`;
export const DataTable = styled.div`

    width: 100%;
    height: auto;
    overflow-y: scroll;

table{
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 2000px;

    thead {
    display:flex;
    position: relative;
    background-color: var(--primaryColor);
    color: var(--secundaryBackground);
    
        tr {
        display: grid;
        place-content: center;
        width: 100%;
        height: 40px;
        grid-template-columns:3% repeat(10,1fr) ;
        }

    
    }
    tbody{
        height: auto;
    
    &:nth-child(odd){
        background-color: var(--secundaryBackground);
    }
        tr{
            display: grid;
            width: 100%;
            min-height:35px;
            grid-template-columns:3% repeat(10,1fr) ;

        &:hover{
            background-color: rgba(0,0,0,0.1);
        }
    
            td{
                display: flex;
                height: 100%;
                justify-content: center;
                font-weight: normal;
                gap: 10px;
                align-items: center;
                
    
                button{
                    display: grid;
                    width: 30px;
                    height: 30px;
                    border: 1px solid rgba(0,0,0, 0.2);
                    border-radius: 5px;
                    cursor: pointer;
                    place-content: center;
    
                    svg{
                        width: 1.2em;
                        height: 1.2em;
                    }
                }
                button:nth-child(1){

                    svg{
                        fill:var(--secundaryColor);
                    }

                }
                button:nth-child(2){
                    svg{
                        fill:red;
                    }
                }
            }
        }
    
    }
    
}

`;


export const BoxFilter = styled.div`
    position: relative;
    display: flex;
    width:100%;
    padding: 20px;

    form{
        position: relative;
        display:flex;
        width: 100%;
        flex-wrap: wrap;
        border:1px solid rgba(0,0,0,0.2);
        padding: 10px;
        border-radius: 5px;

    span:not(span:nth-child(4)){
        display: flex;
        width: calc(100% / 3);
        column-gap: 5px;
        align-items: center;

        label{
            font-weight: 600;
        }
    }

    span:nth-child(4){
        width: 100%;
        margin-top: 10px;
        border:1px solid rgba(0,0,0,0.2);
        overflow: hidden;
        border-radius: 5px;

        input{
            width: calc(100% - 40px);
            height: 30px;
            border:none;
        }
        button{
            width: 40px;
            height: 30px;
            border:none;
            border-left: 1px solid rgba(0,0,0,0.2);
            cursor:pointer;

            svg{
                width: 1.2em;
                height: 1.2em;
            }
        }
    }

    }

    @media screen and (min-width:768px){

        form{
            align-items: center;

                span:not(span:nth-child(4)){
                    width: 100px;
                    justify-content:center;
                    align-items: center;
                }

                span:nth-child(4){
                    width: calc(100% - 300px);
                    margin-top: 0;
                }
            }

    }
`;

export const Form = styled(motion.div)`

    position: fixed;
    display:flex;
    width: 100%;
    //style={{width: activeMenu ? '100%' : 'calc(100% - 150px)'}}
    height: 100vh;
    padding:20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,0.4822303921568627) 100%, rgba(22,22,63,0.2049194677871149) 100%);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    transition: width 0.2s ease;
    right: 0;



    form{
        position: relative;
        display:flex;
        width: 95%;
        max-width: 800px;
        height: auto;
        max-height: 80vh;
        align-items: center;
        justify-content: flex-start;
        background-color:#FFFFFF;
        flex-wrap: wrap;
        border-radius: 5px;
        padding: 20px;
        overflow-y: scroll;
        row-gap: 5px;

        label{
            width: 100%;
            font-weight: bold;
            padding-bottom: 5px;
        }
        input,select{
            width:100%;
            height:40px;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.2);
            font-size: 1.2em;
            padding-left: 5px;
        }
        span{
            display: flex;
            width:100%;
            column-gap: 20px;
            padding: 20px;
            align-items: center;
            justify-content: center;

            button{
                width: 100px;
                padding:5px 0;
                cursor: pointer;
                border:none;
                border-radius: 5px;
                font-weight: bold;
                color:var(--secundaryBackground);
            }

            button:nth-child(1){
                background-color: var(--primaryColor);
            }
            
            button:nth-child(2){
                background-color: red;
            }
        }
    }
    @media screen and (min-width:768px){
        width: ${({ activeMenu }) => activeMenu ? '100%' : 'calc(100% - 150px)'};

        form{
        column-gap: 20px;
            label{
                width: calc(25% - 20px);
            }
            input,select{
                width: calc(25% - 20px);
            }
        }
    }

`;

export const BoxLoader = styled.div`
    position: absolute;
    display: grid;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color:rgba(255,255,255,.8);
    place-content: center;
`;
import React from 'react'
import { useWorkers } from '../../hooks/useWorkers'

const SelectUser = () => {

        const { getAllWorkers } = useWorkers()

        return (
                <select name='associated_user'>
                        <>
                                <option value='0'>Todos</option>
                                {getAllWorkers === 0 ? (
                                        <option value='0'>No hay usuarios</option>
                                ) : (
                                        getAllWorkers === null ? (
                                                <option value='0'>Cargando...</option>
                                        ) : (
                                                getAllWorkers.map((worker) => (
                                                        <option key={worker.id_user} value={worker.id_user}>{worker.name_user}</option>
                                                ))
                                        )
                                )}
                        </>
                </select>
        )
}
export default React.memo(SelectUser)
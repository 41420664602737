import React, { useContext, useEffect } from 'react'
import ContainerPages from '../../components/containerPages'
import LoginContext from '../../contexts/login'
import { useShipping } from '../../hooks/useShipping'
import { Title } from '../../styles/deliveries'
import ListDeliveries from './components/listDeliveries'

const Deliveries = () => {

        const {authenticated} = useContext(LoginContext)
        const {list,shipping} = useShipping()

        useEffect(() => {

                if(authenticated !== null){
                        shipping(authenticated.user[0].id_user)        
                }

        }, [authenticated === null])

        if(list === null) return;

        return (
                <ContainerPages>
                        <Title>
                                <h3>{list?.total} PEDIDOS </h3>
                        </Title>
                        <ListDeliveries orders={list.results}/>
                </ContainerPages>
        )
}

export default Deliveries
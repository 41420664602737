import { useContext, useState } from "react"
import { GET_LOC } from "../services/get"
import { useEffect } from "react"
import LoginContext from "../contexts/login"
import { POST_LOC } from "../services/post"
import { DELETE_LOC } from "../services/delete"

const useSurrenders = () => {

        const { navigation, setToast, activeToast, authenticated } = useContext(LoginContext)
        const [allSurrenders, setAllSurrenders] = useState(null)
        const [dates, setDates] = useState({})
        const [activeDetails, setActiveDetails] = useState(false)
        const [totalsSeller, setTotalsSeller] = useState([])
        const [getACashControl, setGetACashControl] = useState([])
        const [getAllQuadrature, setGetAllQuadrature] = useState(null)
        const [boxCuadrature, setBoxCuadrature] = useState({
                opening_cash: 0,
                closing_cash: 0,
        })

        const onChangeDate = (e) => {
                setDates({
                        ...dates,
                        [e.target.name]: e.target.value
                })
        }


        const getQuadrature = () => {
                GET_LOC({
                        table: 'box_quadrature',
                        params: `?action=all`
                }).then(response => {
                        if (response.status === 200) {
                                setGetAllQuadrature(response)
                        } else {
                                setGetAllQuadrature(0)
                        }
                }).catch(error => {
                        console.log(error)
                }).finally(() => {
                        //console.log('finally')
                })
        }

        const getACashControlById = (id) => {
                GET_LOC({
                        table: 'box_quadrature',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setGetACashControl(response.results[0])
                        } else {
                                setGetACashControl(0)
                        }
                })
        }

        const getAllSurrenders = () => {
                GET_LOC({
                        table: 'cashier_control',
                        params: `?all=true`
                }).then(response => {
                        if (response.status === 200) {
                                setAllSurrenders(response)
                        } else {
                                setAllSurrenders(0)
                        }
                }).catch(error => {
                        console.log(error)
                }).finally(() => {
                        //console.log('finally')
                })
        }

        const getDataTotalsSeller = () => {

                if (Object.keys(dates).length < 3) {
                        setToast({
                                message: "Debes seleccionar un vendedor y un rango de fechas",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                };
                if (dates?.start_date === "" || dates?.end_date === "" || dates?.id_seller === "" || dates?.id_seller === '0') {
                        setToast({
                                message: "Debes seleccionar un vendedor y un rango de fechas",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                GET_LOC({
                        table: 'buy_order',
                        params: `?cash_control=true&seller=${dates?.id_seller}&start_date=${dates.start_date}&end_date=${dates.end_date}`
                }).then(response => {
                        if (response.status === 200) {
                                setTotalsSeller(response.results)
                        } else {
                                setToast({
                                        message: "No hay datos para mostrar",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                }).catch(error => {
                        console.log(error)
                }).finally(() => {
                        //console.log('finally')
                })
        }

        const onSubmitUpdateCashControl = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                POST_LOC({
                        table: 'box_quadrature?action=update',
                        data: data
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: "cierre de caja agregado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/surrenders/list-quadrature', { replace: true })
                        } else {
                                setToast({
                                        message: "Ocurrio un error, intenta nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        const onSubmitCashControl = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                data.append('registered_by', authenticated.user[0].id_user)

                POST_LOC({
                        table: 'box_quadrature',
                        data: data
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: "apertura de caja agregado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/surrenders/list-quadrature', { replace: true })
                        } else {
                                setToast({
                                        message: "Ocurrio un error, intenta nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        const addCashControl = (e) => {
                e.preventDefault()

                if (totalsSeller.length === 0) {
                        setToast({
                                message: "Debes seleccionar un vendedor y un rango de fechas",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                let data = new FormData(e.target)
                data.append('id_responsible_cashier', authenticated.user[0].id_user)
                data.append('name_responsible_cashier', authenticated.user[0].name_user)
                data.append('seller_sales_with_cash', totalsSeller.totals.cash_payment)
                data.append('seller_sales_with_guide', totalsSeller.totals.guide_payment)
                data.append('seller_sales_with_invoice', totalsSeller.totals.invoice_payment)


                POST_LOC({
                        table: 'cashier_control',
                        data: data
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: "Control de caja agregado correctamente",
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/surrenders/list-cash-control', { replace: true })
                        } else {
                                setToast({
                                        message: "Ocurrio un error, intenta nuevamente",
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;

                        }
                }).catch(error => {
                        console.log(error)
                })
        }

        const deleteCashControl = (id) => {
                let isDelete = window.confirm('¿Estas seguro de eliminar este registro?');

                if (!isDelete) return;
                DELETE_LOC({
                        table: 'cashier_control',
                        params: `?id=${id}`
                })
                        .then(response => {
                                if (response.status === 200) {
                                        setToast({
                                                message: "Registro eliminado correctamente",
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                        setAllSurrenders(prev => {
                                                let newCashControl = prev.results.filter(cashControl => cashControl.id !== id)
                                                return {
                                                        ...prev,
                                                        results: newCashControl
                                                }
                                        })
                                } else {
                                        setToast({
                                                message: "Ocurrio un error, intenta nuevamente",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                }
                        })
        }

        const deleteBoxCuadrature = (id) => {
                let isDelete = window.confirm('¿Estas seguro de eliminar este registro?');

                if (!isDelete) return;

                DELETE_LOC({
                        table: 'box_quadrature',
                        params: `?id=${id}`
                })
                        .then(response => {
                                if (response.status === 200) {
                                        setToast({
                                                message: "Registro eliminado correctamente",
                                                error: false,
                                                time: 3000
                                        })
                                        activeToast()
                                        setGetAllQuadrature(prev => {
                                                let newQuadrature = prev.results.filter(quadrature => quadrature.id !== id)
                                                return {
                                                        ...prev,
                                                        results: newQuadrature
                                                }
                                        })
                                } else {
                                        setToast({
                                                message: "Ocurrio un error, intenta nuevamente",
                                                error: true,
                                                time: 3000
                                        })
                                        activeToast()
                                }
                        })
        }

        useEffect(() => {
                getQuadrature()
        }, [])
        useEffect(() => {
                getAllSurrenders()
        }, [])

        return {
                getAllSurrenders,
                allSurrenders,
                navigation,
                getDataTotalsSeller,
                onChangeDate,
                addCashControl,
                totalsSeller,
                setActiveDetails,
                activeDetails,
                onSubmitCashControl,
                getACashControlById,
                getACashControl,
                onSubmitUpdateCashControl,
                getAllQuadrature,
                deleteBoxCuadrature,
                deleteCashControl
        }
}

export default useSurrenders
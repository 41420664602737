import React, { useContext } from 'react'
import styled from 'styled-components'
import LoginContext from '../contexts/login'

const ContainerPages = ({ children, position = 'absolute' }) => {
  const { activeMenu } = useContext(LoginContext)

  return (
    <Box
      activeMenu={activeMenu}
      position={position}
    >{children}</Box>
  )
}

const Box = styled.div`
    position: ${({ position }) => position};
    display: flex;
    width:100%;
    height: calc(100% - 40px);
    right: 0;
    bottom: 0;
    background-color: var(--primaryBackground);
    flex-direction: column;
    align-items:flex-start;
    transition: width .2s ease;
    z-index:100;
    padding: 0 5px;

    @media screen and (min-width:768px){
      width: ${({ activeMenu }) => activeMenu ? '100%' : 'calc(100% - 150px)'};
    }

`;

export default ContainerPages
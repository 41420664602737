import React from 'react'
import { currentTime } from '../services/globals'
import { useBuyOrder } from './useBuyOrder'
import { GET_LOC } from '../services/get'
import { useState } from 'react'
import { useEffect } from 'react'

export const useReports = () => {


        const [getOrders, setGetOrders] = useState(null)
        const [filterByDate, setFilterByDate] = useState({
                start: currentTime(),
                end: currentTime()
        })


        const findAllOrders = (filter) => {
                GET_LOC({
                        table: 'buy_order',
                        params: `?all=true&status=all&shipping=all&initial=${filter.start}&final=${filter.end}`
                }).then(response => {
                        //console.log(response)
                        setGetOrders(response.results)
                })
        }


        useEffect(() => {
                findAllOrders(filterByDate)
        }, [filterByDate])


        return {
                findAllOrders,
                getOrders,
                filterByDate,
                setFilterByDate
        }
}

import React from 'react'
import ContainerPages from '../containerPages'
import styles from '../../styles/packageStore.module.css'
import usePackageStore from '../../hooks/usePackageStore';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AddPackageStoreArrival = () => {

        const { getAlPackageStore, atPackageStore, addPackageStoreArrival, navigation } = usePackageStore();
        const { id } = useParams();
        useEffect(() => {
                getAlPackageStore(id);
        }, [atPackageStore === null])

        return (
                <ContainerPages>
                        <div className={styles.container_add_arrival}>
                                <h3>Control de bodega (Salida)</h3>
                                <div>
                                        <ul>
                                                <li>
                                                        <b>Vendedor </b>
                                                        <p>{atPackageStore?.assigned_salesperson_name}</p>
                                                </li>
                                                <li>
                                                        <b>Fecha de entrada</b>
                                                        <p>{atPackageStore?.exit_time}</p>
                                                </li>
                                                <li>
                                                        <b>Producto Relacionado</b>
                                                        <p>{atPackageStore?.name_product}</p>
                                                </li>
                                        </ul>
                                </div>
                                <div>
                                        <form onSubmit={addPackageStoreArrival}>
                                                <span>
                                                        <label htmlFor='arrival_quantity'>Cantidad</label>
                                                        <input type='number' name='arrival_quantity' id='arrival_quantity' defaultValue={atPackageStore?.arrival_quantity} />
                                                </span>
                                                <span>
                                                        <label htmlFor='sign_seller'>firmar</label>
                                                        <input name='sign_seller' id='sign_seller' type='checkbox' defaultChecked={atPackageStore?.sign_seller} />
                                                </span>
                                                <input type='hidden' name='id' id='id' defaultValue={atPackageStore?.id} />
                                                <span>
                                                        <button type='submit'>Guardar</button>
                                                        <button type='button' onClick={() => navigation('/package-store', { replace: true })}>Volver</button>
                                                </span>
                                        </form>
                                </div>
                        </div>
                </ContainerPages>
        )
}

export default AddPackageStoreArrival
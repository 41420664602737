import React, { useContext } from 'react'
import LoginContext from '../contexts/login'
import { POST_LOC } from '../services/post'
import { API, dateTime, specialPermission } from '../services/globals'
import { GET_LOC } from '../services/get'
import { useState } from 'react'
import { useEffect } from 'react'
import { DELETE_LOC } from '../services/delete'

const usePackageStore = () => {

        const { permissions, authenticated, setToast, activeToast, navigation } = useContext(LoginContext)
        const { currentTime, currentDay } = dateTime()
        const [getPackageStore, setGetPackageStore] = useState(null)
        const [atPackageStore, setAtPackageStore] = useState(null)
        const [saveDataFilter, setSaveDataFilter] = useState(null)
        const inititalFilters = {
                start_date: currentDay,
                end_date: currentTime,
                order_mode: 'ASC',
                order_by: 'id',
                search: ''
        }

        const getAllControls = (filters) => {

                const { start_date, end_date, order_mode, order_by, search } = filters

                GET_LOC({
                        table: 'package_store',
                        params: `?action=list&start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`
                }).then(response => {
                        if (response.status === 200) {
                                setGetPackageStore(response)
                        } else {
                                setGetPackageStore(0)
                        }
                })
        }

        const addPackageStoreArrival = (e) => {
                e.preventDefault()
                let form = new FormData(e.target)
                POST_LOC({
                        table: 'package_store?action=add-arrival',
                        data: form
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                navigation('/package-store', { replace: true })
                        } else {
                                setToast({
                                        message: 'Error al agregar entrada,intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })

        }

        const getAlPackageStore = (id) => {
                GET_LOC({
                        table: 'package_store',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setAtPackageStore(response.results[0])
                        } else {
                                setAtPackageStore(0)
                        }
                })
        }

        const filterPackageStore = (e) => {
                e.preventDefault();

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                let data = Object.fromEntries(new FormData(e.target))
                data.order_mode = data.order_mode === 'on' ? 'DESC' : 'ASC'
                setSaveDataFilter(data)
                getAllControls(data)
        }

        const addPackageStore = (e) => {
                e.preventDefault()
                let form = new FormData(e.target)
                form.append('dispatcher_id', authenticated.user[0].id_user)
                form.append('dispatcher_name', authenticated.user[0].name_user)

                if (form.get('id_product') === '0') {
                        setToast({
                                message: "Seleccione un producto",
                                error: true,
                                time: 3000,
                        })
                        activeToast()
                        return;
                }


                if (form.get('assigned_salesperson_id') === '0') {
                        setToast({
                                message: "Seleccione un vendedor",
                                error: true,
                                time: 3000,
                        })
                        activeToast()
                        return;
                }

                POST_LOC({
                        table: 'package_store',
                        data: form
                }).then(response => {
                        if (response.lastId) {
                                setToast({
                                        message: "Control agregado correctamente",
                                        error: false,
                                        time: 3000,
                                })
                                activeToast()
                                navigation('/package-store', { replace: true })
                        }

                })
        }

        const downloadPackageStore = () => {

                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para descargar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }

                const { start_date, end_date, order_mode, order_by, search } = inititalFilters

                let params = `start_date=${start_date}&end_date=${end_date}&order_mode=${order_mode}&order_by=${order_by}&search=${search}`

                if (saveDataFilter !== null) {
                        params = `start_date=${saveDataFilter.start_date}&end_date=${saveDataFilter.end_date}&order_mode=${saveDataFilter.order_mode}&order_by=${saveDataFilter.order_by}&search=${saveDataFilter.search}`
                }

                fetch(`${API}/package_store?action=list&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {
                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'control_de_bodega.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });

        }

        const deletePackageStore = (id, name) => {
                if (!specialPermission.includes(parseInt(authenticated.user[0].id_user))) {
                        setToast({
                                message: "No tienes permisos para eliminar asignaciones",
                                error: true,
                                time: 3000
                        })
                        activeToast()
                        return;
                }
                if (!window.confirm(`¿Estas seguro de eliminar el control de ${name}?`)) {
                        return;
                }

                DELETE_LOC({
                        table: 'package_store',
                        params: `?id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: response.comment,
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                setGetPackageStore(prevState => {
                                        let newItem = {};
                                        newItem.results = prevState.results.filter(item => item.id !== id)
                                        newItem.total = prevState.total - 1
                                        return newItem;
                                })
                        } else {
                                setToast({
                                        message: 'Error al eliminar control,intente nuevamente',
                                        error: true,
                                        time: 3000
                                })
                                activeToast()
                                return;
                        }
                })
        }

        useEffect(() => {
                getAllControls(inititalFilters)
        }, [])

        return {
                permissions,
                addPackageStore,
                inititalFilters,
                getAllControls,
                getPackageStore,
                filterPackageStore,
                downloadPackageStore,
                getAlPackageStore,
                atPackageStore,
                addPackageStoreArrival,
                navigation,
                deletePackageStore
        }
}

export default usePackageStore
import { PDFViewer, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import { useCallback } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ContainerPages from '../../components/containerPages'
import LoginContext from '../../contexts/login'
import { RANGE } from '../../services/get'
import { currentTime } from '../../services/globals'
import { GeneratorPDF } from '../../styles/reports'
import BoxFilter from './components/boxFilter'
import ListOrders from './components/listOrders'
import ViewPdf from './components/viewPdf'
import { useBuyOrder } from '../../hooks/useBuyOrder'
import { useReports } from '../../hooks/useReports'

const Reports = () => {


        const { setToast, activeToast } = useContext(LoginContext)
        const [activeView, setActiveView] = useState(false)
        const [dateInitial, setDateInitial] = useState(currentTime)
        const [dateFinal, setDateFinal] = useState(currentTime)
        const [getOrders, setGetOrders] = useState(null)
        const [search, setSearch] = useState(null)
        const [filterSearch, setFilterSearch] = useState(null)
        const {findAllOrders, filterByDate} = useReports()

        return (
                <ContainerPages>
                        <BoxFilter/>
                        <ListOrders/>
                </ContainerPages>
        )
}

export default Reports
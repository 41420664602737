import React from 'react'
import ContainerPages from '../../components/containerPages'
import { BoxDrum } from '../../styles/drumControl'
import TableControl from './components/tableControl'
import { useControl } from '../../hooks/useControl'
import { dateTime } from '../../services/globals'
import Filters from '../../components/control/filters'

const Control = () => {

        const { getAllControl, navigation, deleteControl, permissions, signSales, inititalFilters, findByFilters, downloadDrumControl, signRegister } = useControl()

        const { currentDate } = dateTime()

        return (
                <ContainerPages>
                        <BoxDrum>
                                <h3>CONTROL DE SALIDA Y LLEGADA DE BIDONES ({getAllControl?.total ?? ""})</h3>
                                <button type="button" onClick={() => permissions('/control/add-exit', 'submenu')}>Agregar salida</button>
                                <span>
                                        <p>{currentDate}</p>
                                </span>
                        </BoxDrum>
                        <Filters
                                downloadDrumControl={downloadDrumControl}
                                findByFilters={findByFilters}
                                inititalFilters={inititalFilters}
                        />
                        <TableControl
                                signSales={signSales}
                                signRegister={signRegister}
                                permissions={permissions}
                                deleteControl={deleteControl}
                                navigation={navigation}
                                getAllControl={getAllControl}
                        />
                </ContainerPages>
        )
}



export default Control
import Delete from "../../../img/svg/delete"
import Edit from "../../../img/svg/edit"
import { ContainerTable, DataTable } from "../../../styles/deliveryman"

const Table = ({getAllWorkers,deleteWorker, stateAdministrator,permissions}) => {

        if(getAllWorkers === null) return

        return (

                <ContainerTable>
                        <DataTable>
                                <table>
                                        <thead>
                                                <tr style={{gridTemplateColumns:'8% 1fr 120px 120px 90px 120px 150px 1fr'}}>
                                                        <th>Nombre</th>
                                                        <th>Email</th>
                                                        <th>Telefono</th>
                                                        <th>Cargo</th>
                                                        <th>Estado</th>
                                                        <th>Fecha de creación</th>
                                                        <th>Ultima actualización</th>
                                                        <th>Acciones</th>
                                                </tr>
                                        </thead>
                                        {getAllWorkers !== 0 ? (
                                                getAllWorkers.map((item, index) => (
                                                        <tbody key={index}>
                                                                <tr style={{gridTemplateColumns:'8% 1fr 120px 120px 90px 120px 150px 1fr'}}>
                                                                        <th>{item.name_user}</th>
                                                                        <th>{item.email_user}</th>
                                                                        <th>{item.phone_user}</th>
                                                                        <th>{item.rol_user}</th>
                                                                        <th style={{ color: item.state_user == 1 ? 'green' : 'red'}}>{item.state_user == 1 ? 'Activo' : 'Inactivo'}</th>
                                                                        <th style={{justifyContent:'center'}}>{item.date_created_user}</th>
                                                                        <th>{item.date_update_user}</th>
                                                                        <th className="btn_action">
                                                                                <button type="button" onClick={() => stateAdministrator(item)} style={{ color: item.state_user == 1 ? 'red' : 'green' }}>{item.state_user == 1 ? 'Desactivar' : 'Activar'}</button>
                                                                                <button onClick={() => permissions(`/workers/update/${item.id_user}`, 'submenu')}>
                                                                                        <Edit />
                                                                                </button>
                                                                                <button onClick={() => deleteWorker(item.id_user,item.email_user)}>
                                                                                        <Delete />
                                                                                </button>
                                                                        </th>
                                                                </tr>
                                                        </tbody>
                                                ))
                                        ) : (
                                                <tbody>
                                                        <tr>
                                                                <th>Sin</th>
                                                                <th>registros</th>
                                                        </tr>
                                                </tbody>
                                        )}
                                </table>
                        </DataTable>
                </ContainerTable>
        )
}

export default Table
import React from 'react'
import ContainerPages from '../../components/containerPages'

const Coupons = () => {
  return (
    <ContainerPages>
      <h3>gestion de cupones y promociones</h3>
    </ContainerPages>
  )
}

export default Coupons
import React from 'react'
import styles from '../../styles/parameters.module.css'
import { useLocation } from 'react-router-dom'

const ListPagesInternal = ({ navigation }) => {

        const { pathname } = useLocation()


        return (
                <>
                        <div className={styles.box_title_and_button_new}>
                                <h3>Parametros y mantenciones</h3>
                                <span>
                                        <button type='button' onClick={() => navigation('/maintenance/add-param', { replace: true })}>Agregar Parametros</button>
                                        <button type='button' onClick={() => navigation('/maintenance/add-maintenance', { replace: true })}>Agregar Mantención</button>
                                </span>
                        </div>
                        <div className={styles.box_intercalate_buttons}>
                                <button type='button' className={pathname === "/maintenance" ? styles.active_button_page : ''} onClick={() => navigation('/maintenance')}>Lista de Parametros</button>
                                <button type='button' className={pathname === "/maintenance/maintenance" ? styles.active_button_page : ''} onClick={() => navigation('/maintenance/maintenance')}>Lista de Mantención</button>
                        </div>
                </>
        )
}

export default ListPagesInternal
import { useNavigate } from 'react-router-dom'
import { POST_LOC } from '../services/post'
import { useEffect, useState } from 'react'
import { GET_LOC } from '../services/get'
import { useContext } from 'react'
import LoginContext from '../contexts/login'
import { DELETE_LOC } from '../services/delete'
import { API, dateTime } from '../services/globals'

export const useDailyManager = () => {

        const navigation = useNavigate()
        const { setToast, activeToast } = useContext(LoginContext)
        const [activeFormAccount, setActiveFormAccount] = useState(false)
        const [activeFormSubAccount, setActiveFormSubAccount] = useState(false)
        const { initialMonth, currentDay } = dateTime()
        const [filterInitial, setFilterInitial] = useState({
                start: initialMonth,
                end: currentDay,
                account: 0,
                subaccount: 0
        })


        const [getAcc, setGetAcc] = useState(null)
        const [getSubAcc, setGetSubAcc] = useState(null)
        const [getAccExp, setGetAccExp] = useState(null)
        const [updateAccExp, setUpdateAccExp] = useState(null)

        const getAccounts = () => {
                GET_LOC({
                        table: 'accounts_expenses',
                        params: '?action=accounts'
                }).then(response => {
                        if (response.status === 200) {
                                setGetAcc(response.results)
                        } else {
                                setGetAcc(0)
                        }
                }
                )
        }
        const getAtAccountExpenses = (id) => {
                GET_LOC({
                        table: 'accounts_expenses',
                        params: `?action=accounts-expenses&id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setUpdateAccExp(response.results[0])
                        } else {
                                setUpdateAccExp(0)
                        }
                }
                )
        }

        const getSubAccounts = () => {
                GET_LOC({
                        table: 'accounts_expenses',
                        params: '?action=subaccounts'
                }).then(response => {
                        if (response.status === 200) {
                                setGetSubAcc(response.results)
                        } else {
                                setGetSubAcc(0)
                        }
                }
                )
        }

        const getAccountsExpenses = () => {
                GET_LOC({
                        table: 'accounts_expenses',
                        params: '?action=accounts-expenses'
                }).then(response => {
                        console.log(response)
                        if (response.status === 200) {
                                setGetAccExp(response)
                        } else {
                                setGetAccExp(0)
                        }
                }
                )
        }

        useEffect(() => {
                getAccounts()
        }, [])

        useEffect(() => {
                getSubAccounts()
                getAccountsExpenses()
        }, [])

        const addSubAccount = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                if (data.get('name').trim() === '') {
                        return;
                }
                POST_LOC({
                        table: 'accounts_expenses?action=add-subaccount',
                        data: data
                }).then(response => {

                        setToast({
                                message: 'subcuenta agregada correctamente',
                                error: false,
                                time: 3000
                        })

                        activeToast()
                        getSubAccounts()
                        setActiveFormSubAccount(false)
                })
        }

        const addAccount = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                if (data.get('name_account').trim() === '') {
                        return;
                }
                POST_LOC({
                        table: 'accounts_expenses?action=add-account',
                        data: data
                }).then(response => {
                        setToast({
                                message: 'cuenta agregada correctamente',
                                error: false,
                                time: 3000
                        })
                        activeToast()
                        getAccounts()
                        setActiveFormAccount(false)
                })
        }

        const addAccountExpenses = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)
                POST_LOC({
                        table: 'accounts_expenses?action=add-accounts-expenses',
                        data: data
                }).then(response => {
                        setToast({
                                message: 'gasto agregado correctamente',
                                error: false,
                                time: 3000
                        })
                        activeToast()
                        navigation('/daily-management', { replace: true })

                })
        }

        const deleteAccount = (id) => {

                let isDelete = window.confirm("¿Estas seguro de eliminar esta cuenta?");

                if (!isDelete) {
                        return
                }

                DELETE_LOC({
                        table: 'accounts_expenses',
                        params: `?action=account&id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: 'cuenta eliminada correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                setGetAcc(prev => prev.filter(item => item.id_account !== id));
                                return;
                        }

                })

        }
        const deleteSubAccount = (id) => {

                let isDelete = window.confirm("¿Estas seguro de eliminar esta subcuenta?");

                if (!isDelete) {
                        return
                }

                DELETE_LOC({
                        table: 'accounts_expenses',
                        params: `?action=subaccount&id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: 'subcuenta eliminada correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                setGetSubAcc(prev => prev.filter(item => item.id !== id));
                                return;
                        }

                })

        }
        const deleteAccountExpences = (id) => {

                let isDelete = window.confirm("¿Estas seguro de eliminar este gasto?");

                if (!isDelete) {
                        return
                }

                DELETE_LOC({
                        table: 'accounts_expenses',
                        params: `?action=account-expenses&id=${id}`
                }).then(response => {
                        if (response.status === 200) {
                                setToast({
                                        message: 'gasto eliminado correctamente',
                                        error: false,
                                        time: 3000
                                })
                                activeToast()
                                getAccountsExpenses()
                           
                        }
                })
        }

        const updateAccountExpences = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)

                POST_LOC({
                        table: 'accounts_expenses?action=update-accounts-expenses',
                        data: data
                }).then(response => {
                        setToast({
                                message: 'gasto actualizado correctamente',
                                error: false,
                                time: 3000
                        })
                        activeToast()
                        navigation('/daily-management', { replace: true })
                }
                )
        }

        const filterAccountsExpenses = (e) => {
                e.preventDefault()
                let data = new FormData(e.target)


                GET_LOC({
                        table: 'accounts_expenses',
                        params: `?action=filter&${Object.keys(Object.fromEntries(data)).map(key => `${key}=${data.get(key)}`).join('&')}`
                }).then(response => {
                        if (response.status === 200) {
                                console.log(response)
                                setFilterInitial(Object.fromEntries(data))
                                setGetAccExp(response)
                        } else {
                                setGetAccExp(0)
                        }
                }
                )
        }

        const downloadAccountsExpenses = () => {

                let params = `start=${filterInitial.start}&end=${filterInitial.end}&account=${filterInitial.account}&subaccount=${filterInitial.subaccount}`

                fetch(`${API}/accounts_expenses?action=filter&${params}&download=true`, {
                        method: 'GET'
                })
                        .then(response => {

                                // Obtener los datos del archivo como un objeto blob
                                return response.blob();
                        })
                        .then(blob => {
                                // Crear un objeto URL con los datos del archivo
                                const url = URL.createObjectURL(blob);

                                //return;
                                // Crear un enlace para descargar el archivo
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'cuentas_gastos.xlsx');
                                document.body.appendChild(link);

                                // Simular un clic en el enlace para descargar el archivo
                                link.click();

                                // Limpiar el objeto URL
                                URL.revokeObjectURL(url);
                        })
                        .catch(error => {
                                console.error(error);
                        });


        }

        return {
                activeFormAccount,
                setActiveFormAccount,
                activeFormSubAccount,
                setActiveFormSubAccount,
                addSubAccount,
                navigation,
                addAccount,
                addAccountExpenses,
                getAccExp,
                getAcc,
                getSubAcc,
                deleteAccountExpences,
                updateAccountExpences,
                getAtAccountExpenses,
                updateAccExp,
                setActiveFormAccount,
                deleteAccount,
                deleteSubAccount,
                filterInitial,
                setFilterInitial,
                filterAccountsExpenses,
                downloadAccountsExpenses
        }
}

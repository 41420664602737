import React from 'react'
import { NavLink } from 'react-router-dom'

const Link = ({ to, children, ...props }) => {

    return (
        <NavLink {...props} to={to} className={({ isActive }) => { return isActive ? "is-active" : undefined; }}>
            {children}
        </NavLink>
    )
}

export default Link
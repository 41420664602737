import { BASE_API, API } from "./globals";

export const PUT = ({
        table = null,
        id = null,
        nameId = null,
        data = null
}) =>
        fetch(`${BASE_API}${table}?id=${id}&nameId=${nameId}&token=no&except=${nameId}`, {
                method: 'PUT',
                headers: new Headers({
                        "Content-Type": "application/x-www-form-urlencoded"
                }),
                body: data,
                redirect: 'follow'
        })
                .then(response => response.json())
                .catch(err => console.log(err))

export const PUT_LOC = ({
        table = null,
        data = null
}) =>
        fetch(`${API}/${table}`, {
                method: 'PUT',
                headers: new Headers({
                        "Content-Type": "application/x-www-form-urlencoded"
                }),
                body: data,
                redirect: 'follow'
        }).then(response => response.json())
        .catch(err => console.log(err))